import {
  Box,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { TableComponent } from "../components/table";
import { order_list_url } from "../api/urls";
import { Search } from "lucide-react";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Crumbs } from "../components/crumbs";

export function Payments() {
  const apiUrl = order_list_url;
  const columns = [
    {
      headerName: "Date",
      field: "customer_name",
      renderCell: () => "12/02/2002",
    },
    {
      headerName: "Payment Datails",
      field: "order_id",
      renderCell: () => "1112236871702273802c270",
    },
    {
      headerName: "Amount",
      field: "phone_number",
      renderCell: (params) => "₹2,900",
    },
    {
      headerName: "Charges",
      field: "phone_number",
      renderCell: (params) => "₹00",
    },
    {
      headerName: "Net Amount",
      field: "email_id",
      renderCell: (params) => "₹2,900",
    },
    {
      headerName: "Details",
      field: "view",
      renderCell: (params) => (
        <Link to={`${params.row.id}`}>
          <Box
            sx={{
              fontWeight: "500",
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            View
          </Box>
        </Link>
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(apiUrl);
  const cardsData = [
    {
      label: "Unclaimed Payment",
      value: "₹900",
      settlement: (
        <>
          Next settlement{" "}
          <Typography sx={{ display: "inline" }} color="primary.main">
            09 May
          </Typography>
        </>
      ),
    },
    {
      label: "Settled Payment",
      value: "₹2,900",
      settlement: (
        <>
          Last settlement{" "}
          <Typography sx={{ display: "inline" }} color="primary.main">
            09 May
          </Typography>
        </>
      ),
    },
  ];

  const [activeCard, setActiveCard] = useState(0);
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Grid container spacing="20px">
        {cardsData.map((d, index) => (
          <Grid key={index} item xs={6} lg={6}>
            <Card
              onClick={() => {
                setActiveCard(index);
              }}
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                alignItems: "start",
                justifyContent: "center",
                height: "100%",
                cursor: "pointer",
                border:
                  activeCard === index
                    ? `2px solid ${theme.palette.primary.main} !important`
                    : null,
                ":hover": {
                  bgcolor:
                    activeCard !== index
                      ? `${theme.palette.primary.main}30 !important`
                      : null,
                },
              }}
              elevation={0}
            >
              <Typography fontWeight="500" fontSize={18}>
                {d.value}
              </Typography>
              <Typography fontSize={14} color="gray.main">
                {d.label}
              </Typography>
              <Typography fontSize={12}>{d.settlement}</Typography>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Card sx={{ padding: "20px" }} elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontWeight="500" fontSize={18}>
                {[cardsData[activeCard].label]}s
              </Typography>
              <TextField
                placeholder="Search"
                size="small"
                variant="outlined"
                sx={{
                  width: "200px",
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  sx: {
                    bgcolor: "background.default",
                  },
                }}
              />
            </Box>
            <Box sx={{ padding: "10px" }} />
            <TableComponent
              columns={columns}
              rows={data.data?.results || []}
              loading={data.loading}
            />
            <Box sx={{ padding: "10px" }} />
            {pagination}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export function PaymentDetail() {
  const apiUrl = order_list_url;
  const columns = [
    {
      headerName: "Date",
      field: "customer_name",
      renderCell: () => "12/02/2002",
    },
    {
      headerName: "Payment Datails",
      field: "order_id",
      renderCell: () => "1112236871702273802c270",
    },
    {
      headerName: "Amount",
      field: "phone_number",
      renderCell: (params) => "₹2,900",
    },
    {
      headerName: "Charges",
      field: "phone_number",
      renderCell: (params) => "₹00",
    },
    {
      headerName: "Net Amount",
      field: "email_id",
      renderCell: (params) => "₹2,900",
    },
    {
      headerName: "Details",
      field: "view",
      renderCell: (params) => (
        <Link to={`${params.row.id}`}>
          <Box
            sx={{
              fontWeight: "500",
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            View
          </Box>
        </Link>
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(apiUrl);
  const cardsData = [
    {
      label: "Transaction ID",
      value: "1112236871702273802c270",
    },
    {
      label: "Total Amount",
      value: "₹2,900",
    },
    {
      label: "Net Amount",
      value: "₹2,000",
    },
    {
      label: "Total Charges",
      value: "₹900",
    },
  ];

  return (
    <>
      <Crumbs />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Grid container spacing="20px">
          {cardsData.map((d, index) => (
            <Grid key={index} item xs={6} lg={3}>
              <Card
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  alignItems: "end",
                  justifyContent: "center",
                  height: "100%",
                }}
                elevation={0}
              >
                <Typography fontSize={13}>{d.label}</Typography>
                <Typography fontWeight="500" fontSize={14}>
                  {d.value}
                </Typography>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Card sx={{ padding: "20px" }} elevation={0}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="500" fontSize={20}>
                  12 March 2023
                </Typography>
                <TextField
                  placeholder="Search"
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "200px",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    sx: {
                      bgcolor: "background.default",
                    },
                  }}
                />
              </Box>
              <Box sx={{ padding: "10px" }} />
              <TableComponent
                columns={columns}
                rows={data.data?.results || []}
                loading={data.loading}
              />
              <Box sx={{ padding: "10px" }} />
              {pagination}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
