import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import {
  certificate_sections_url,
  certificate_url,
  document_sections_url,
  documents_url,
} from "../../api/urls";
import { Loading } from "../../components/loading";
import { useFetch } from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../../utils/validators";
import { generateUID } from "../../utils/generateUID";
import { api } from "../../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../../utils/unknownError";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { Fragment, useEffect, useState } from "react";
import { LabelValue } from "../../components/cardLabelValue";
import { useSelector } from "react-redux";
import { jsonToFormData } from "../../utils/jsonToFromData";
import { Link } from "react-router-dom";
import moment from "moment";
import { tickByStatus } from "../../utils/uiByStatus";

export function DocCertSection({ refetch }) {
  // const tabs = [
  //   {
  //     name: "Document",
  //   },
  //   { name: "Certificate" },
  // ];
  const [selectedTab] = useState(0);
  const [subPage, setSubPage] = useState("view");
  useEffect(() => {
    setSubPage("view");
  }, [selectedTab]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* <Box sx={{ padding: "10px" }} />
      <Box sx={{ display: "flex" }}>
        {tabs.map((tab, index) => (
          <Tab
            active={selectedTab === index}
            onClick={() => setSelectedTab(index)}
          >
            {tab.name}
          </Tab>
        ))}
      </Box> */}
      <Box sx={{ flex: 1 }}>
        {selectedTab === 0 && (
          <DocTab refetch={refetch} subPage={subPage} setSubPage={setSubPage} />
        )}
        {selectedTab === 1 && (
          <CertTab
            refetch={refetch}
            subPage={subPage}
            setSubPage={setSubPage}
          />
        )}
      </Box>
    </Box>
  );
}

function DocTab({ refetch, subPage, setSubPage }) {
  const [editData, setEditData] = useState();
  const sectionData = useFetch(document_sections_url + `?type=doc`);

  sectionData.data = sectionData?.data?.map((sec) => ({
    ...sec,
    docs: sec?.docs?.filter((doc) => doc.is_active === true),
  }));

  const ducumentsData = useFetch(documents_url);

  if (
    sectionData.loading ||
    !sectionData.data ||
    ducumentsData.loading ||
    !ducumentsData.data
  ) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <DocForm setSubPage={setSubPage} editData={editData} refetch={refetch} />
    );
  }

  return (
    <Box sx={{ padding: "20px" }}>
      {sectionData.data.map((section) => (
        <Box key={section.id} sx={{ marginBottom: "20px" }}>
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            {section.section}
          </Typography>
          <Divider />
          <Box sx={{ padding: "10px" }} />
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            {section.docs.map((doc) => {
              const docData = ducumentsData?.data?.find(
                (d) => d.doc_type === doc.id
              );
              return (
                <Paper
                  key={doc.id}
                  elevation={0}
                  sx={{ border: "1px solid", borderColor: "divider" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "20px",
                    }}
                  >
                    {[
                      { label: "Document Name:", value: doc.doc_name },
                      {
                        label: "Document Number:",
                        value: doc.doc_num_require ? docData?.doc_number : "",
                      },
                      {
                        label: "File:",
                        value: doc.filerequire && docData?.doc_file && (
                          <Button
                            component={Link}
                            to={docData?.doc_file}
                            variant="text"
                            target="_blank"
                          >
                            View File
                          </Button>
                        ),
                      },
                      {
                        label: "Expiry Date:",
                        value:
                          doc.expiryrequire && docData?.doc_expiry
                            ? moment(docData?.doc_expiry).format("DD-MM-YYYY")
                            : "",
                      },
                      {
                        label: "Status",
                        value: docData?.document_request_status
                          ? tickByStatus(docData?.document_request_status)
                          : "",
                      },
                    ]
                      .filter((item) => item.value)
                      .map((item, index, array) => (
                        <Fragment key={item.label}>
                          <LabelValue label={item.label} value={item.value} />
                          {index < array.length - 1 && <Divider />}
                        </Fragment>
                      ))}
                    <Box sx={{ padding: "5px" }} />
                    {!docData ? (
                      <Box>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            setEditData({
                              doc_type: doc.id,
                              doc_name: doc.doc_name,
                              filerequire: doc.filerequire,
                              expiryrequire: doc.expiryrequire,
                              doc_num_require: doc.doc_num_require,
                              doc_section: section.id,
                            });
                            setSubPage("add");
                          }}
                        >
                          Add Document
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            setEditData({
                              ...docData,
                              doc_type: doc.id,
                              doc_name: doc.doc_name,
                              filerequire: doc.filerequire,
                              expiryrequire: doc.expiryrequire,
                              doc_num_require: doc.doc_num_require,
                              doc_section: section.id,
                            });
                            setSubPage("add");
                          }}
                        >
                          Edit Document
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function DocForm({ setSubPage, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        ...(editData?.doc_num_require
          ? { doc_number: validators.stringRequired }
          : {}),
        ...(editData?.filerequire ? { doc_file: validators.fileRequired } : {}),
        ...(editData?.expiryrequire
          ? { doc_expiry: validators.stringRequired }
          : {}),
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    newData.seller = userId;
    if (!newData.filerequire) {
      delete newData.doc_file;
    }
    if (!newData.expiryrequire) {
      delete newData.doc_expiry;
    }
    if (!newData.doc_num_require) {
      delete newData.doc_number;
    }
    try {
      const response = await (editData?.id
        ? api.patch(`${documents_url}${editData.id}/`, jsonToFormData(newData))
        : api.post(documents_url, jsonToFormData(newData)));
      if (response?.status === 200 || response?.status === 201) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          {editData?.doc_num_require && (
            <Input.Text
              methods={methods}
              name="doc_number"
              label={`${editData?.doc_name} Number`}
              placeholder={`${editData?.doc_name} Number`}
              required
            />
          )}
          {editData?.filerequire && (
            <Input.File
              methods={methods}
              name="doc_file"
              label={`${editData?.doc_name} File`}
              placeholder={`${editData?.doc_name} File`}
              required
            />
          )}
          {editData?.expiryrequire && (
            <Input.Date
              methods={methods}
              name="doc_expiry"
              label={`${editData?.doc_name} Expiry Date`}
              placeholder={`${editData?.doc_name} Expiry Date`}
              required
            />
          )}
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}

function CertTab({ refetch, subPage, setSubPage }) {
  const [editData, setEditData] = useState();
  const sectionData = useFetch(certificate_sections_url);
  const ducumentsData = useFetch(certificate_url);

  if (
    sectionData.loading ||
    !sectionData.data ||
    ducumentsData.loading ||
    !ducumentsData.data
  ) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <CertForm setSubPage={setSubPage} editData={editData} refetch={refetch} />
    );
  }

  return (
    <Box sx={{ padding: "20px" }}>
      {sectionData.data.map((section) => (
        <Box key={section.id} sx={{ marginBottom: "20px" }}>
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            {section.section}
          </Typography>
          <Divider />
          <Box sx={{ padding: "10px" }} />
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            {section.docs.map((doc) => {
              const docData = ducumentsData.data.find(
                (d) => d.doc_type === doc.id
              );
              return (
                <Paper
                  key={doc.id}
                  elevation={0}
                  sx={{ border: "1px solid", borderColor: "divider" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "20px",
                    }}
                  >
                    {[
                      { label: "Certificate Name:", value: doc.doc_name },
                      {
                        label: "Certificate Number:",
                        value: docData?.certificate_number,
                      },
                      { label: "File:", value: docData?.certificate_file },
                      {
                        label: "Expiry Date:",
                        value: docData?.certificate_expiry_date,
                      },
                    ]
                      .filter((item) => item.value)
                      .map((item, index, array) => (
                        <Fragment key={item.label}>
                          <LabelValue label={item.label} value={item.value} />
                          {index < array.length - 1 && <Divider />}
                        </Fragment>
                      ))}
                    <Box sx={{ padding: "5px" }} />
                    {!docData ? (
                      <Box>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            setEditData({
                              doc_type: doc.id,
                              doc_name: doc.doc_name,
                              filerequire: doc.filerequire,
                              expiryrequire: doc.expiryrequire,
                              doc_num_require: doc.doc_num_require,
                            });
                            setSubPage("add");
                          }}
                        >
                          Add Certificate
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setEditData({
                              ...doc,
                              doc_type: doc.id,
                              doc_name: doc.doc_name,
                              filerequire: doc.filerequire,
                              expiryrequire: doc.expiryrequire,
                              doc_num_require: doc.doc_num_require,
                            });
                            setSubPage("add");
                          }}
                        >
                          Edit Certificate
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function CertForm({ setSubPage, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        ...(editData?.doc_num_require
          ? { certificate_number: validators.stringRequired }
          : {}),
        ...(editData?.filerequire
          ? { certificate_file: validators.fileRequired }
          : {}),
        ...(editData?.expiryrequire
          ? { certificate_expiry_date: validators.stringRequired }
          : {}),
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    newData.seller = userId;
    try {
      const response = await (editData?.id
        ? api.patch(
            `${certificate_url}${editData.id}/`,
            jsonToFormData(newData)
          )
        : api.post(certificate_url, jsonToFormData(newData)));
      if (response?.status === 200 || response?.status === 201) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          {editData?.doc_num_require && (
            <Input.Text
              methods={methods}
              name="certificate_number"
              label={`${editData?.doc_name} Number`}
              placeholder={`${editData?.doc_name} Number`}
              required
            />
          )}
          {editData?.filerequire && (
            <Input.File
              methods={methods}
              name="certificate_file"
              label={`${editData?.doc_name} File`}
              placeholder={`${editData?.doc_name} File`}
              required
            />
          )}
          {editData?.expiryrequire && (
            <Input.Date
              methods={methods}
              name="certificate_expiry_date"
              label={`${editData?.doc_name} Expiry Date`}
              placeholder={`${editData?.doc_name} Expiry Date`}
              required
            />
          )}
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}
