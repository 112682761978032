import { Box } from "@mui/material";

export function FormSteps({
  steps,
  currentStep,
  setCurrentStep,
  canGoToStep,
  submit,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Box
        sx={{
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          padding: "0px 5px",
          overflow: "auto",
          whiteSpace: "nowrap",
          backgroundColor: "background.paper",
          borderRadius: "5px",
        }}
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              color:
                currentStep === index
                  ? "primary.main"
                  : canGoToStep(index)
                  ? "text.primary"
                  : "gray.main",
              fontWeight: "500",
              flex: 1,
              display: "flex",
              paddingX: "10px",
              alignItems: "center",
              justifyContent: "center",
              cursor: canGoToStep(index) ? "pointer" : "not-allowed",
              padding: "10px 0px",
              borderBottom: currentStep === index ? "2px solid" : "",
            }}
            onClick={async () => {
              if (canGoToStep(index)) {
                if (submit) {
                  const valid = await submit();
                  if (valid) {
                    setCurrentStep(index);
                  }
                } else {
                  setCurrentStep(index);
                }
              }
            }}
          >
            {step.title}
          </Box>
        ))}
      </Box>
      <Box
        key={currentStep}
        sx={{
          overflow: "auto",
        }}
      >
        {steps[currentStep].body}
      </Box>
    </Box>
  );
}
