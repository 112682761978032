export const sellerAddressFormatter = (d) => {
  let result = "";
  if (!d) {
    return result;
  }

  const append = (v) => {
    if (v) {
      if (result !== "") result += ", ";
      result += v;
    }
  };
  append(d.building_premises_number);
  append(d.building_premises_name);
  append(d.address_line_1);
  append(d.address_line_2);
  append(d.landmark);
  append(d.area);
  append(d.district);
  append(d.city_village);
  append(d.state);
  append(d.pincode);
  append(d.country_name);
  return result;
};

export const userAddressFormatter = (d) => {
  let result = "";
  if (!d) {
    return result;
  }

  const append = (v) => {
    if (v) {
      if (result !== "") result += ", ";
      result += v;
    }
  };
  append(d.address);
  append(d.address_two);
  append(d.landmark);
  append(d.city);
  append(d.state_name);
  append(d.pincode);
  append(d.country);
  return result;
};

export const userNameFormatter = (d) => {
  let result = "";
  if (!d) {
    return result;
  }

  const append = (v) => {
    if (v) {
      if (result !== "") result += " ";
      result += v;
    }
  };
  append(d?.name);
  append(d?.full_name);
  append(d?.middle_name);
  append(d?.lastname);
  append(d?.last_name);
  append(d?.customer_name);
  return result;
};
