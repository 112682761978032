import { zodResolver } from "@hookform/resolvers/zod";
import { Box, IconButton, InputAdornment, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { validators } from "../utils/validators";
import { api } from "../api/api";
import { user_temp_pass_update_url } from "../api/urls";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { unknownError } from "../utils/unknownError";
import { FieldSet } from "../components/form";
import { Input } from "../components/input";
import { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { LoadingButton } from "@mui/lab";

export function UpdateTempPassword() {
  return (
    <Box
      sx={{
        padding: "20px",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.paper",
      }}
    >
      <UpdateTempPasswordForm />
    </Box>
  );
}

function UpdateTempPasswordForm() {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: zodResolver(
      z
        .object({
          password: validators.stringRequired,
          confirm_password: validators.stringRequired,
        })
        .superRefine((values, ctx) => {
          if (values.confirm_password !== values.password) {
            ctx.addIssue({
              message: "Password and confirm password should be same",
              path: ["confirm_password"],
            });
          }
        })
    ),
  });
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = async () => {
    const values = methods.getValues();
    const newData = {
      password: values.password,
      confirm_password: values.confirm_password,
    };
    try {
      const response = await api.post(user_temp_pass_update_url, newData, {
        headers: { Token: values.token },
      });
      if (response?.status === 200) {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
        });
        navigate("/dashboard");
      } else if (response?.status === 400) {
        if (response?.data?.data) {
          enqueueSnackbar(response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  const loading = methods.formState.isSubmitting;

  return (
    <form
      style={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
      className="login-form"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <h1 className="color-accent">Change Password</h1>
      <p className="color-accent para">
        You are using a temporarily assigned password. Please reset your
        password.
      </p>
      <div style={{ padding: "5px" }} />
      <FieldSet>
        <Input.Text
          methods={methods}
          name="password"
          label="Password"
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((show) => !show)}
                  edge="end"
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Input.Text
          methods={methods}
          name="confirm_password"
          label="Confirm password"
          placeholder="Confirm password"
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword((show) => !show)}
                  edge="end"
                >
                  {showConfirmPassword ? <EyeOff /> : <Eye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FieldSet>
      <div style={{ padding: "5px" }} />
      <div style={{ padding: "5px" }} />
      <LoadingButton
        type="submit"
        variant="contained"
        loading={loading}
        fullWidth
      >
        Next
      </LoadingButton>
    </form>
  );
}
