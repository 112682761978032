import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Dialog,
  IconButton,
} from "@mui/material";
import { Crumbs } from "../components/crumbs";
import { TableComponent } from "../components/table";
import { TextChip } from "../components/textChip";
import { order_list_url } from "../api/urls";
import { Mail, MapPin, Phone, Search, X } from "lucide-react";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { DashboardImage } from "../components/dashboardImage";
import { TrackUI } from "../components/trackUI";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { Loading } from "../components/loading";
import { na, na2, namt } from "../utils/na";
import {
  sellerAddressFormatter,
  userAddressFormatter,
  userNameFormatter,
} from "../utils/addressFormatter";
import moment from "moment/moment";
import { FilledIcons } from "../components/filledIcons";
import { Authorization } from "../components/authorization";
import { modules, perms } from "../api/codes";
import { useFilter } from "../hooks/useFilter";
import { Input } from "../components/input";
import { z } from "zod";
import { FieldGrid } from "../components/form";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FieldSet } from "../components/form";
import { LoadingButton } from "@mui/lab";
import { api } from "../api/api";
import { unknownError } from "../utils/unknownError";
import { AreYouSure } from "../components/areYouSure";

export function OrderList() {
  const apiUrl = order_list_url;
  const columns = [
    {
      headerName: "Customer Name",
      renderCell: (params) => userNameFormatter(params.row),
    },
    {
      headerName: "Order ID",
      field: "order_id",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Phone Number",
      field: "phone_number",
      renderCell: (params) => `+${params.row.mobile_no}`,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Email",
      field: "email_id",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "View",
      field: "view",
      renderCell: (params) => (
        <Link to={`${params.row.id}`}>
          <Box
            sx={{
              fontWeight: "500",
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            View Details
          </Box>
        </Link>
      ),
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "State",
      field: "action_name",
      renderCell: (params) => (
        <TextChip
          text={params.row.action_name}
          color={"#" + params.row.action_color_code}
          minWidth={150}
        />
      ),
      flex: 1,
      minWidth: 150,
    },
  ];

  const filter = useFilter({
    width: "800px",
    render: ({ methods }) => <FilterUI methods={methods} />,
    schema: z
      .object({
        created__range: z.any(),
        order_id__icontains: z.string().optional(),
        dlvr_prtnr_data: z.string().optional(),
        status__code__in: z.array(z.string()).optional(),
      })
      .superRefine((values, ctx) => {
        if (values.created__range?.[0] && !values.created__range?.[1]) {
          ctx.addIssue({
            message: "Both dates are required",
            path: ["created__range", 1],
          });
        }
        if (values.created__range?.[1] && !values.created__range?.[0]) {
          ctx.addIssue({
            message: "Both dates are required",
            path: ["created__range", 0],
          });
        }
      }),
  });

  const { data, pagination } = useFetchWithPagination(
    apiUrl + `?filter=${JSON.stringify(filter.obj)}`
  );

  const cardsData = [
    {
      label: "Recent Orders",
      value: "23",
      icon: FilledIcons.PackageTime,
    },
    {
      label: "Shipped Orders",
      value: "23",
      icon: FilledIcons.Truck,
    },
    {
      label: "Orders Delivered",
      value: "23",
      icon: FilledIcons.PackageRight,
    },
    {
      label: "Cancelled Orders",
      value: "31",
      icon: FilledIcons.PackageWrong,
    },
  ];
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Grid container spacing="20px">
        {cardsData.map((d, index) => (
          <Grid key={index} item xs={6} lg={3}>
            <Card
              sx={{
                padding: "20px",
                display: "flex",
                gap: "10px",
                alignItems: "start",
                justifyContent: "center",
                height: "100%",
              }}
              elevation={0}
            >
              <Box sx={{ marginTop: "3px" }}>
                <d.icon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={16} align="center">
                  {d.label}
                </Typography>
                <Typography fontWeight="500" fontSize={20}>
                  {d.value}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Card sx={{ padding: "20px" }} elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
              <TextField
                placeholder="Search"
                size="small"
                variant="outlined"
                sx={{
                  width: "200px",
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid",
                    borderColor: "divider",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  sx: {
                    bgcolor: "background.default",
                  },
                }}
              />
              {filter.FilterButton}
            </Box>
            <Box sx={{ padding: "10px" }} />
            <TableComponent
              columns={columns}
              rows={data.data?.results || []}
              loading={data.loading}
            />
            <Box sx={{ padding: "10px" }} />
            {pagination}
          </Card>
        </Grid>
      </Grid>
      {filter.FilterModal}
    </Box>
  );
}

export function Annexure() {
  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const {
    data: d,
    loading,
    refetch,
  } = useFetch(order_list_url + params.id + "/");

  const methods = useForm({
    resolver: zodResolver(
      z.object({
        reason_choices: z.string().min(1, "Reason is required"),
      })
    ),
    shouldFocusError: true,
  });

  const handleDenyOrder = () => {
    setOpenDenyDialog(true);
  };

  const handleCloseDialogs = () => {
    setOpenDenyDialog(false);
    methods.reset();
  };

  const onSubmit = async (data, action) => {
    try {
      let response;
      switch (action) {
        case "deny":
          response = await api.patch(
            `${order_list_url}${params.id}/order_deny/`,
            { reason_choices: data.reason_choices, refund_to_source: true }
          );
          break;
        case "approve":
          response = await api.patch(
            `${order_list_url}${params.id}/approve_pending/`
          );
          break;
        case "stockInward":
          response = await api.patch(
            `${order_list_url}${params.id}/stock_inward/`
          );
          break;
        default:
          response = await api.patch(
            `${order_list_url}${params.id}/order_deny/`
          );
          break;
      }

      if (response?.status === 200) {
        enqueueSnackbar(`Order ${action}ed successfully`, {
          variant: "success",
        });
        handleCloseDialogs();
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  if (loading || !d) {
    return <Loading />;
  }

  return (
    <>
      <Crumbs />
      <Box
        sx={{
          display: "grid",
          gap: "10px",
          gridTemplateColumns: "1fr 1fr 1fr",
          fontSize: "14px",
          justifyItems: "stretch",
          gridTemplateRows: "min-content 1fr min-content",
        }}
      >
        <Box
          sx={{
            gridColumn: "span 2",
            height: "min-content",
          }}
        >
          <TopNameSection d={d} />
        </Box>
        <Box
          sx={{
            gridRow: "span 2",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "100%",
          }}
        >
          <ProductDetailsSection d={d} />
          <DeliveryPartner d={d} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "100%",
          }}
        >
          <Track d={d} />
          <PriceDetails d={d} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "100%",
          }}
        >
          <OrderDetails d={d} />
          <ShippingAddress d={d} />
          <PickupAddress d={d} />
        </Box>
        <Box
          sx={{
            gridColumn: "span 3",
            display: "flex",
            gap: "10px",
            fontSize: "14px",
            "& > *": {
              flex: 1,
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: "success.main",
              bgcolor: (theme) => `${theme.palette.success.main}4d`,
              "&:hover": {
                bgcolor: (theme) => `${theme.palette.success.main}5d`,
              },
            }}
            onClick={() => {
              if (d.shipping_label) {
                window.open(d.shipping_label, "_blank");
              }
            }}
            disabled={!d.shipping_label}
          >
            Download Airway Bill
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "secondary.main",
              bgcolor: (theme) => `${theme.palette.secondary.main}4d`,
              "&:hover": {
                bgcolor: (theme) => `${theme.palette.secondary.main}5d`,
              },
            }}
            onClick={() => {
              if (d.invoice) {
                window.open(d.invoice, "_blank");
              }
            }}
            disabled={!d.invoice}
          >
            Download Invoice
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "primary.main",
              bgcolor: (theme) => `${theme.palette.primary.main}4d`,
              "&:hover": {
                bgcolor: (theme) => `${theme.palette.primary.main}5d`,
              },
            }}
            onClick={() => {
              if (d.pkl_label) {
                window.open(d.pkl_label, "_blank");
              }
            }}
            disabled={!d.pkl_label}
          >
            Download Packing List
          </Button>
          {d.status_code === "OS_ ORDERCNFRMD_47" && (
            <Authorization module={modules.Orders} perm={perms.denyOrder}>
              <Button
                variant="contained"
                sx={{
                  color: "error.main",
                  bgcolor: (theme) => `${theme.palette.error.main}4d`,
                  "&:hover": {
                    bgcolor: (theme) => `${theme.palette.error.main}5d`,
                  },
                }}
                onClick={handleDenyOrder}
              >
                Deny Order
              </Button>
            </Authorization>
          )}

          {d.return_replace &&
            d.status_code !== "OS_ACCPT_PEND" &&
            !wasApproved(d) && (
              <Authorization module={modules.Orders} perm={perms.approveOrder}>
                <AreYouSure
                  onYes={() => onSubmit({}, "approve")}
                  message="Are you sure?"
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: "success.main",
                      bgcolor: (theme) => `${theme.palette.success.main}4d`,
                      "&:hover": {
                        bgcolor: (theme) => `${theme.palette.success.main}5d`,
                      },
                    }}
                  >
                    Mark As Received
                  </Button>
                </AreYouSure>
              </Authorization>
            )}

          {d.return_replace &&
            d.status_code === "OS_ACCPT_PEND" &&
            !wasApproved(d) && (
              <Authorization module={modules.Orders} perm={perms.stockInward}>
                <AreYouSure
                  onYes={() => onSubmit({}, "stockInward")}
                  message="Are you sure?"
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: "info.main",
                      bgcolor: (theme) => `${theme.palette.info.main}4d`,
                      "&:hover": {
                        bgcolor: (theme) => `${theme.palette.info.main}5d`,
                      },
                    }}
                  >
                    Approve Stock Inward
                  </Button>
                </AreYouSure>
              </Authorization>
            )}
        </Box>
      </Box>

      <Dialog open={openDenyDialog} onClose={handleCloseDialogs}>
        <DialogContent
          methods={methods}
          onSubmit={(data) => onSubmit(data, "deny")}
          title="Deny Order"
          onClose={handleCloseDialogs}
        />
      </Dialog>
    </>
  );
}

function DialogContent({ methods, onSubmit, title, onClose }) {
  return (
    <Box
      sx={{
        padding: "40px",
        width: "500px",
        position: "relative",
        maxWidth: "100%",
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: "10px", top: "10px" }}
        onClick={onClose}
      >
        <X />
      </IconButton>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Text
            methods={methods}
            name="reason_choices"
            label="Reason"
            placeholder="Enter reason"
            multiline
            rows={4}
            required
          />
          <Box sx={{ p: "5px" }} />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={methods.formState.isSubmitting}
          >
            Submit
          </LoadingButton>
        </FieldSet>
      </Form>
    </Box>
  );
}

function TopNameSection({ d }) {
  return (
    <Paper elevation={0} sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ fontSize: "20px", fontWeight: "500" }}>
            {userNameFormatter(d)}
          </Box>
          <Box sx={{ fontSize: "15px", fontWeight: "400" }}>
            # {d?.order_id}
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          {namt(d?.subtotal_after_tax_amt, d?.currency_symbol)}
        </Box>
      </Box>
    </Paper>
  );
}

function ProductDetailsSection({ d }) {
  return (
    <Paper elevation={0} sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextChip
          text={d?.action_name}
          color={"#" + d?.action_color_code}
          minWidth="100%"
        />
        <Box sx={{ padding: "2px" }} />
        <DashboardImage src={d?.tagon_product_image} width="100%" />
        <Box sx={{ fontSize: "20px", fontWeight: "500" }}>
          {d?.prod_json?.title}
        </Box>
        <Box sx={{ fontSize: "15px", fontWeight: "400" }}>
          {d?.prod_json?.short_description}
        </Box>
        <LabelValue label="Colour:" value={na(d?.color)} />
        <LabelValue label="Size:" value={na(d?.size)} />
        <LabelValue label="Quantity:" value={na(d?.quantity)} />
        <LabelValue
          label="Price:"
          value={namt(d?.subtotal_after_tax_amt, d?.currency_symbol)}
        />
        <LabelValue
          label="HSN Code:"
          value={d?.prod_price?.goods_tax_code_id?.name || "N/A"}
        />
        <LabelValue label="SKU ID:" value={na(d?.prod_json?.skuid)} />
      </Box>
    </Paper>
  );
}

function ShippingAddress({ d }) {
  return (
    <Paper elevation={0} sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Shipping Address
        </Box>
        <IconValue
          icon={MapPin}
          value={userAddressFormatter(d?.shipping_address)}
        />
        <IconValue
          icon={Phone}
          value={
            <Box>
              {d?.shipping_address?.primary_mobile && (
                <Box>+{d?.shipping_address?.primary_mobile}</Box>
              )}
              {d?.shipping_address?.alternate_mobile && (
                <Box>+{d?.shipping_address?.alternate_mobile}</Box>
              )}
            </Box>
          }
        />
        {d?.shipping_address?.email_iD && (
          <IconValue icon={Mail} value={d?.shipping_address?.email_iD} />
        )}
      </Box>
    </Paper>
  );
}

function PickupAddress({ d }) {
  return (
    <Paper elevation={0} sx={{ padding: "20px", flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Pickup Address
        </Box>
        <IconValue
          icon={MapPin}
          value={sellerAddressFormatter(d?.pickup_address)}
        />
        <IconValue
          icon={Phone}
          value={
            <Box>
              {d?.pickup_address?.contact_number && (
                <Box>+{d?.pickup_address.contact_number}</Box>
              )}
            </Box>
          }
        />
        {d?.pickup_address?.contact_email_id && (
          <IconValue icon={Mail} value={d?.pickup_address?.contact_email_id} />
        )}
      </Box>
    </Paper>
  );
}

function DeliveryPartner({ d }) {
  return (
    <Paper elevation={0} sx={{ padding: "20px", flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Delivery Partner
        </Box>
        <LabelValue label="Name:" value={na(d?.delivery_details)} />
        <LabelValue label="Contact:" value="Todo" />
        <LabelValue label="E-Mail:" value="Todo" />
        <LabelValue label="Track:" value="Todo" />
      </Box>
    </Paper>
  );
}

function OrderDetails({ d }) {
  return (
    <Paper elevation={0} sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Order Details
        </Box>
        <LabelValue label="Order ID:" value={d?.order_id} />
        <LabelValue
          label="Ordered Date:"
          value={na2(
            getDateOfStatus(d?.order_logs, "OS_SHPD_002"),
            moment(getDateOfStatus(d?.order_logs, "OS_SHPD_002")).format(
              "DD-MM-YYYY"
            )
          )}
        />
        <LabelValue
          label="Expected Delivery Date:"
          value={na2(
            d?.expected_delivery,
            moment(d?.expected_delivery).format("DD-MM-YYYY")
          )}
        />
        <LabelValue label="Air Way Bill no.:" value={na(d?.awb_no)} />
        <LabelValue label="Product Count:" value={na(d.quantity)} />
        <LabelValue
          label="Order Value:"
          value={namt(d.total_amount_paid_amt, d.currency_symbol)}
        />
      </Box>
    </Paper>
  );
}

export function getDateOfStatus(logs, status) {
  return logs?.find((log) => log?.action_code === status)?.created_at;
}

export const wasApproved = (data) => {
  if (data?.order_logs?.find((log) => log.action_code === "OS_ACCPT_PEND"))
    return true;
  return false;
};

function Track({ d }) {
  const steps = d?.order_logs.map((ol) => ({
    name: ol.status,
    date: moment(ol.created_at).format("llll"),
  }));
  // [
  //   { name: "Order Placed", date: "23 Aug 2023, 04:25 PM" },
  //   { name: "In Progress", date: "23 Aug 2023, 04:25 PM" },
  //   { name: "Shipped", date: "23 Aug 2023, 04:25 PM" },
  //   { name: "Delivered", date: "23 Aug 2023, 04:25 PM" },
  // ];
  return (
    <Paper elevation={0} sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Order Status
        </Box>
        <TrackUI data={steps} completed={steps.length} />
      </Box>
    </Paper>
  );
}

function PriceDetails() {
  return (
    <Paper elevation={0} sx={{ padding: "20px", flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Sale Details
        </Box>
        <LabelValue label="Sales Revenue:" value="Todo" />
        <LabelValue label="Shipping Revenue:" value="Todo" />
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Charges
        </Box>
        <LabelValue label="Warehousing Fee:" value="Todo" />
        <LabelValue label="Shipping Charge:" value="Todo" />
        <LabelValue label="Commission fees:" value="Todo" />
        <LabelValue label="Packaging Fee:" value="Todo" />
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "primary.main",
          }}
        >
          Net Settlement
        </Box>
        <LabelValue label="GST Input Credits:" value="Todo" />
        <LabelValue label="TCS Input Credits:" value="Todo" />
        <LabelValue label="TDS Deduction:" value="Todo" />
        <LabelValue label="Bank Settlement:" value="Todo" />
      </Box>
    </Paper>
  );
}

function LabelValue({ label, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontWeight: "500" }}>{label}</Box>
      <Box>{value}</Box>
    </Box>
  );
}

function IconValue({ icon, value }) {
  const Icon = icon;
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Box>
        <Icon style={{ height: "20px", width: "20px", strokeWidth: "1.5px" }} />
      </Box>
      <Box>{value}</Box>
    </Box>
  );
}

function FilterUI({ methods }) {
  const statuses = [
    {
      name: "Order Confirmed",
      value: "OS_ ORDERCNFRMD_47",
    },
    {
      name: "Waiting for Pickup",
      value: "OS_ ORDERPROCESSED_52",
    },
    {
      name: "Shipped",
      value: "OS_SHPD_002",
    },
    {
      name: "Out for Delivery",
      value: "OS_OUTFRDELIV_53",
    },
    {
      name: "Delivered",
      value: "OS_DLVRD_003",
    },
    {
      name: "Cancel Request",
      value: "OS_CNCLDRQSTD_032",
    },
    {
      name: "Cancelled",
      value: "OS_CNCLD_029",
    },
    {
      name: "Return Request",
      value: "OS_RTRNRQSTD_015",
    },
    {
      name: "Returned",
      value: "OS_RTRND_018",
    },
    {
      name: "Return Denied",
      value: "OS_RTRNDEND_021",
    },
    {
      name: "Replace Request",
      value: "OS_RPLCRQSTD_005",
    },
    {
      name: "Replaced",
      value: "OS_RPLCD_010",
    },
    {
      name: "Replace Denied",
      value: "OS_RPLCDND_006",
    },
    {
      name: "Refunded",
      value: "OS_RFNDD_020",
    },
    {
      name: "Order Pending",
      value: "OS_PNDG_027",
    },
    {
      name: "Order Denied",
      value: "OS_ORDRDEND_53",
    },
    {
      name: "Failed",
      value: "OS_ORDRFLD_004",
    },
  ];

  const statusCodeKey = "status__code__in";
  const fields = methods.watch(statusCodeKey);
  const add = (value) => {
    const newData = [...(fields || [])];
    newData.push(value);
    methods.setValue(statusCodeKey, newData);
  };
  const remove = (value) => {
    const newData = [...(fields || [])];
    methods.setValue(
      statusCodeKey,
      newData.filter((v) => v !== value)
    );
  };

  // const deliveryPartnerData = useFetch(delivery_partner_url);

  return (
    <>
      <FieldGrid>
        <Input.Date
          methods={methods}
          label="Date range start"
          placeholder="date from"
          name="created__range.0"
          format="YYYY-MM-DD"
        />
        <Input.Date
          methods={methods}
          label="Date range end"
          placeholder="date to"
          name="created__range.1"
          format="YYYY-MM-DD"
        />
        {/* <Input.Text
          methods={methods}
          label="Order ID"
          placeholder="Order ID"
          name="order_id__icontains"
        /> */}
        {/* <Input.Select
          methods={methods}
          label="Delivery partner"
          placeholder="Delivery partner"
          name="dlvr_prtnr_data"
          loading={deliveryPartnerData.loading}
          options={deliveryPartnerData.data}
        /> */}
      </FieldGrid>
      <Box sx={{ padding: "10px" }} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
          gap: "10px",
          columnGap: "20px",
        }}
      >
        {statuses.map((st, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  fields?.find((field) => field === st.value) !== undefined
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    add(st.value);
                  } else {
                    remove(st.value);
                  }
                }}
              />
            }
            label={st.name}
            key={index}
          />
        ))}
      </Box>
      <Box sx={{ padding: "10px" }} />
    </>
  );
}
