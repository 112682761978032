import { Box, useTheme } from "@mui/material";

export function TextChip({ text, color: propColor, minWidth, maxWidth }) {
  const theme = useTheme();
  let color = propColor;
  if (color === "success") {
    color = theme.palette.success.main;
  }
  if (color === "error") {
    color = theme.palette.error.main;
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: `${color}61`,
          color: color,
          borderRadius: "5px",
          padding: "3px 10px",
          whiteSpace: "nowrap",
          fontSize: "12px",
          minWidth: minWidth || "80px",
          maxWidth: maxWidth,
          // borderColor: color,
          // border: "1px solid",
          fontWeight: "500",
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
