import { Box, Button, Divider } from "@mui/material";
import { bank_details_decrypted_url, bank_details_url } from "../../api/urls";
import { Loading } from "../../components/loading";
import { useFetch } from "../../hooks/useFetch";
import { useParamsState } from "../../hooks/useParamState";
import { encrypt } from "../../utils/encryption";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../../utils/validators";
import { generateUID } from "../../utils/generateUID";
import { jsonToFormData } from "../../utils/jsonToFromData";
import { api } from "../../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../../utils/unknownError";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as NoBank } from "../../images/noBank.svg";
import { LabelValue } from "../../components/cardLabelValue";

function decryptBankData(data) {
  const newData = { ...data };
  // if (data) {
  //   newData.account_name = decrypt(newData.account_name);
  //   newData.bank_name = decrypt(newData.bank_name);
  //   newData.account_number = decrypt(newData.account_number);
  //   newData.ifsc_code = decrypt(newData.ifsc_code);
  //   newData.upi_id_number = decrypt(newData.upi_id_number);
  // }
  return newData;
}

export function BankSection({ refetch }) {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const bankData = useFetch(bank_details_decrypted_url);

  if (bankData.loading || !bankData.data) {
    return <Loading />;
  }

  bankData.data = bankData.data.map(decryptBankData);

  if (subPage === "add") {
    return (
      <BankDetailsForm
        editData={{
          id: bankData?.data?.[0]?.id,
        }}
        setSubPage={setSubPage}
        refetch={() => {
          setSubPage("view");
          refetch();
        }}
      />
    );
  }

  if (bankData?.data?.length === 0) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoBank />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => setSubPage("add")}>
            Add Account
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <LabelValue
          label="Account Holders Name:"
          value={bankData?.data?.[0]?.account_name}
        />
        <Divider />
        <LabelValue label="Bank Name:" value={bankData?.data?.[0]?.bank_name} />
        <Divider />
        <LabelValue
          label="Account Number:"
          //value={bankData?.data?.[0]?.account_number}
          value="*******"
        />
        <Divider />
        <LabelValue
          label="IFSC Code:"
          // value={bankData?.data?.[0]?.ifsc_code}
          value="*******"
        />
        {/* <Divider />
        <LabelValue
          label="Status:"
          value={
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              {tickByStatus(bankData?.data?.[0]?.bank_request_status)}
            </Box>
          }
        /> */}
        <Box sx={{ padding: "5px" }} />
        <Button variant="contained" onClick={() => setSubPage("add")}>
          Update Account
        </Button>
      </Box>
    </Box>
  );
}

function BankDetailsForm({ setSubPage, refetch, editData }) {
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        account_name: validators.stringRequired,
        bank_name: validators.stringRequired,
        account_number: validators.stringRequired,
        ifsc_code: validators.stringRequired,
        passbook: validators.fileRequired,
        // account_type: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });

  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.account_number = encrypt(newData.account_number);
    newData.ifsc_code = encrypt(newData.ifsc_code);
    newData.upi_id_number = encrypt(newData.upi_id_number);
    newData.printable_name = newData.code;

    // const state = store.getState();
    // console.log({
    //   key: state.user.accessToken.substring(0, 16),
    //   iv: state.user.mobile_number + "0000",
    //   code: encrypt(newData.code),
    //   account_name: encrypt(newData.account_name),
    //   bank_name: encrypt(newData.bank_name),
    //   account_number: encrypt(newData.account_number),
    //   ifsc_code: encrypt(newData.ifsc_code),
    // });

    if (editData?.id) {
      try {
        const response = await api.patch(
          `${bank_details_url}${editData?.id}/`,
          jsonToFormData(newData)
        );
        if (response?.status === 200 || response?.status === 201) {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });
          refetch();
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    } else {
      try {
        const response = await api.post(
          bank_details_url,
          jsonToFormData(newData)
        );
        if (response?.status === 200 || response?.status === 201) {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });
          refetch();
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
        unknownError();
      }
    }
  };
  return (
    <Box sx={{ padding: "20px" }}>
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Text
            methods={methods}
            name="account_name"
            label="Account Holders Name"
            placeholder="Account Holders Name"
            required
          />
          <Input.Text
            methods={methods}
            name="bank_name"
            label="Bank Name"
            placeholder="Bank Name"
            required
          />
          <Input.Text
            methods={methods}
            name="account_number"
            label="Account Number"
            placeholder="Account Number"
            required
          />
          <Input.Text
            methods={methods}
            name="ifsc_code"
            label="IFSC Code"
            placeholder="IFSC Code"
            required
          />
          <Input.Text
            methods={methods}
            name="upi_id_number"
            label="UPI ID"
            placeholder="UPI ID"
            required
          />
          <Input.File
            methods={methods}
            name="passbook"
            label="Passbook"
            placeholder="Passbook"
            required
          />
          {/* <Input.Text
            methods={methods}
            name="account_type"
            label="Account Type"
            placeholder="Account Type"
            required
          /> */}
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}
