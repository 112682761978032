import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
  cardClasses,
} from "@mui/material";
import { useState, useRef } from "react";
import { sidebarConfig } from "../config/sidebarConfig";
import {
  LogOut,
  Menu,
  MoonStar,
  Search,
  Sun,
  FileText,
  Shield,
} from "lucide-react";
import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userStore";
import { ReactComponent as Logo } from "../images/logoAccent.svg";
import { api } from "../api/api";
import { user_logout_url } from "../api/urls";
import { FilledIcons } from "./filledIcons";
import { Authorization } from "./authorization";
import { perms } from "../api/codes";
import Autocomplete from "@mui/material/Autocomplete";

export const drawerWidth = 240;

export function Layout() {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);

  const [searchValue, setSearchValue] = useState(null);
  const [resetSearch, setResetSearch] = useState(false);

  const handleSearchChange = (event, newValue) => {
    if (newValue) {
      navigate(newValue.path);
      setResetSearch(!resetSearch);
    }
  };

  const getActiveModuleName = () => {
    let activeModuleName = "";
    sidebarConfig.forEach((item) => {
      if (location.pathname.startsWith(item.path)) {
        activeModuleName = item.name;
      }
    });
    return activeModuleName;
  };

  const searchOptions = sidebarConfig
    // .filter((item) => item.name !== getActiveModuleName())
    .map((item) => ({
      label: item.name,
      path: item.path,
    }));
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        width: "100%",
        [`.${cardClasses.root}`]: {
          bgcolor: "background.paper",
        },
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "background.paper",
            display: "block",
          },
        }}
        open
      >
        <Sidebar />
      </Drawer>
      <Drawer
        variant="temporary"
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "background.paper",
          },
        }}
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
      >
        <Sidebar />
      </Drawer>
      <Box
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: { xs: `100%`, sm: `calc(100% - ${drawerWidth}px)` },
          ml: { xs: `0`, sm: `${drawerWidth}px` },
          padding: { xs: `20px`, sm: `40px 60px` },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            height: "100%",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", sm: "20px" },
              backgroundColor: "background.paper",
              padding: "10px 20px",
              borderRadius: "10px",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ display: { xs: "flex", sm: "none" } }}
              size="small"
              onClick={() => setMobileDrawerOpen(true)}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h4"
              color="primary.main"
              fontWeight="500"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              {getActiveModuleName()}
            </Typography>
            <Box sx={{ flex: 1 }} />
            <Autocomplete
              key={resetSearch}
              ref={autocompleteRef}
              options={searchOptions}
              getOptionLabel={(option) => option.label}
              onChange={handleSearchChange}
              blurOnSelect
              // noOptionsText={null}
              onBlur={() => setSearchValue("")}
              disableClearable
              popupIcon={null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search modules"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "350px",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "primary.main" }}
                      >
                        <Search />
                      </InputAdornment>
                    ),
                    sx: {
                      bgcolor: "background.default",
                    },
                  }}
                />
              )}
            />
            <IconButton sx={{ margin: "-15px" }}>
              <FilledIcons.Bell />
            </IconButton>
            <ProfileSection />
          </Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

function ProfileSection() {
  const dispatch = useDispatch();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [themeVarient, setThemeVarient] = useLocalStorage("theme", "light");
  const username = useSelector((state) => state.user.username);
  const profileImage = useSelector((state) => state.user.profile_image);
  const mobileNumber = useSelector((state) => state.user.mobile_number);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          cursor: "pointer",
          alignItems: "center",
        }}
        onClick={() => setMobileDrawerOpen(true)}
      >
        <Typography
          noWrap
          fontWeight="500"
          fontSize={18}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          {username ? username : `+${mobileNumber}`}
        </Typography>
        <Avatar
          src={profileImage}
          sx={{
            bgcolor: "primary.main",
            color: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.text.primary
                : "none",
          }}
        >
          {username?.[0]?.toUpperCase()}
        </Avatar>
      </Box>
      <Drawer
        anchor="right"
        variant="temporary"
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "background.paper",
          },
        }}
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
      >
        <Box
          sx={{
            p: "20px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Avatar
              src={profileImage}
              sx={{
                bgcolor: "primary.main",
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.text.primary
                    : "none",
              }}
            >
              {username?.[0]?.toUpperCase()}
            </Avatar>
            <Typography noWrap fontWeight="500" fontSize={18}>
              {username ? username : `+${mobileNumber}`}
            </Typography>
          </Box>
          <Box sx={{ p: "5px" }} />
          <Divider />
          <Box sx={{ p: "5px" }} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <Button
              variant="ghost"
              sx={{ justifyContent: "flex-start" }}
              component={Link}
              to="/terms"
              startIcon={<FileText />}
            >
              Terms & Conditions
            </Button>
            <Button
              variant="ghost"
              sx={{ justifyContent: "flex-start" }}
              component={Link}
              to="/privacy_policy"
              startIcon={<Shield />}
            >
              Privacy Policy
            </Button>
            <Button
              variant="ghost"
              sx={{ justifyContent: "flex-start" }}
              startIcon={<LogOut />}
              onClick={async () => {
                try {
                  await api.get(user_logout_url);
                } catch (e) {
                  console.error(e);
                }
                await dispatch(logout());
              }}
            >
              Log-out
            </Button>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ flex: 1 }}
              variant={themeVarient === "light" ? "contained" : "ghost"}
              startIcon={<Sun />}
              onClick={() => setThemeVarient("light")}
            >
              Light
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant={themeVarient === "dark" ? "contained" : "ghost"}
              startIcon={<MoonStar />}
              onClick={() => setThemeVarient("dark")}
            >
              Dark
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

function Sidebar() {
  const location = useLocation();
  const onBoarding = false;

  const getActiveIndex = () => {
    let activeIndex = 0;
    sidebarConfig.forEach((item, index) => {
      if (location.pathname.startsWith(item.path)) {
        activeIndex = index;
      }
    });
    return activeIndex;
  };
  return (
    <Box
      sx={{
        p: "20px",
        flexDirection: "column",
        gap: "5px",
        paddingTop: "20px",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Logo width="170px" />
      <Box
        sx={{
          position: "absolute",
          right: "0px",
          top: `${getActiveIndex() * 41.5 + 94}px`,
          bgcolor: "secondary.main",
          height: "40px",
          width: "4px",
          borderRadius: "5px 0px 0px 5px",
          transition: "all 0.5s ease",
        }}
      />
      <Box sx={{ p: "10px" }} />
      {sidebarConfig.map((item, index) =>
        item?.children?.filter((x) => x.display !== false).length > 0 ? (
          <Authorization
            key={index}
            module={item.module}
            perm={perms.view}
            style={{ width: "100%" }}
          >
            <ButtonWithPopOver item={item} index={index} />
          </Authorization>
        ) : (
          <Authorization
            key={index}
            module={item.module}
            perm={perms.view}
            style={{ width: "100%" }}
          >
            <Button
              component={Link}
              to={item.path}
              variant={
                location.pathname.startsWith(item.path) ? "contained" : "ghost"
              }
              sx={{ justifyContent: "flex-start" }}
              startIcon={<item.icon />}
              fullWidth
              disabled={!(item.onBoarding || !onBoarding) || item.disabled}
            >
              {item.name}
            </Button>
          </Authorization>
        )
      )}
    </Box>
  );
}

function ButtonWithPopOver({ item, index }) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const items = item.children;

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        key={index}
        variant={
          location.pathname.startsWith(item.path) ? "contained" : "ghost"
        }
        sx={{ justifyContent: "flex-start" }}
        startIcon={<item.icon />}
        onClick={handleClick}
        fullWidth
      >
        {item.name}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            width: drawerWidth,
          }}
        >
          {items.map((item, index) =>
            item.children ? (
              <ButtonWithPopOver key={index} item={item} index={index} />
            ) : (
              <Button
                key={index}
                component={Link}
                to={item.path}
                variant="ghost"
                sx={{ justifyContent: "flex-start" }}
                startIcon={<item.icon />}
                onClick={handleClose}
              >
                {item.name}
              </Button>
            )
          )}
        </Box>
      </Popover>
    </>
  );
}
