export const modules = {
  Orders: "Orders",
  Inventory: "Inventory",
  GlobalStore: "GlobalStore",
  B2BStore: "B2BStore",
  Coupon: "Coupon",
  Reels: "Clips",
  Payments: "Payments",
  HelpCenter: "Tickets",
  Users: "Users",
  Roles: "Roles",
};

export const perms = {
  view: "VIEW_LIST",
  add: "ADD",
  edit: "EDIT",
  delete: "DELETE",

  denyOrder: "order_deny",

  downloadReports: "download_reports",
};
