import {
  Box,
  Card,
  Grid,
  Typography,
  useTheme,
  Avatar,
  MenuList,
  MenuItem,
  Divider,
  LinearProgress,
  Paper,
  Select,
} from "@mui/material";
import { ChevronRight, ChevronUp, ChevronDown } from "lucide-react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tab } from "../components/tabs";
import { useState } from "react";
import { FilledIcons } from "../components/filledIcons";
import { ReactComponent as MaharashtraIcon } from "../images/maharashtraIcon.svg";
import {
  user_profile_url,
  address_url,
  documents_url,
  certificate_url,
  bank_details_decrypted_url,
  document_sections_url,
  certificate_sections_url,
  dashboard_graph_url,
} from "../api/urls";
import { useFetch } from "../hooks/useFetch";
import { Loading } from "../components/loading";

function checkAccountInformation(userData) {
  if (!userData) return false;
  return !!(
    userData.company_name &&
    userData.description &&
    userData.firstname &&
    userData.email_id &&
    userData.mobile_number &&
    userData.bizz_type &&
    userData.orgzn_type &&
    userData.website
  );
}

function checkRequiredDocuments(
  documentsData,
  certificateData,
  docSectionData,
  certSectionData
) {
  let ok =
    documentsData &&
    certificateData &&
    documentsData.length > 0 &&
    certificateData.length > 0 &&
    docSectionData &&
    certSectionData;

  if (!ok) return false;

  docSectionData.forEach((section) => {
    if (section.docs.length > 0) {
      section.docs.forEach((doc) => {
        if (!documentsData.find((d) => d.doc_type === doc.id)) {
          ok = false;
        }
      });
    }
  });

  return ok;
}

export function Dashboard() {
  const verified = useSelector((state) => state.user.verified);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSubTab, setSelectedSubTab] = useState("daily");
  if (!verified) {
    return <OnBoarding />;
  }

  const chartTabs = [
    { name: "Customers", value: "24k" },
    { name: "Products", value: "1.5k" },
    { name: "Revenue", value: "250k" },
  ];

  const chartSubTabs = [
    { name: "daily", label: "Daily" },
    { name: "weekly", label: "Weekly" },
    { name: "monthly", label: "Monthly" },
    { name: "yearly", label: "Yearly" },
  ];

  const cardsData = [
    {
      label: "Total Revenue",
      value: "₹2,900",
      icon: FilledIcons.Stats,
    },
    {
      label: "Today’s Orders",
      value: "23",
      icon: FilledIcons.Truck,
    },
    {
      label: "Upcoming Payment",
      value: "23",
      icon: FilledIcons.PackageTime,
    },
    {
      label: "Shipped Orders",
      value: "31",
      icon: FilledIcons.PackageWrong,
    },
  ];
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      <Grid container spacing="20px">
        {cardsData.map((d, index) => (
          <Grid key={index} item xs={6} lg={3}>
            <Card
              sx={{
                padding: "20px",
                display: "flex",
                gap: "10px",
                alignItems: "start",
                justifyContent: "center",
                height: "100%",
              }}
              elevation={0}
            >
              <Box sx={{ marginTop: "3px" }}>
                <d.icon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={16} align="center">
                  {d.label}
                </Typography>
                <Typography fontWeight="500" fontSize={20}>
                  {d.value}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} lg={8}>
          <Card
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            elevation={0}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                {chartTabs.map((tab, index) => (
                  <Tab
                    key={index}
                    active={index === selectedTab}
                    onClick={() => setSelectedTab(index)}
                  >
                    <Box sx={{ padding: "10px", paddingRight: "20px" }}>
                      <Box
                        sx={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "text.primary",
                        }}
                      >
                        {tab.value}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "gray.main",
                        }}
                      >
                        {tab.name}
                      </Box>
                    </Box>
                  </Tab>
                ))}
              </Box>
              <Box>
                <Select
                  value={selectedSubTab}
                  onChange={(e) => setSelectedSubTab(e.target.value)}
                  sx={{
                    minWidth: 120,
                    height: 40,
                    "& .MuiSelect-icon": {
                      top: "calc(50% - 12px)",
                    },
                  }}
                  IconComponent={ChevronDown}
                >
                  {chartSubTabs.map((tab) => (
                    <MenuItem key={tab.name} value={tab.name}>
                      {tab.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <TotalRevenueChart
              selectedTab={selectedTab}
              selectedSubTab={selectedSubTab}
            />
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card elevation={0} sx={{ height: "100%" }}>
            <Box
              sx={{ padding: "20px", display: "flex", flexDirection: "column" }}
            >
              <Typography fontSize={16} fontWeight="500">
                Recent Orders
              </Typography>
              <RecentOrderCard />
              <Divider />
              <RecentOrderCard />
              <Divider />
              <RecentOrderCard />
              <Divider />
              <RecentOrderCard />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card elevation={0} sx={{ height: "100%" }}>
            <Box
              sx={{ padding: "20px", display: "flex", flexDirection: "column" }}
            >
              <Typography fontSize={17} fontWeight="500">
                Orders in last month
              </Typography>
              <Typography fontSize={20} fontWeight="700">
                16.5K
              </Typography>
              <Typography fontSize={15} fontWeight="400" color="gray.main">
                Sales per day
              </Typography>
              <Box sx={{ padding: "5px" }} />
              <OrdersPerDay />
              <Box sx={{ padding: "5px" }} />
              <StateOrdersCard />
              <Divider />
              <StateOrdersCard />
              <Divider />
              <StateOrdersCard />
              <Divider />
              <StateOrdersCard />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card elevation={0} sx={{ height: "100%" }}>
            <Box
              sx={{ padding: "20px", display: "flex", flexDirection: "column" }}
            >
              <Typography fontSize={17} fontWeight="500">
                Popular products
              </Typography>
              <Box sx={{ padding: "5px" }} />
              <ProductCard />
              <Divider />
              <ProductCard />
              <Divider />
              <ProductCard />
              <Divider />
              <ProductCard />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

function OnBoarding() {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.id);

  const { data: userData, loading: userLoading } = useFetch(
    `${user_profile_url}${userId}/`
  );
  const { data: addressData, loading: addressLoading } = useFetch(address_url);
  const { data: docSectionData, loading: docSectionLoading } = useFetch(
    document_sections_url
  );
  const { data: certSectionData, loading: certSectionLoading } = useFetch(
    certificate_sections_url
  );
  const { data: documentsData, loading: documentsLoading } =
    useFetch(documents_url);
  const { data: certificateData, loading: certificateLoading } =
    useFetch(certificate_url);
  const { data: bankData, loading: bankLoading } = useFetch(
    bank_details_decrypted_url
  );

  const isLoading =
    userLoading ||
    addressLoading ||
    documentsLoading ||
    certificateLoading ||
    bankLoading ||
    docSectionLoading ||
    certSectionLoading;

  if (isLoading) {
    return <Loading />;
  }

  const onBoardingOptions = [
    {
      name: "Complete Account Information",
      href: "/profile?page=account",
      check: checkAccountInformation(userData),
    },
    {
      name: "Add Bank Details",
      href: "/profile?page=bank",
      check: bankData && bankData.length > 0,
    },
    {
      name: "Add Address",
      href: "/profile?page=address",
      check: addressData && addressData.length > 0,
    },
    {
      name: "Upload Required Documents",
      href: "/profile?page=document",
      check: checkRequiredDocuments(
        documentsData,
        certificateData,
        docSectionData,
        certSectionData
      ),
    },
    {
      name: "Upload Digital Signature",
      href: "/profile?page=signature",
      check: !!userData?.digital_signature,
    },
  ];

  const completedOnBoadingOptions = onBoardingOptions.filter(
    (x) => x.check
  ).length;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            height: "30px",
            width: "30px",
            borderRadius: "50%",
          }}
        >
          <FilledIcons.Flag width="35px" style={{ marginTop: "-5px" }} />
        </Box>
        <Typography variant="h5" fontWeight="500">
          Finalize your onboarding
        </Typography>
      </Box>
      <Box sx={{ p: "2px" }} />
      <Box sx={{ display: "flex", gap: "4px", width: "100%" }}>
        {onBoardingOptions.map((option, index) => (
          <Box
            key={index}
            sx={{
              bgcolor: (theme) =>
                index < completedOnBoadingOptions
                  ? "primary.main"
                  : `${theme.palette.primary.main}40`,
              flex: 1,
              height: "4px",
            }}
          />
        ))}
      </Box>
      <Box sx={{ p: "2px" }} />
      <Box
        sx={{
          p: "3px 10px",
          borderRadius: "10px",
          bgcolor: "background.paper",
        }}
      >
        <MenuList>
          {onBoardingOptions.map((option, index) => (
            <Box key={index}>
              <MenuItem
                key={index}
                onClick={() => {
                  navigate(option.href);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ marginTop: "5px" }}>
                    {option.check ? (
                      <FilledIcons.OnBoardingCheck height={25} />
                    ) : (
                      <FilledIcons.OnBoardingUnCheck height={25} />
                    )}
                  </Box>
                  <Box sx={{ p: "0px" }} />
                  <Box sx={{ flex: 1, fontSize: "15px" }}>{option.name}</Box>
                  <ChevronRight />
                </Box>
              </MenuItem>
              {index < onBoardingOptions.length - 1 && <Divider />}
            </Box>
          ))}
        </MenuList>
      </Box>
    </Box>
  );
}

function TotalRevenueChart({ selectedTab, selectedSubTab }) {
  const theme = useTheme();
  const { data, loading: graphLoading } = useFetch(
    dashboard_graph_url + `?period=${selectedSubTab}`
  );

  if (graphLoading) {
    return <Loading />;
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const d = payload[0]?.payload;
      return (
        <Paper
          sx={{
            padding: "10px",
            backgroundColor: "background.paper",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          <Box sx={{ fontSize: "12px", fontWeight: "400" }}>2024</Box>
          <Box sx={{ fontSize: "15px", fontWeight: "500" }}>March 09,</Box>
          <Box sx={{ fontSize: "12px", fontWeight: "400", color: "gray.main" }}>
            Total Orders
          </Box>
          <Box
            sx={{ fontSize: "15px", fontWeight: "500", color: "primary.main" }}
          >
            {d?.["value"]}
          </Box>
        </Paper>
      );
    }
    return null;
  };

  const CustomizedDot = (props) => {
    const { cx, cy } = props;

    return (
      <svg
        x={cx - 7}
        y={cy - 7}
        width="14"
        height="14"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.55079 11.98C9.52775 11.98 11.941 9.56037 11.941 6.5755C11.941 3.59062 9.52775 1.17065 6.55079 1.17065C3.57384 1.17065 1.1606 3.59062 1.1606 6.5755C1.1606 9.56037 3.57384 11.98 6.55079 11.98Z"
          fill="white"
          stroke="#1612D1"
          strokeWidth="2"
        />
      </svg>
    );
  };

  return (
    <ResponsiveContainer height={350} width="100%">
      <AreaChart
        width={100}
        height={100}
        data={data}
        margin={{
          top: 40,
          right: 10,
          left: -10,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="name"
          tick={{
            fill: theme.palette.gray.main,
            fontSize: "14px",
          }}
          tickLine={{
            display: "none",
          }}
          axisLine={{ display: "none" }}
        />
        <YAxis
          tick={{
            fill: theme.palette.gray.main,
            fontSize: "14px",
          }}
          tickLine={{
            display: "none",
          }}
          axisLine={{ display: "none" }}
        />
        <Tooltip content={<CustomTooltip />} />
        <defs>
          <linearGradient id="blueGra" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.4}
            />
            <stop
              offset="50%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.2}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke={theme.palette.primary.main}
          fill="url(#blueGra)"
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

function OrdersPerDay() {
  const theme = useTheme();
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const data = [
    ...[...Array(30)].map(() => {
      return { orders: randomIntFromInterval(1, 100), date: "21-08-2002" };
    }),
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const d = payload[0]?.payload;
      return (
        <Paper
          sx={{
            padding: "10px",
            backgroundColor: "background.paper",
            fontSize: "12px",
          }}
        >
          <Box>
            <span style={{ fontWeight: "500" }}>Date: </span>
            {d?.date}
          </Box>
          <Box>
            <span style={{ fontWeight: "500" }}>Orders: </span>
            {d?.orders}
          </Box>
        </Paper>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer height={50} width="100%">
      <BarChart
        // width={100}
        // height={100}
        data={data}
        margin={{
          right: 0,
          left: 0,
          top: 0,
          botton: 0,
        }}
      >
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="orders" fill={theme.palette.primary.main} />
      </BarChart>
    </ResponsiveContainer>
  );
}

function RecentOrderCard() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "10px 0px" }}>
      <Box sx={{ display: "flex" }}>
        <Avatar>A</Avatar>
        <Box sx={{ width: "8px" }} />
        <Box sx={{ fontWeight: "500", marginTop: "6px" }}>Anirud Tate</Box>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ width: "5px" }} />
        <Box sx={{ color: "gray.main", fontSize: "12px" }}>09:13 AM</Box>
      </Box>
      <Box sx={{ padding: "2px" }} />
      <Box
        sx={{ display: "flex", gap: "5px", justifyContent: "space-between" }}
      >
        <Box sx={{ color: "gray.main", fontWeight: "500", fontSize: "12px" }}>
          Order ID:
        </Box>
        <Box sx={{ fontWeight: "400", fontSize: "12px" }}>
          1112236871702273802c270
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", gap: "5px", justifyContent: "space-between" }}
      >
        <Box sx={{ color: "gray.main", fontWeight: "500", fontSize: "12px" }}>
          Order Value:
        </Box>
        <Box sx={{ fontWeight: "400", fontSize: "12px" }}>₹2345</Box>
      </Box>
    </Box>
  );
}

function StateOrdersCard() {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px 0px",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <MaharashtraIcon />
      <Box>
        <Box sx={{ fontSize: "14px", fontWeight: "500" }}>30k</Box>
        <Box sx={{ fontSize: "13px", color: "gray.main" }}>Maharashtra</Box>
      </Box>
      <LinearProgress
        variant="determinate"
        value={50}
        color="primary"
        sx={{ flex: 1 }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
          color: "success.main",
        }}
      >
        <ChevronUp />
        <Box sx={{ fontSize: "14px" }}>25.8%</Box>
      </Box>
    </Box>
  );
}

function ProductCard() {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px 0px",
        gap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box sx={{ fontSize: "14px", fontWeight: "500" }}>Apple iPhone 13</Box>
        <Box sx={{ fontSize: "13px", color: "gray.main" }}>Item: #FXZ-4567</Box>
      </Box>
      <Box sx={{ fontSize: "14px", fontWeight: "500" }}>₹99999.29</Box>
    </Box>
  );
}
