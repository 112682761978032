import { Box, Typography } from "@mui/material";
import { useFetchPublic } from "./root";
import { Loading } from "../components/loading";
import { terms_url } from "../api/urls";

export function Terms() {
  const { data: terms, loading } = useFetchPublic(`/in${terms_url}`);

  if (loading || !terms) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </Box>
    );
  }

  const data = terms?.[0]?.description;

  return (
    <Box
      sx={{
        padding: { xs: "15px", sm: "30px", maxWidth: "1000px", margin: "auto" },
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
        Terms and Conditions
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Box>
  );
}
