import { createSlice } from "@reduxjs/toolkit";

export const userStore = createSlice({
  name: "user",
  initialState: {
    username: "",
    accessToken: "",
    refreshToken: "",
    id: "",
    mobile_number: "",
    account_id: "",
    profile_image: "",
    country_code: "",
    verified: false,
    pwd_temp: false,
    permissions: [],
    is_owner: false,
  },
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.username = action.payload.username;
      state.id = action.payload.id;
      state.mobile_number = action.payload.mobile_number;
      state.account_id = action.payload.account_id;
      state.profile_image = action.payload.profile_image;
      state.country_code = action.payload.country_code;
      state.verified = action.payload.verified;
      state.pwd_temp = action.payload.pwd_temp;
      state.permissions = action.payload.permissions;
      state.is_owner = action.payload.is_owner;
    },
    logout: (state) => {
      state.username = "";
      state.accessToken = "";
      state.refreshToken = "";
      state.id = "";
      state.mobile_number = "";
      state.account_id = "";
      state.profile_image = "";
      state.country_code = "";
      state.verified = false;
      state.is_owner = false;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setUserName: (state, action) => {
      state.username = action.payload;
    },
    setProfileImage: (state, action) => {
      state.profile_image = action.payload;
    },
    setMobileNumber: (state, action) => {
      state.mobile_number = action.payload;
    },
    setVerified: (state, action) => {
      state.verified = action.verified;
    },
    setPwdTemp: (state, action) => {
      state.pwd_temp = action.payload;
    },
    setAccountId: (state, action) => {
      state.account_id = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setAccessToken,
  setUserName,
  setProfileImage,
  setMobileNumber,
  setVerified,
  setPwdTemp,
  setAccountId,
} = userStore.actions;

export default userStore.reducer;
