import { Link } from "react-router-dom";
import { Image } from "lucide-react";
import { Box, useTheme } from "@mui/material";

export function DashboardImage({ src, height, width, noLink }) {
  const theme = useTheme();
  const source = src ? src : Image;
  return src && !noLink ? (
    <Link
      to={source}
      target="_blank"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={source}
        height={height}
        width={width}
        alt="img"
        style={{
          borderRadius: "5px",
          objectFit: "contain",
          border: `1px solid ${theme.palette.divider}`,
        }}
      />
    </Link>
  ) : noLink ? (
    <img
      src={source}
      height={height}
      width={width}
      alt="img"
      style={{
        borderRadius: "5px",
        objectFit: "contain",
        border: `1px solid ${theme.palette.divider}`,
      }}
    />
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "5px",
          padding: "10px",
          color: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: height,
          width: width,
        }}
      >
        <Image style={{ height: "30px", width: "30px" }} />
      </Box>
    </Box>
  );
}
