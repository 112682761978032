import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import {
  brand_document_url,
  category_document_url,
  category_url,
  document_sections_url,
  full_brand_url,
  full_first_category_url,
  full_second_category_url,
  full_third_category_url,
  request_brand_url,
} from "../../api/urls";
import { Loading } from "../../components/loading";
import { useFetch } from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../../utils/validators";
import { generateUID } from "../../utils/generateUID";
import { api } from "../../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../../utils/unknownError";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Tab } from "../../components/tabs";
import { useFetchWithPagination } from "../../hooks/useFetchWithPagination";
import { ReactComponent as NoBrand } from "../../images/noBrand.svg";
import { ReactComponent as NoCategory } from "../../images/noCategory.svg";
import moment from "moment";
import { TableComponent } from "../../components/table";
import { useSelector } from "react-redux";
import { objToParams } from "../../utils/objToParams";
import { X } from "lucide-react";
import { tickByStatus, chipByStatus } from "../../utils/uiByStatus";
import { View } from "../../components/view";
import { jsonToFormData } from "../../utils/jsonToFromData";

export function BrandSection({ data, refetch }) {
  const tabs = [
    {
      name: "My Brand",
    },
    { name: "My Category" },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const [subPage, setSubPage] = useState("view");
  useEffect(() => {
    setSubPage("view");
  }, [selectedTab]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ padding: "10px" }} />
      <Box sx={{ display: "flex" }}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            active={selectedTab === index}
            onClick={() => setSelectedTab(index)}
          >
            {tab.name}
          </Tab>
        ))}
      </Box>
      <Box sx={{ flex: 1 }}>
        {selectedTab === 0 && <BrandTab />}
        {selectedTab === 1 && (
          <CategoryTab
            data={data}
            refetch={refetch}
            subPage={subPage}
            setSubPage={setSubPage}
          />
        )}
      </Box>
    </Box>
  );
}

function BrandTab() {
  const [openBrandForm, setOpenBrandForm] = useState(false);
  const [editData, setEditData] = useState();
  const { data: brandData, pagination } =
    useFetchWithPagination(request_brand_url);

  if (brandData.loading || !brandData.data) {
    return <Loading />;
  }

  if (brandData?.data?.results?.length === 0) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoBrand />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => setOpenBrandForm(true)}>
            Request Brand
          </Button>
          <BrandForm
            open={openBrandForm}
            onClose={() => setOpenBrandForm(false)}
            refetch={brandData.refetch}
            editData={editData}
          />
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Brand Name",
      field: "brands_name",
    },
    {
      headerName: "Request Date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Attempts",
      field: "request_attempts",
    },
    {
      headerName: "Status",
      field: "brand_request_status",
      renderCell: (params) => chipByStatus(params.value),
    },
    {
      headerName: "Actions",
      field: "check4",
      renderCell: (params) => (
        <BrandView data={params.row} refetch={brandData.refetch} />
      ),
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            setEditData();
            setOpenBrandForm(true);
          }}
          sx={{ px: "20px" }}
        >
          Request Brand
        </Button>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={brandData.loading}
        rows={brandData?.data?.results || []}
        columns={columns}
      />
      <Box sx={{ padding: "10px" }} />
      {pagination}
      <BrandForm
        open={openBrandForm}
        onClose={() => setOpenBrandForm(false)}
        refetch={brandData.refetch}
        editData={editData}
      />
    </Box>
  );
}

function BrandForm({ open, onClose, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);

  const brandData = useFetch(full_brand_url, {
    run: open,
  });
  const sectionData = useFetch(document_sections_url + `?type=brand`, {
    run: open,
  });

  sectionData.data = sectionData?.data?.map((sec) => ({
    ...sec,
    docs: sec?.docs?.filter((doc) => doc.is_active === true),
  }));

  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        brand: validators.numberRequired,
        ...Object.assign(
          {},
          {},
          ...(sectionData?.data ? sectionData?.data : [])?.flatMap((s) =>
            s.docs.map((d) => ({
              ...(d.doc_num_require
                ? { [`${d.doc_name}_number`]: validators.stringRequired }
                : {}),
              ...(d.filerequire
                ? { [`${d.doc_name}_file`]: validators.fileRequired }
                : {}),
              ...(d.expiryrequire
                ? { [`${d.doc_name}_expiry`]: validators.dateRequired }
                : {}),
            }))
          )
        ),
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;

  useEffect(() => {
    if (editData) {
      methods.reset(editData);
    } else {
      methods.reset();
    }
  }, [open, editData, methods]);

  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    newData.seller = userId;
    try {
      const response = await (editData
        ? api.patch(`${request_brand_url}${editData.id}/`, newData)
        : api.post(request_brand_url, newData));
      if (response?.status === 200 || response?.status === 201) {
      } else {
        unknownError();
      }
      for (const section of sectionData.data) {
        for (const doc of section.docs) {
          await api.post(
            brand_document_url,
            jsonToFormData({
              doc_name: doc.doc_name,
              doc_number: newData[`${doc.doc_name}_number`],
              doc_file: newData[`${doc.doc_name}_file`],
              doc_expiry: newData[`${doc.doc_name}_expiry`],
              seller: userId,
              brand: newData.brand,
              doc_section: section.id,
              doc_type: doc.id,
            })
          );
        }
      }
      enqueueSnackbar("Data submitted successfully", {
        variant: "success",
      });
      onClose();
      refetch();
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  if (sectionData.loading || !sectionData.data) {
    return (
      <Dialog open={open} onClose={onClose}>
        <Box
          sx={{
            padding: "10px",
            width: "800px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => onClose()}
          >
            <X />
          </IconButton>

          <Loading />
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          padding: "10px",
          width: "800px",
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={() => onClose()}
        >
          <X />
        </IconButton>
        <Box sx={{ padding: "20px" }}>
          <Typography fontSize={20} fontWeight="500">
            Request Brand
          </Typography>
          <Box sx={{ p: "10px" }} />
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Select
                methods={methods}
                name="brand"
                label="Select Brand"
                placeholder="Select Brand"
                required
                loading={brandData.loading}
                options={brandData.data}
                generateOptionLabel={(x) =>
                  `${x.printable_name} ${x.is_global ? "(global)" : ""} ${
                    x.is_b2b ? "(B2B)" : ""
                  }`
                }
              />
              <Box sx={{ p: "5px" }} />
              {sectionData.data.map((section) => (
                <Box key={section.id}>
                  <Typography fontWeight="500" fontSize={16}>
                    {section.section.printable_name}
                  </Typography>
                  <Box sx={{ p: "5px" }} />
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    {section.docs.map((d) => (
                      <FieldSet>
                        {d.doc_num_require && (
                          <Input.Text
                            methods={methods}
                            name={`${d.doc_name}_number`}
                            label={`${d.doc_name} Number`}
                            placeholder={`${d.doc_name} Number`}
                            required
                          />
                        )}
                        {d.filerequire && (
                          <Input.File
                            methods={methods}
                            name={`${d.doc_name}_file`}
                            label={`${d.doc_name} File`}
                            placeholder={`${d.doc_name} File`}
                            required
                          />
                        )}
                        {d.expiryrequire && (
                          <Input.Date
                            methods={methods}
                            name={`${d.doc_name}_expiry`}
                            label={`${d.doc_name} Expiry Date`}
                            placeholder={`${d.doc_name} Expiry Date`}
                            required
                          />
                        )}
                      </FieldSet>
                    ))}
                  </Box>
                </Box>
              ))}
              <Box sx={{ p: "5px" }} />
              <Box className="equal-columns">
                <LoadingButton variant="outlined" onClick={onClose}>
                  Cancel
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </FieldSet>
          </Form>
        </Box>
      </Box>
    </Dialog>
  );
}

function BrandView({ data: d, refetch }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          fontWeight: "500",
          color: "primary.main",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        View
      </Box>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "800px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Typography fontSize={20} fontWeight="500">
            {d.brands_name}
          </Typography>
          <Box sx={{ p: "10px" }} />
          <View
            data={[
              {
                label: "Request Status",
                value: tickByStatus(d.brand_request_status),
              },
              {
                label: "Request attempts",
                value: d.request_attempts,
              },
              {
                label: "Comment From Approver",
                value: d.comments,
              },
              {
                label: "Documents",
                value: (
                  <BrandDocuments data={d} open={open} refetch={refetch} />
                ),
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
}

function BrandDocuments({ data: d, open, refetch }) {
  const userId = useSelector((state) => state.user.id);
  const sectionData = useFetch(
    document_sections_url + `?type=brand&brand=${d.brand}`,
    {
      run: open,
    }
  );
  const methods = useForm({
    defaultValues: d.docs,
    resolver: zodResolver(
      z.object({
        ...Object.assign(
          {},
          {},
          ...(sectionData?.data ? sectionData?.data : [])?.flatMap((s) =>
            s.docs.map((doc) => ({
              ...(doc.doc_num_require
                ? { [`${doc.doc_name}_number`]: validators.stringRequired }
                : {}),
              ...(doc.filerequire
                ? { [`${doc.doc_name}_file`]: validators.fileRequired }
                : {}),
              ...(doc.expiryrequire
                ? { [`${doc.doc_name}_expiry`]: validators.dateRequired }
                : {}),
            }))
          )
        ),
      })
    ),
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    newData.seller = userId;
    try {
      for (const section of sectionData.data) {
        for (const doc of section.docs) {
          await api.post(
            brand_document_url,
            jsonToFormData({
              doc_name: doc.doc_name,
              doc_number: newData[`${doc.doc_name}_number`],
              doc_file: newData[`${doc.doc_name}_file`],
              doc_expiry: newData[`${doc.doc_name}_expiry`],
              seller: userId,
              brand: d.brand,
              doc_section: section.id,
              doc_type: doc.id,
            })
          );
        }
      }
      enqueueSnackbar("Data submitted successfully", {
        variant: "success",
      });
      refetch();
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError(e);
      }
    }
  };

  sectionData.data = sectionData?.data?.map((sec) => ({
    ...sec,
    docs: sec?.docs?.filter(
      (doc) =>
        !d.docs.find(
          (d) =>
            d.doc_type === doc.id && doc.document_request_status !== "REJECTED"
        ) && doc.is_active === true
    ),
  }));

  return (
    <Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        rows={d.docs}
        columns={[
          {
            headerName: "Document Name",
            field: "doc_name",
          },
          {
            headerName: "Document Number",
            field: "doc_number",
          },
          {
            headerName: "Document Expiry",
            field: "doc_expiry",
            renderCell: (params) =>
              params.value ? moment(params.value).format("DD-MM-YYYY") : "-",
          },
          {
            headerName: "Status",
            field: "document_request_status",
            renderCell: (params) => chipByStatus(params.value),
          },
          {
            headerName: "View",
            field: "doc_file",
            renderCell: (params) => (
              <a href={params.value} target="_blank" rel="noreferrer">
                <Box
                  sx={{
                    fontWeight: "500",
                    color: "primary.main",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  View
                </Box>
              </a>
            ),
          },
        ]}
      />
      <Box sx={{ padding: "10px" }} />
      <Typography fontSize={14} fontWeight="500">
        Update Documents
      </Typography>
      {sectionData.loading ? (
        <Loading />
      ) : (
        <Box>
          <Typography fontSize={13}>
            {sectionData?.data?.flatMap((s) => s.docs).length > 0
              ? "Some documents need to be uploaded"
              : "All documents are uploaded"}
          </Typography>
          <Box sx={{ p: "5px" }} />
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              {sectionData?.data
                ?.filter((section) => section.docs)
                ?.map((section) => (
                  <Box key={section.id}>
                    <Typography fontWeight="500" fontSize={16}>
                      {section.section.printable_name}
                    </Typography>
                    <Box sx={{ p: "5px" }} />
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      {section.docs.map((d) => (
                        <FieldSet>
                          {d.doc_num_require && (
                            <Input.Text
                              methods={methods}
                              name={`${d.doc_name}_number`}
                              label={`${d.doc_name} Number`}
                              placeholder={`${d.doc_name} Number`}
                              required
                            />
                          )}
                          {d.filerequire && (
                            <Input.File
                              methods={methods}
                              name={`${d.doc_name}_file`}
                              label={`${d.doc_name} File`}
                              placeholder={`${d.doc_name} File`}
                              required
                            />
                          )}
                          {d.expiryrequire && (
                            <Input.Date
                              methods={methods}
                              name={`${d.doc_name}_expiry`}
                              label={`${d.doc_name} Expiry Date`}
                              placeholder={`${d.doc_name} Expiry Date`}
                              required
                            />
                          )}
                        </FieldSet>
                      ))}
                    </Box>
                  </Box>
                ))}
              <Box sx={{ p: "5px" }} />
              {sectionData?.data?.flatMap((s) => s.docs).length > 0 && (
                <Box>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              )}
            </FieldSet>
          </Form>
        </Box>
      )}
    </Box>
  );
}

function CategoryTab({ refetch, subPage, setSubPage }) {
  const [openCategoryForm, setOpenCategoryForm] = useState(false);
  const [editData, setEditData] = useState();
  const { data: categoryData, pagination } =
    useFetchWithPagination(category_url);

  if (categoryData.loading || !categoryData.data) {
    return <Loading />;
  }

  if (categoryData?.data?.results?.length === 0) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoCategory />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => setOpenCategoryForm(true)}>
            Request Category
          </Button>
          <CategoryForm
            open={openCategoryForm}
            onClose={() => setOpenCategoryForm(false)}
            refetch={categoryData.refetch}
            editData={editData}
          />
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Category",
      field: "category",
      renderCell: (params) =>
        `${params.row.category_first_name} > ${params.row.category_second_name} > ${params.row.category_third_name}`,
      wrap: true,
    },
    {
      headerName: "Request Date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Attempts",
      field: "request_attempts",
    },
    {
      headerName: "Status",
      field: "category_request_status",
      renderCell: (params) => chipByStatus(params.value),
    },
    {
      headerName: "Actions",
      field: "check4",
      renderCell: (params) => (
        <CategoryView data={params.row} refetch={categoryData.refetch} />
      ),
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            setEditData();
            setOpenCategoryForm(true);
          }}
          sx={{ px: "20px" }}
        >
          Request Category
        </Button>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={false}
        rows={categoryData?.data?.results || []}
        columns={columns}
      />
      <Box sx={{ padding: "10px" }} />
      {pagination}
      <CategoryForm
        open={openCategoryForm}
        onClose={() => setOpenCategoryForm(false)}
        refetch={categoryData.refetch}
        editData={editData}
      />
    </Box>
  );
}

function CategoryForm({ open, onClose, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);

  const cat1Data = useFetch(full_first_category_url);
  const [thirdCategory, setThirdCategory] = useState();

  const sectionData = useFetch(
    document_sections_url + `?type=category&category=${thirdCategory}`,
    {
      run: open && thirdCategory !== undefined,
    }
  );

  sectionData.data = sectionData?.data?.map((sec) => ({
    ...sec,
    docs: sec?.docs?.filter((doc) => doc.is_active === true),
  }));

  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        category_first: validators.numberRequired,
        category_second: validators.numberRequired,
        category_third: validators.numberRequired,
        ...Object.assign(
          {},
          {},
          ...(sectionData?.data ? sectionData?.data : [])?.flatMap((s) =>
            s.docs.map((d) => ({
              ...(d.doc_num_require
                ? { [`${d.doc_name}_number`]: validators.stringRequired }
                : {}),
              ...(d.filerequire
                ? { [`${d.doc_name}_file`]: validators.fileRequired }
                : {}),
              ...(d.expiryrequire
                ? { [`${d.doc_name}_expiry`]: validators.dateRequired }
                : {}),
            }))
          )
        ),
      })
    ),
    shouldFocusError: true,
  });

  const cat2Data = useFetch(
    `${full_second_category_url}?${objToParams({
      category_first: methods.watch("category_first"),
    })}`
  );
  const cat3Data = useFetch(
    `${full_third_category_url}?${objToParams({
      category_second: methods.watch("category_second"),
    })}`
  );

  useEffect(() => {
    if (editData) {
      methods.reset(editData);
    } else {
      methods.reset();
    }
  }, [open, editData, methods]);

  const loading = methods.formState.isSubmitting;

  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    newData.seller = userId;
    try {
      const response = await (editData
        ? api.patch(`${category_url}${editData.id}/`, newData)
        : api.post(category_url, newData));
      if (response?.status === 200 || response?.status === 201) {
        for (const section of sectionData.data) {
          for (const doc of section.docs) {
            await api.post(
              category_document_url,
              jsonToFormData({
                doc_name: doc.doc_name,
                doc_number: newData[`${doc.doc_name}_number`],
                doc_file: newData[`${doc.doc_name}_file`],
                doc_expiry: newData[`${doc.doc_name}_expiry`],
                seller: userId,
                category_first: newData.category_first,
                category_second: newData.category_second,
                category_third: newData.category_third,
                doc_section: section.id,
                doc_type: doc.id,
              })
            );
          }
        }
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        onClose();
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          padding: "10px",
          width: "800px",
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={onClose}
        >
          <X />
        </IconButton>
        <Box sx={{ padding: "20px" }}>
          <Typography fontSize={20} fontWeight="500">
            Request Category
          </Typography>
          <Box sx={{ p: "10px" }} />
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Select
                methods={methods}
                name="category_first"
                label="Category 1"
                placeholder="Category 1"
                required
                loading={cat1Data.loading}
                options={cat1Data.data}
                generateOptionLabel={(d) =>
                  `${d.printable_name}${d.is_global ? " (Global)" : ""}${
                    d.is_b2b ? " (B2B)" : ""
                  }`
                }
              />
              <Input.Select
                methods={methods}
                name="category_second"
                label="Category 2"
                placeholder="Category 2"
                required
                loading={cat2Data.loading}
                options={cat2Data.data}
                generateOptionLabel={(d) =>
                  `${d.printable_name}${d.is_global ? " (Global)" : ""}${
                    d.is_b2b ? " (B2B)" : ""
                  }`
                }
              />
              <Input.Select
                methods={methods}
                name="category_third"
                label="Category 3"
                placeholder="Category 3"
                required
                loading={cat3Data.loading}
                options={cat3Data.data}
                generateOptionLabel={(d) =>
                  `${d.printable_name}${d.is_global ? " (Global)" : ""}${
                    d.is_b2b ? " (B2B)" : ""
                  }`
                }
                onChangeFullData={(d) => setThirdCategory(d.id)}
              />
              <Box sx={{ p: "5px" }} />
              {sectionData?.data?.map((section) => (
                <Box key={section.id}>
                  <Typography fontWeight="500" fontSize={16}>
                    {section.section.printable_name}
                  </Typography>
                  <Box sx={{ p: "5px" }} />
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    {section.docs.map((d) => (
                      <FieldSet>
                        {d.doc_num_require && (
                          <Input.Text
                            methods={methods}
                            name={`${d.doc_name}_number`}
                            label={`${d.doc_name} Number`}
                            placeholder={`${d.doc_name} Number`}
                            required
                          />
                        )}
                        {d.filerequire && (
                          <Input.File
                            methods={methods}
                            name={`${d.doc_name}_file`}
                            label={`${d.doc_name} File`}
                            placeholder={`${d.doc_name} File`}
                            required
                          />
                        )}
                        {d.expiryrequire && (
                          <Input.Date
                            methods={methods}
                            name={`${d.doc_name}_expiry`}
                            label={`${d.doc_name} Expiry Date`}
                            placeholder={`${d.doc_name} Expiry Date`}
                            required
                          />
                        )}
                      </FieldSet>
                    ))}
                  </Box>
                </Box>
              ))}
              <Box sx={{ p: "5px" }} />
              <Box className="equal-columns">
                <LoadingButton variant="outlined" onClick={onClose}>
                  Cancel
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </FieldSet>
          </Form>
        </Box>
      </Box>
    </Dialog>
  );
}

function CategoryView({ data: d, refetch }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          fontWeight: "500",
          color: "primary.main",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        View
      </Box>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "800px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Typography fontSize={20} fontWeight="500">
            {`${d.category_first_name} > ${d.category_second_name} > ${d.category_third_name}`}
          </Typography>
          <Box sx={{ p: "10px" }} />
          <View
            data={[
              {
                label: "Request Status",
                value: tickByStatus(d.category_request_status),
              },
              {
                label: "Request attempts",
                value: d.request_attempts,
              },
              {
                label: "Comment From Approver",
                value: d.comments,
              },
              {
                label: "Documents",
                value: (
                  <CategoryDocuments data={d} open={open} refetch={refetch} />
                ),
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
}

function CategoryDocuments({ data: d, open, refetch }) {
  const userId = useSelector((state) => state.user.id);
  const sectionData = useFetch(
    document_sections_url + `?type=category&category=${d.category_third}`,
    {
      run: open,
    }
  );
  const methods = useForm({
    defaultValues: d.docs,
    resolver: zodResolver(
      z.object({
        ...Object.assign(
          {},
          {},
          ...(sectionData?.data ? sectionData?.data : [])?.flatMap((s) =>
            s.docs.map((doc) => ({
              ...(doc.doc_num_require
                ? { [`${doc.doc_name}_number`]: validators.stringRequired }
                : {}),
              ...(doc.filerequire
                ? { [`${doc.doc_name}_file`]: validators.fileRequired }
                : {}),
              ...(doc.expiryrequire
                ? { [`${doc.doc_name}_expiry`]: validators.dateRequired }
                : {}),
            }))
          )
        ),
      })
    ),
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      for (const section of sectionData?.data || []) {
        for (const doc of section?.docs || []) {
          await api.post(
            category_document_url,
            jsonToFormData({
              doc_name: doc.doc_name,
              doc_number: newData[`${doc.doc_name}_number`],
              doc_file: newData[`${doc.doc_name}_file`],
              doc_expiry: newData[`${doc.doc_name}_expiry`],
              seller: userId,
              category_first: d.category_first,
              category_second: d.category_second,
              category_third: d.category_third,
              doc_section: section.id,
              doc_type: doc.id,
            })
          );
        }
      }
      enqueueSnackbar("Data submitted successfully", {
        variant: "success",
      });
      refetch();
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError(e);
      }
    }
  };

  sectionData.data = sectionData?.data?.map((sec) => ({
    ...sec,
    docs: sec?.docs?.filter(
      (doc) =>
        !d.docs.find(
          (d) =>
            d.doc_type === doc.id && doc.document_request_status !== "REJECTED"
        ) && doc.is_active === true
    ),
  }));

  return (
    <Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        rows={d.docs}
        columns={[
          {
            headerName: "Document Name",
            field: "doc_name",
          },
          {
            headerName: "Document Number",
            field: "doc_number",
          },
          {
            headerName: "Document Expiry",
            field: "doc_expiry",
            renderCell: (params) =>
              params.value ? moment(params.value).format("DD-MM-YYYY") : "-",
          },
          {
            headerName: "Status",
            field: "document_request_status",
            renderCell: (params) => chipByStatus(params.value),
          },
          {
            headerName: "View",
            field: "doc_file",
            renderCell: (params) => (
              <a href={params.value} target="_blank" rel="noreferrer">
                <Box
                  sx={{
                    fontWeight: "500",
                    color: "primary.main",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  View
                </Box>
              </a>
            ),
          },
        ]}
      />
      <Box sx={{ padding: "10px" }} />
      <Typography fontSize={14} fontWeight="500">
        Update Documents
      </Typography>
      {sectionData.loading ? (
        <Loading />
      ) : (
        <Box>
          <Typography fontSize={13}>
            {sectionData?.data?.flatMap((s) => s.docs).length > 0
              ? "Some documents need to be uploaded"
              : "All documents are uploaded"}
          </Typography>
          <Box sx={{ p: "5px" }} />
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              {sectionData?.data
                ?.filter((section) => section?.docs?.length > 0)
                ?.map((section) => (
                  <Box key={section.id}>
                    <Typography fontWeight="500" fontSize={16}>
                      {section.section.printable_name}
                    </Typography>
                    <Box sx={{ p: "5px" }} />
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      {section.docs.map((d) => (
                        <FieldSet>
                          {d.doc_num_require && (
                            <Input.Text
                              methods={methods}
                              name={`${d.doc_name}_number`}
                              label={`${d.doc_name} Number`}
                              placeholder={`${d.doc_name} Number`}
                              required
                            />
                          )}
                          {d.filerequire && (
                            <Input.File
                              methods={methods}
                              name={`${d.doc_name}_file`}
                              label={`${d.doc_name} File`}
                              placeholder={`${d.doc_name} File`}
                              required
                            />
                          )}
                          {d.expiryrequire && (
                            <Input.Date
                              methods={methods}
                              name={`${d.doc_name}_expiry`}
                              label={`${d.doc_name} Expiry Date`}
                              placeholder={`${d.doc_name} Expiry Date`}
                              required
                            />
                          )}
                        </FieldSet>
                      ))}
                    </Box>
                  </Box>
                ))}
              <Box sx={{ p: "5px" }} />
              {sectionData?.data?.flatMap((s) => s.docs).length > 0 && (
                <Box>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              )}
            </FieldSet>
          </Form>
        </Box>
      )}
    </Box>
  );
}
