export const na = (value) => {
  if (value) return value;
  return "N/A";
};

export const namt = (value, symbol = "₹", sign = "") => {
  if (value)
    return (
      (sign ? `${sign} ` : "") +
      symbol +
      (Number(value) > 0 ? Number(value)?.toFixed(2) : Number("0")?.toFixed(2))
    );
  return (sign ? `${sign} ` : "") + symbol + "0.00";
};

export const naper = (value) => {
  if (value) return value + " %";
  return "0.00 %";
};

export const na2 = (value, formated) => {
  if (value) return formated;
  return "N/A";
};
