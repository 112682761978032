import { Box, Divider } from "@mui/material";

export function View({ data }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        fontSize: "14px",
      }}
    >
      {data.map((d, index) => (
        <>
          <Box key={index}>
            <Box component="span" fontWeight="500">
              {d.label}:{" "}
            </Box>
            {d.value}
          </Box>
          {data.length - 1 > index && <Divider key={index + "divider"} />}
        </>
      ))}
    </Box>
  );
}
