import { Box, IconButton, InputAdornment, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  country_url,
  forgot_password_password_change_url,
  forgot_password_submit_otp_url,
  forgot_password_url,
  user_resend_otp_url,
} from "../api/urls";
import { MobileNumberDropdown, useFetchPublic } from "./root";
import { useEffect, useState } from "react";
import { validators } from "../utils/validators";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { unknownError } from "../utils/unknownError";
import { enqueueSnackbar } from "notistack";
import { apiPublic } from "../api/api";
import { FieldSet } from "../components/form";
import { Input } from "../components/input";
import { Eye, EyeOff } from "lucide-react";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";

export function ForgotPassword() {
  return (
    <Box
      sx={{
        padding: "20px",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ForgotPassordForm />
    </Box>
  );
}

function ForgotPassordForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const countryData = useFetchPublic(country_url);
  const [step, setStep] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const remove = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      } else {
        clearTimeout(remove);
      }
    }, 1000);
    return () => {
      clearTimeout(remove);
    };
  }, [seconds]);

  const schemas = [
    z
      .object({
        mobile_number: validators.stringRequired,
        country: validators.numberRequired,
      })
      .superRefine((values, ctx) => {
        if (countryData?.data) {
          const regex = countryData.data.find(
            (c) => c.country_mobile_code === values.country
          )?.country_regex;
          if (
            regex &&
            !RegExp(regex).test(`${values.country}${values.mobile_number}`)
          ) {
            ctx.addIssue({
              message: "Invalid mobile no",
              path: ["mobile_number"],
            });
          }
        }
      }),
    z.object({ recepient_otp: validators.otpRequired }),
    z
      .object({
        password: validators.stringRequired,
        confirm_password: validators.stringRequired,
      })
      .superRefine((values, ctx) => {
        if (values.confirm_password !== values.password) {
          ctx.addIssue({
            message: "Password and confirm password should be same",
            path: ["confirm_password"],
          });
        }
      }),
  ];
  const methods = useForm({ resolver: zodResolver(schemas[step]) });
  const { setValue } = methods;
  const loading = methods.formState.isSubmitting;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    if (countryData?.data) {
      const india = countryData.data.find((c) => c.country_mobile_code === 91);
      setValue("country", india.country_mobile_code);
      setValue("alpha_two_code", india.alpha_two_code);
    }
  }, [countryData.data, setValue]);

  const submitByStep = [
    async () => {
      const newData = { ...methods.getValues() };
      newData.mobile_number = newData.country + newData.mobile_number;
      try {
        const response = await apiPublic.post(
          `/${newData.alpha_two_code}${forgot_password_url}`,
          newData
        );
        if (response?.status === 200) {
          setValue("token", response?.data.data);
          setStep(step + 1);
          setSeconds(30);
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    },
    async () => {
      const values = methods.getValues();
      const newData = { recipient_otp: values.recepient_otp };
      try {
        const response = await apiPublic.post(
          `/${values.alpha_two_code}${forgot_password_submit_otp_url}`,
          newData,
          { headers: { Token: values.token } }
        );
        if (response?.status === 200) {
          setStep(step + 1);
        } else if (response?.status === 400) {
          if (response?.data?.data) {
            enqueueSnackbar(response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    },
    async () => {
      const values = methods.getValues();
      const newData = {
        password: values.password,
        confirm_password: values.confirm_password,
        recepient_otp: values.recepient_otp,
      };
      try {
        const response = await apiPublic.post(
          `/${values.alpha_two_code}${forgot_password_password_change_url}`,
          newData,
          { headers: { Token: values.token } }
        );
        if (response?.status === 200) {
          enqueueSnackbar(
            "Password changed successfully, Please login to complete on-boarding process",
            { variant: "success" }
          );
          navigate("/dashboard");
        } else if (response?.status === 400) {
          if (response?.data?.data) {
            enqueueSnackbar(response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    },
  ];

  return (
    <form
      style={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
      className="login-form"
      onSubmit={methods.handleSubmit(submitByStep[step])}
    >
      {step === 0 && (
        <>
          <h1 className="color-accent">Forgot Password</h1>
          <p className="color-accent para">
            Enter your registered mobile number
          </p>
          <div style={{ padding: "5px" }} />
          <FieldSet>
            <Input.Text
              methods={methods}
              label="Mobile number"
              placeholder="Mobile number"
              name="mobile_number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Controller
                      control={methods.control}
                      name="country"
                      render={({ field }) => (
                        <MobileNumberDropdown
                          countryData={countryData}
                          value={field.value}
                          onChange={(v) => {
                            field.onChange(v.country_mobile_code);
                            setValue("alpha_two_code", v.alpha_two_code);
                          }}
                        />
                      )}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FieldSet>
          <div style={{ padding: "5px" }} />
          <div style={{ padding: "5px" }} />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
          >
            Next
          </LoadingButton>
        </>
      )}
      {step === 1 && (
        <>
          <h1 className="color-accent">Enter OTP</h1>
          <Box sx={{ p: "10px" }} />
          <p className="color-accent para" style={{ textAlign: "center" }}>
            Enter OTP sent to +{methods.watch("country")}
            {methods.watch("mobile_number")} to verify mobile number
          </p>
          <Box sx={{ p: "5px" }} />
          <Input.OTP methods={methods} name="recepient_otp" />
          <div
            style={
              seconds <= 0
                ? {
                    marginLeft: "auto",
                  }
                : {
                    margin: "auto",
                  }
            }
          >
            {seconds <= 0 ? (
              <p
                className="forgot-password para"
                style={{ marginRight: "30px", cursor: "pointer" }}
                onClick={async () => {
                  setSeconds(30);
                  const values = methods.getValues();
                  const response = await apiPublic.post(
                    `/${values.alpha_two_code}${user_resend_otp_url}`,
                    {
                      country: values.country,
                      mobile_number:
                        values.country?.toString() + values.mobile_number,
                    },
                    { headers: { Token: values.token } }
                  );
                  if (response?.data.token) {
                    setValue("token", response?.data.token);
                  }
                }}
              >
                resend otp
              </p>
            ) : (
              <p className="para">
                00:{seconds < 10 ? "0" : ""}
                {seconds}s
              </p>
            )}
          </div>
          <div style={{ padding: "5px" }} />
          <div style={{ padding: "5px" }} />
          <LoadingButton
            type="submit"
            sx={{ width: "250px" }}
            variant="contained"
            loading={loading}
          >
            Verify
          </LoadingButton>
        </>
      )}
      {step === 2 && (
        <>
          <h1 className="color-accent">New Password</h1>
          <p className="color-accent para">Create Password</p>
          <div style={{ padding: "5px" }} />
          <FieldSet>
            <Input.Text
              methods={methods}
              name="password"
              label="Password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Input.Text
              methods={methods}
              name="confirm_password"
              label="Confirm password"
              placeholder="Confirm password"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword((show) => !show)}
                      edge="end"
                    >
                      {showConfirmPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FieldSet>
          <div style={{ padding: "5px" }} />
          <div style={{ padding: "5px" }} />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
          >
            Next
          </LoadingButton>
        </>
      )}
    </form>
  );
}
