import { z } from "zod";

const stringRequired = z
  .string({
    required_error: "This field is requied",
    invalid_type_error: "This field is required",
    message: "This field is requied",
  })
  .min(1, {
    required_error: "This field is requied",
    invalid_type_error: "This field is required",
    message: "This field is requied",
  });

// const MAX_FILE_SIZE = 500000;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const imageRequired = z
  .any()
  .refine((file) => !!file?.name, "This field is required.")
  // .refine((file) => file?.size <= MAX_FILE_SIZE, `Max file size is 5MB.`)
  .refine(
    (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
    ".jpg, .jpeg, .png and .webp files are accepted."
  );

const fileRequired = z
  .any()
  .refine((file) => !!file?.name, "This field is required.");
// .refine((file) => file?.size <= MAX_FILE_SIZE, `Max file size is 5MB.`);
// .refine(
//   (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
//   ".jpg, .jpeg, .png and .webp files are accepted."
// );

const videoRequired = z
  .any()
  .refine((file) => !!file?.name, "This field is required.");
// .refine((file) => file?.size <= MAX_Video_SIZE, `Max file size is 5MB.`);
// .refine(
//   (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
//   ".jpg, .jpeg, .png and .webp files are accepted."
// );

const dateRequired = z.coerce.date("This field is requied");

const numberRequired = z.number({
  required_error: "This field is requied",
  invalid_type_error: "This field is required",
  message: "This field is requied",
});

const emailRequired = z
  .string()
  .min(1, { message: "This field has to be filled." })
  .email("This is not a valid email.");

const otpRequired = z
  .string()
  .min(6, { message: "This field has to be filled." });

const numberArrayRequired = z.array(z.number()).min(1, {
  required_error: "This field is requied",
  invalid_type_error: "This field is required",
  message: "This field is requied",
});

export const validators = {
  stringRequired,
  imageRequired,
  dateRequired,
  numberRequired,
  fileRequired,
  emailRequired,
  otpRequired,
  videoRequired,
  numberArrayRequired,
};
