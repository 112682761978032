import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  change_mobile_url,
  change_mobile_verify_url,
  company_type_url,
  country_url,
  seller_type_url,
  update_password_url,
  user_email_update_url,
  user_profile_url,
  user_resend_otp_url,
} from "../../api/urls";
import { useFetch } from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validators } from "../../utils/validators";
import { api, apiPublic } from "../../api/api";
import { jsonToFormData } from "../../utils/jsonToFromData";
import { enqueueSnackbar } from "notistack";
import {
  setAccountId,
  setMobileNumber,
  setProfileImage,
  setUserName,
} from "../../redux/userStore";
import { unknownError } from "../../utils/unknownError";
import { X } from "lucide-react";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { idToname } from "../../utils/idToName";
import { z } from "zod";
import { MobileNumberDropdown, useFetchPublic } from "../root";

export function AccountSection({ data, refetch }) {
  const sellerTypeData = useFetch(seller_type_url);
  const companyTypeData = useFetch(company_type_url);
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            height: "100px",
            width: "100px",
            bgcolor: "primary.main",
            fontSize: "50px",
            color: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.text.primary
                : "none",
          }}
          src={data?.company_image}
        >
          {data?.firstname?.[0]?.toUpperCase()}
        </Avatar>
        <UpdatePhoto data={data} refetch={refetch} />
      </Box>
      <Divider />
      {data?.company_name ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Store Name</Box>
            <Box sx={{ textAlign: "justify" }}>{data?.company_name}</Box>
          </Box>
          <UpdateStoreName data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateStoreName
            data={data}
            triggerText="Add Store Name"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box>
          <Box sx={{ fontWeight: "500" }}>Account ID</Box>
          <Box sx={{ textAlign: "justify" }}>
            {data?.account_id ? data?.account_id : "Not set"}
          </Box>
        </Box>
        {!data?.account_id && <UpdateAccountId data={data} refetch={refetch} />}
      </Box>

      <Divider />
      {data?.description ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Description</Box>
            <Box sx={{ textAlign: "justify" }}>{data.description}</Box>
          </Box>
          <UpdateDescription data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateDescription
            data={data}
            triggerText="Add Store Description"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      {data?.firstname ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Name</Box>
            <Box sx={{ textAlign: "justify" }}>
              {data.firstname} {data.middlename} {data.lastname}
            </Box>
          </Box>
          <UpdateName data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateName
            data={data}
            triggerText="Add Your Name"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      {data?.email_id ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Email</Box>
            <Box sx={{ textAlign: "justify" }}>{data.email_id}</Box>
          </Box>
          <UpdateEmail data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateEmail
            data={data}
            triggerText="Add Your Email"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box>
          <Box sx={{ fontWeight: "500" }}>Mobile Number</Box>
          <Box sx={{ textAlign: "justify" }}>{data?.mobile_number}</Box>
        </Box>
        <UpdateMobileNumber data={data} refetch={refetch} />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box>
          <Box sx={{ fontWeight: "500" }}>Password</Box>
          <Box sx={{ textAlign: "justify" }}>***************</Box>
        </Box>
        <UpdatePassword data={data} refetch={refetch} />
      </Box>
      <Divider />
      {data?.bizz_type ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Seller Type</Box>
            <Box sx={{ textAlign: "justify" }}>
              {idToname(data?.bizz_type, sellerTypeData.data)}
            </Box>
          </Box>
          <UpdateSellerType data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateSellerType
            data={data}
            triggerText="Add Seller Type"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      {data?.orgzn_type ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Company Type</Box>
            <Box sx={{ textAlign: "justify" }}>
              {idToname(data?.orgzn_type, companyTypeData.data)}
            </Box>
          </Box>
          <UpdateCompanyType data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateCompanyType
            data={data}
            triggerText="Add Company Type"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      {data?.website ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <Box sx={{ fontWeight: "500" }}>Company Website URL</Box>
            <Box sx={{ textAlign: "justify" }}>{data?.website}</Box>
          </Box>
          <UpdateCompanyUrl data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateCompanyUrl
            data={data}
            triggerText="Add Company Website Url"
            refetch={refetch}
          />
        </Box>
      )}
    </Box>
  );
}

function UpdateAccountId({ data, refetch }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        account_id: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Account ID added successfully", {
          variant: "success",
        });
        dispatch(setAccountId(newData.account_id));
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add Account ID</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Text
                methods={methods}
                name="account_id"
                label="Account ID"
                placeholder="Enter your Account ID"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdatePhoto({ data, refetch }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        company_image: validators.imageRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        jsonToFormData(newData)
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        if (response?.data?.company_image) {
          dispatch(setProfileImage(response?.data.company_image));
        }
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>Update</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.File
                methods={methods}
                name="company_image"
                label="Profile picture"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateStoreName({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        company_name: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Text
                methods={methods}
                name="company_name"
                label="Store name"
                placeholder="Current Store Name"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateDescription({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        description: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Text
                methods={methods}
                name="description"
                label="About Store (Will be shown in sell profile of the app)"
                placeholder="About Store (Will be shown in sell profile of the app)"
                multiline
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateName({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        // fullname: validators.stringRequired,
        firstname: validators.stringRequired,
        middlename: validators.stringRequired,
        lastname: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
        dispatch(
          setUserName(
            newData.firstname +
              // " " +
              // newData.middlename +
              " " +
              newData.lastname
          )
        );
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              {/* <Input.Text
                methods={methods}
                name="fullname"
                label="Full name"
                placeholder="Your full name"
                required
              /> */}
              <Input.Text
                methods={methods}
                name="firstname"
                label="First name"
                placeholder="Your first name"
                required
              />
              <Input.Text
                methods={methods}
                name="middlename"
                label="Middle name"
                placeholder="Your middle name"
                required
              />
              <Input.Text
                methods={methods}
                name="lastname"
                label="Last name"
                placeholder="Your last name"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateEmail({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        email_id: validators.emailRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.post(`${user_email_update_url}`, newData);
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Text
                methods={methods}
                name="email_id"
                label="Email"
                placeholder="Your email"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateMobileNumber({ triggerText = "Update", refetch }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const remove = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      } else {
        clearTimeout(remove);
      }
    }, 1000);
    return () => {
      clearTimeout(remove);
    };
  }, [seconds]);
  const methods = useForm({
    resolver: zodResolver(
      [
        z
          .object({
            country: validators.numberRequired,
            mobile_number: validators.stringRequired,
          })
          .superRefine((values, ctx) => {
            if (countryData?.data) {
              const regex = countryData.data.find(
                (c) => c.country_mobile_code === values.country
              )?.country_regex;
              if (
                regex &&
                !RegExp(regex).test(`${values.country}${values.mobile_number}`)
              ) {
                ctx.addIssue({
                  message: "Invalid mobile no",
                  path: ["mobile_number"],
                });
              }
            }
          }),
        z.object({
          recipient_otp: validators.stringRequired,
        }),
      ][step]
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    if (step === 0) {
      const values = methods.getValues();
      try {
        const response = await api.post(change_mobile_url, {
          country_code: values.country,
          mobile_number: `${values.country}${values.mobile_number}`,
        });
        if (response?.status === 200) {
          setValue("token", response?.data.token);
          setStep(step + 1);
          setSeconds(30);
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    }
    if (step === 1) {
      const values = methods.getValues();
      try {
        const response = await api.post(
          change_mobile_verify_url,
          {
            recipient_otp: values.recipient_otp,
            mobile_number: `${values.country}${values.mobile_number}`,
          },
          {
            headers: {
              Token: values.token,
            },
          }
        );
        if (response?.status === 200) {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });
          dispatch(setMobileNumber(methods.getValues().mobile_number));
          refetch();
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    }
  };
  const countryData = useFetchPublic(country_url);
  const { setValue } = methods;
  useEffect(() => {
    if (countryData?.data) {
      const india = countryData.data.find((c) => c.country_mobile_code === 91);
      setValue("country", india?.country_mobile_code);
      setValue("alpha_two_code", india.alpha_two_code);
    }
  }, [countryData.data, setValue]);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            {step === 0 && (
              <FieldSet>
                <Input.Text
                  methods={methods}
                  label="Mobile number"
                  placeholder="Mobile number"
                  name="mobile_number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Controller
                          control={methods.control}
                          name="country"
                          render={({ field }) => (
                            <MobileNumberDropdown
                              countryData={countryData}
                              value={field.value}
                              onChange={(v) => {
                                field.onChange(v?.country_mobile_code);
                                methods.setValue(
                                  "alpha_two_code",
                                  v.alpha_two_code
                                );
                              }}
                            />
                          )}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ p: "5px" }} />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </FieldSet>
            )}
            {step === 1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p className="color-accent para">
                  Enter OTP sent to +{methods.watch("country")}
                  {methods.watch("mobile_number")} to verify mobile number
                </p>
                <Box sx={{ p: "5px" }} />
                <Input.OTP methods={methods} name="recipient_otp" />
                <div
                  style={
                    seconds <= 0
                      ? {
                          marginLeft: "auto",
                        }
                      : {
                          margin: "auto",
                        }
                  }
                >
                  {seconds <= 0 ? (
                    <p
                      className="forgot-password para"
                      style={{ marginRight: "30px" }}
                      onClick={async () => {
                        setSeconds(30);
                        const values = methods.getValues();
                        const response = await apiPublic.post(
                          `/${values.alpha_two_code}${user_resend_otp_url}`,
                          {
                            country: values.country,
                            mobile_number:
                              values.country?.toString() + values.mobile_number,
                          },
                          { headers: { Token: values.token } }
                        );
                        if (response?.data.token) {
                          setValue("token", response?.data.token);
                        }
                      }}
                    >
                      resend otp
                    </p>
                  ) : (
                    <p className="para">
                      00:{seconds < 10 ? "0" : ""}
                      {seconds}s
                    </p>
                  )}
                </div>
                <div style={{ padding: "5px" }} />
                <div style={{ padding: "5px" }} />
                <LoadingButton
                  type="submit"
                  sx={{ width: "250px" }}
                  variant="contained"
                  loading={loading}
                >
                  Verify
                </LoadingButton>
              </Box>
            )}
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdatePassword({ triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z
        .object({
          old_password: validators.stringRequired,
          new_password: validators.stringRequired,
          confirm_password: validators.stringRequired,
        })
        .superRefine((values, ctx) => {
          if (values.confirm_password !== values.new_password) {
            ctx.addIssue({
              message: "Password and confirm password should be same",
              path: ["confirm_password"],
            });
          }
        })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.post(update_password_url, newData);
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        if (response?.data?.data) {
          enqueueSnackbar(response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Text
                methods={methods}
                name="old_password"
                label="Old password"
                placeholder="Old password"
                required
              />
              <Input.Text
                methods={methods}
                name="new_password"
                label="Password"
                placeholder="Password"
                required
              />
              <Input.Text
                methods={methods}
                name="confirm_password"
                label="Confirm password"
                placeholder="Confirm password"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateSellerType({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        bizz_type: validators.numberRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  const sellerTypeData = useFetch(seller_type_url);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Select
                methods={methods}
                name="bizz_type"
                label="Seller type"
                placeholder="Seller type"
                options={sellerTypeData.data}
                loading={sellerTypeData.loading}
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateCompanyType({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        orgzn_type: validators.numberRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  const companyTypeData = useFetch(company_type_url);
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Select
                methods={methods}
                name="orgzn_type"
                label="Company type"
                placeholder="Company type"
                options={companyTypeData.data}
                loading={companyTypeData.loading}
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}

function UpdateCompanyUrl({ data, triggerText = "Update", refetch }) {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        website: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        newData
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.Text
                methods={methods}
                name="website"
                label="Company website URL"
                placeholder="Company website URL"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}
