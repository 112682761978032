import { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../api/api";
import { unknownError } from "../utils/unknownError";

export function useFetch(url, options = {}) {
  const { run = true } = options;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [refetcher, setRefetcher] = useState(false);

  useEffect(() => {
    if (run) {
      const source = axios.CancelToken.source();
      if (url) {
        setLoading(true);
        setData(undefined);
        try {
          api
            .get(url, { cancelToken: source.token })
            .then((res) => {
              res.data && setData(res.data);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              unknownError(err);
            });
        } catch (err) {
          setLoading(false);
          unknownError(err);
        }
      }
      return () => {
        source.cancel();
      };
    }
  }, [url, refetcher, run]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return { data, loading, refetch };
}
