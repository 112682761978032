import { useState } from "react";
import { useParamsState } from "../hooks/useParamState";
import { useFetch } from "../hooks/useFetch";
import {
  contact_us_url,
  faq_url,
  help_center_url,
  subject_category_url,
  subject_sub_category_url,
} from "../api/urls";
import { Loading } from "../components/loading";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { TextChip } from "../components/textChip";
import { TableComponent } from "../components/table";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validators } from "../utils/validators";
import { generateUID } from "../utils/generateUID";
import { api } from "../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../utils/unknownError";
import { FieldSet, Form } from "../components/form";
import { Input } from "../components/input";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { ReactComponent as NoCoupon } from "../images/noCoupon.svg";
import moment from "moment";
import { jsonToFormData } from "../utils/jsonToFromData";
import { useSelector } from "react-redux";
import { Headset, Mail, Search, Ticket, X } from "lucide-react";
import { View } from "../components/view";
import { Link } from "react-router-dom";
import { objToParams } from "../utils/objToParams";
import { Authorization } from "../components/authorization";
import { modules, perms } from "../api/codes";
import { BackButton } from "../components/backButton";

export function FAQ() {
  const [search, setSearch] = useState("");
  const data = useFetch(faq_url);

  const filteredData = data?.data?.filter(
    (item) =>
      item.title.toLowerCase().includes(search.toLowerCase()) ||
      item.description.toLowerCase().includes(search.toLowerCase())
  );

  const contactUsData = useFetch(contact_us_url);

  return (
    <Box
      sx={{
        padding: { xs: "15px", sm: "30px" },
        bgcolor: "background.paper",
        borderRadius: "10px",
        height: "calc(100vh - 100px)",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography fontSize={18} fontWeight="500">
          Contact Us or Raise a Ticket
        </Typography>
        <Typography
          fontSize={15}
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <Headset />{" "}
          <Link to={`tel:${contactUsData?.data?.[0]?.mobile_number}`}>
            {contactUsData?.data?.[0]?.mobile_number}
          </Link>
        </Typography>
        <Typography
          fontSize={15}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginTop: "-5px",
          }}
        >
          <Mail />{" "}
          <Link to={`mailto:${contactUsData?.data?.[0]?.email}`}>
            {contactUsData?.data?.[0]?.email}
          </Link>
        </Typography>
      </Box>
      <Box sx={{ padding: "15px" }} />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TextField
          placeholder="Search"
          size="small"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: "divider",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            sx: {
              bgcolor: "background.default",
            },
          }}
        />
        <Link
          to="/help_center/tickets"
          style={{ width: { xs: "100%", sm: "auto" } }}
        >
          <Button
            startIcon={<Ticket />}
            variant="contained"
            fullWidth
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            My Tickets
          </Button>
        </Link>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          position: "relative",
          height: "calc(100% - 60px)",
          overflow: "hidden",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "0px", md: "220px" },
            fontSize: "14px",
            position: { xs: "static", md: "sticky" },
            top: 0,
            maxHeight: { xs: "0px", md: "100%" },
            overflowY: "auto",
            display: { xs: "none", md: "block" },
            borderBottom: { xs: "none", md: "none" },
            borderColor: "divider",
          }}
        >
          <Typography fontSize={14} fontWeight="500">
            Frequently Asked Questions ?
          </Typography>
          {filteredData?.map((d) => (
            <Box
              to={`/help_center#${d.title}`}
              key={d.id}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById(d.title)
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <Box
                sx={{
                  fontSize: "13px",
                  cursor: "pointer",
                  py: 0.5,
                  "&:hover": { color: "primary.main", fontWeight: "500" },
                }}
              >
                {d.title}
              </Box>
            </Box>
          ))}
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { xs: "none", md: "block" } }}
        />
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            display: "block",
          }}
        >
          {filteredData?.map((d) => (
            <Box id={d.title} key={d.id} sx={{ mb: 3 }}>
              <Typography fontSize={{ xs: 16, sm: 18 }} fontWeight="500">
                {d.title}
              </Typography>
              <Box sx={{ padding: "1px" }} />
              <Box sx={{ fontSize: { xs: 13, sm: 14 } }}>{d.description}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export function Tickets() {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const [editData, setEditData] = useState();
  const data = useFetch(help_center_url);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <Authorization module={modules.HelpCenter} perm={perms.add} page>
        <RaiseTicketForm
          setSubPage={setSubPage}
          editData={editData}
          refetch={data.refetch}
        />
      </Authorization>
    );
  }

  if (data?.data?.length === 0) {
    return (
      <Box
        sx={{
          padding: "30px",
          bgcolor: "background.paper",
          borderRadius: "10px",
        }}
      >
        <BackButton />
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoCoupon />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Authorization module={modules.HelpCenter} perm={perms.add}>
            <Button variant="contained" onClick={() => setSubPage("add")}>
              Raise Ticket
            </Button>
          </Authorization>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Ticket ID",
      field: "id",
    },
    {
      headerName: "Subject",
      field: "subject",
    },
    {
      headerName: "Created",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Status",
      field: "is_active",
      renderCell: () => (
        <TextChip text="TODO" color="#00B087" maxWidth="100px" />
      ),
    },
    {
      headerName: "Details",
      field: "view",
      renderCell: (params) => <HelpCenterView data={params.row} />,
    },
  ];

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <BackButton />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Authorization module={modules.HelpCenter} perm={perms.add}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setEditData();
              setSubPage("add");
            }}
            sx={{ px: "20px" }}
          >
            Raise Ticket
          </Button>
        </Authorization>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={data.loading}
        rows={data.data || []}
        columns={columns}
      />
    </Box>
  );
}

function RaiseTicketForm({ setSubPage, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        subject_category: validators.numberRequired,
        subject_sub_category: validators.numberRequired,
        subject: validators.stringRequired,
        description: validators.stringRequired,
        // form_data: z.array(z.any()).min(1, "This field is required"),
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;

  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    if (newData.form_data) {
      newData.form_data = newData.form_data.map((file) => ({
        files: file.file,
      }));
    }
    newData.seller = userId;
    try {
      const response = await (editData
        ? api.patch(
            `${help_center_url}${editData.id}/`,
            jsonToFormData(newData)
          )
        : api.post(help_center_url, jsonToFormData(newData)));
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  const subjectCategoryData = useFetch(subject_category_url);
  const subjectSubCategoryData = useFetch(
    subject_sub_category_url +
      `?${objToParams({ category: methods.watch("subject_category") })}`
  );

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Select
            methods={methods}
            name="subject_category"
            label="Subject Category"
            placeholder="Subject Category"
            required
            options={subjectCategoryData.data}
            loading={subjectCategoryData.loading}
          />
          <Input.Select
            methods={methods}
            name="subject_sub_category"
            label="Subject Sub-Category"
            placeholder="Subject Sub-Category"
            required
            options={subjectSubCategoryData.data}
            loading={subjectSubCategoryData.loading}
          />
          <Input.Text
            methods={methods}
            name="subject"
            label="Subject"
            placeholder="Subject"
            required
          />
          <Input.Text
            methods={methods}
            name="description"
            label="Describe Issue"
            placeholder="Describe Issue"
            required
            multiline
          />
          <Input.MultipleFile
            methods={methods}
            name="form_data"
            label="Attach Files"
            placeholder="Attach Files"
          />
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}

function HelpCenterView({ data: d }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          fontWeight: "500",
          color: "primary.main",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        View
      </Box>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Typography fontSize={20} fontWeight="500">
            {d.subject}
          </Typography>
          <Box sx={{ p: "10px" }} />
          <View
            data={[
              {
                label: "Status",
                value: "TODO",
              },
              {
                label: "description",
                value: d.description,
              },
              {
                label: "Attachements",
                value: (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      marginTop: "5px",
                    }}
                  >
                    {Object.keys(d.form_data).map((key, index) => (
                      <Link to={d.form_data[key]} target="_blank">
                        <Button variant="contained" key={index}>
                          View file {index + 1}
                        </Button>
                      </Link>
                    ))}
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
}
