import { Box } from "@mui/material";
import { ChevronLeftCircle } from "lucide-react";
import { Link } from "react-router-dom";

export function getBackUrl() {
  let path = window.location.pathname;
  const slugs = path.slice(1).split("/");
  slugs.pop();
  if (slugs[slugs.length - 1] === "edit") {
    slugs.pop();
  }
  return "/" + slugs.join("/");
}

export function BackButton({ href, disabled: propDisabled }) {
  const path = getBackUrl();

  const disabled = path ? propDisabled : true;

  return (
    <Link
      to={href ? href : path}
      style={{
        width: "fit-content",
        pointerEvents: disabled ? "none" : "all",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
          justifyContent: "center",
          color: disabled ? "action.disabled" : "text.primary",
        }}
      >
        <ChevronLeftCircle style={{ height: "18px", width: "18px" }} />
        <Box
          sx={{
            fontSize: "14px",
            marginTop: "1px",
            "&:hover": { textDecoration: disabled ? "normal" : "underline" },
          }}
        >
          Back
        </Box>
      </Box>
    </Link>
  );
}
