import axios from "axios";
import { logout, setAccessToken } from "../redux/userStore";
import {
  base_url,
  country_url,
  refresh_token_url,
  user_logout_url,
} from "./urls";

export const api = axios.create({
  baseURL: base_url,
});

export const apiPublic = axios.create({
  baseURL: base_url,
});

export const setup = (store) => {
  api.interceptors.request.use(
    (config) => {
      const token = store.getState().user.accessToken;
      if (!config.url.startsWith("/in") && config.url !== country_url) {
        config.url = "/in" + config.url;
      }
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  api.interceptors.response?.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err.response && err.response?.status === 401) {
        try {
          const rs = await apiPublic
            .post(refresh_token_url, {
              refresh: store.getState().user.refreshToken,
            })
            .catch(async (error) => {
              if (error?.response && error.response?.status === 401) {
                try {
                  await api.get(user_logout_url);
                } catch (e) {
                  console.error(e);
                }
                await dispatch(logout());
                return 0;
              }
            });

          const { access } = rs.data;

          dispatch(setAccessToken(access));

          return api(err.config);
        } catch (error) {
          if (error.response && error.response?.status === 401) {
            try {
              await api.get(user_logout_url);
            } catch (e) {
              console.error(e);
            }
            await dispatch(logout());
            return 0;
          }
          return Promise.reject(error);
        }
      } else if (
        err.response &&
        err.response?.status === 500 &&
        base_url !== "https://api1.tagonstore.com"
      ) {
        const myWindow = window.open("", "response", "resizable=yes");
        myWindow.document.write(err.response?.data);
      }

      return Promise.reject(err);
    }
  );
};
