import { useState } from "react";
import { useParamsState } from "../hooks/useParamState";
import { roles_url } from "../api/urls";
import { Loading } from "../components/loading";
import { Box, Button } from "@mui/material";
import { Actions } from "../components/actions";
import { Pencil, Trash2 } from "lucide-react";
import { TableComponent } from "../components/table";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validators } from "../utils/validators";
import { generateUID } from "../utils/generateUID";
import { api } from "../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../utils/unknownError";
import { FieldSet, Form } from "../components/form";
import { Input } from "../components/input";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { ReactComponent as NoCoupon } from "../images/noCoupon.svg";
import { sidebarConfig } from "../config/sidebarConfig";
import moment from "moment";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { perms } from "../api/codes";
import _ from "lodash";

export function Roles() {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const [editData, setEditData] = useState();
  const { data: rolesData, pagination } = useFetchWithPagination(roles_url);

  if (rolesData.loading || !rolesData.data) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <RolesForm
        setSubPage={setSubPage}
        editData={editData}
        refetch={rolesData.refetch}
      />
    );
  }

  if (rolesData?.data?.length === 0) {
    return (
      <Box
        sx={{
          padding: "30px",
          bgcolor: "background.paper",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoCoupon />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => setSubPage("add")}>
            Add Role
          </Button>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Role",
      field: "role_name",
    },
    {
      headerName: "Date Created",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Date Updated",
      field: "updated_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Actions",
      renderCell: (params) => (
        <Actions
          options={[
            {
              icon: Pencil,
              name: "Edit",
              onClick: () => {
                setEditData(params.row);
                setSubPage("add");
              },
            },
            {
              icon: Trash2,
              name: "Delete",
              deleteUrl: roles_url,
              deleteId: params.row.id,
              refetch: rolesData.refetch,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            setEditData();
            setSubPage("add");
          }}
          sx={{ px: "20px" }}
        >
          Add Role
        </Button>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={rolesData.loading}
        rows={rolesData?.data?.results || []}
        columns={columns}
      />
      {pagination}
    </Box>
  );
}

function RolesForm({ setSubPage, refetch, editData }) {
  const methods = useForm({
    defaultValues: dataToState(editData),
    resolver: zodResolver(
      z.object({
        role_name: validators.stringRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = stateToData(methods.getValues());
    newData.code = generateUID();
    newData.printable_name = generateUID();
    try {
      const response = await (editData
        ? api.patch(`${roles_url}${editData.id}/`, newData)
        : api.post(roles_url, newData));
      if (response?.status === 200 || response?.status === 201) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Text
            methods={methods}
            name="role_name"
            label="Role Name"
            placeholder="Role Name"
            required
          />
          <Box sx={{ padding: "10px" }} />
          <RolesTable methods={methods} />
          <Box sx={{ padding: "10px" }} />
          {editData &&
            !_.isEqual(
              dataToState(editData).permissions,
              methods.watch("permissions")
            ) && (
              <Input.Switch
                methods={methods}
                name="force"
                label="Force Logout"
              />
            )}
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}

function RolesTable({ methods }) {
  const columns = [
    {
      headerName: "Modules",
      field: "name",
      headerAlign: "left",
      valign: "top",
      renderCell: (params) => (
        <Input.CheckBox
          methods={methods}
          name={`permissions.${params.row.module}.${perms.view}`}
          label={params.value}
          onChange={(v) => {
            if (!v) {
              const permsArray = params.row.perms;
              if (_.isArray(permsArray)) {
                permsArray?.forEach((permItem) => {
                  methods.setValue(
                    `permissions.${params.row.module}.${permItem.code}`,
                    false
                  );
                });
              }
            }
          }}
        />
      ),
    },
    {
      headerName: "Permissions",
      field: "perms",
      headerAlign: "left",
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          {params?.value?.map((perm, index) => (
            <Input.CheckBox
              key={index}
              name={`permissions.${params.row.module}.${perm.code}`}
              methods={methods}
              label={perm.name}
              disabled={
                !methods.watch(`permissions.${params.row.module}.${perms.view}`)
              }
            />
          ))}
        </Box>
      ),
    },
  ];

  return (
    <TableComponent
      columns={columns}
      rows={sidebarConfig.filter((sc) => sc.noPerm !== true)}
    />
  );
}

function dataToState(data) {
  const obj = {};
  if (_.isArray(data?.permissions)) {
    data?.permissions?.forEach((d) => {
      const permObj = {};
      d?.perms?.forEach((pm) => {
        permObj[pm] = true;
      });
      obj[d.code] = permObj;
    });
  }
  const newData = { ...data };
  newData.permissions = obj;
  return newData;
}

function stateToData(data) {
  const newData = { ...data };
  newData.permissions = Object.keys(data.permissions)
    .filter((key) => data.permissions[key]?.[perms.view])
    .map((key) => ({
      code: key,
      perms: Object.keys(data.permissions[key]).filter(
        (x) => data.permissions[key][x]
      ),
    }));
  return newData;
}
