import { Box, Button, Divider } from "@mui/material";
import { profileSidebarConfig } from "../config/profileSidebarConfig";
import { useParamsState } from "../hooks/useParamState";
import { user_profile_url } from "../api/urls";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { Loading } from "../components/loading";

export function Profile() {
  const [page] = useParamsState("page", "account");
  const user = useFetch(user_profile_url);
  if (user.loading || !user.data) {
    return <Loading />;
  }

  const Component = profileSidebarConfig[page].component;
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <SideBar />
      <Divider orientation="vertical" />
      <Box sx={{ flex: 1 }}>
        <Component data={user?.data?.[0]} refetch={user.refetch} />
      </Box>
    </Box>
  );
}

function SideBar() {
  const [page] = useParamsState("page", "account");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "260px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      {Object.keys(profileSidebarConfig).map((key) => {
        const data = profileSidebarConfig[key];
        return (
          <Button
            variant={page === key ? "contained" : "ghost"}
            key={key}
            fullWidth
            onClick={() => navigate(`/profile?page=${key}`)}
            sx={{
              justifyContent: "flex-start",
              whiteSpace: "nowrap",
              ...(page === key
                ? {
                    bgcolor: (theme) => `${theme.palette.primary.main}33`,
                    color: "primary.main",
                    "&:hover": {
                      bgcolor: (theme) => `${theme.palette.primary.main}55`,
                      color: "primary.main",
                    },
                  }
                : {}),
            }}
            startIcon={<data.icon />}
          >
            {data.name}
          </Button>
        );
      })}
    </Box>
  );
}
