import {
  Autocomplete,
  Box,
  FormControlLabel,
  Radio as RadioInput,
  RadioGroup,
  TextField,
  InputLabel,
  Switch,
  Checkbox,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Calendar, FileIcon, UploadCloud, X } from "lucide-react";
import moment from "moment";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFieldArray } from "react-hook-form";
import { Field } from "./form";
import { urlToFile } from "../utils/urlToFile";
import { isValidUrl } from "../utils/isValidUrl";
import { OTPInput } from "./otpInput";
import { DashboardImage } from "./dashboardImage";

function Text({
  type = "text",
  methods,
  label,
  name,
  placeholder,
  required,
  info,
  multiline,
  InputProps,
  disabled,
  error: errorProp,
}) {
  const { control } = methods;
  return (
    <Field label={label ? label + (required ? " *" : "") : null} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <TextField
            type={type}
            inputRef={ref}
            value={value ? value : ""}
            onChange={onChange}
            multiline={multiline}
            rows={multiline ? 3 : undefined}
            placeholder={placeholder}
            error={!!error || !!errorProp}
            helperText={error?.message || errorProp}
            size="small"
            disabled={disabled}
            InputProps={InputProps}
            sx={{ minWidth: "150px" }}
          />
        )}
      />
    </Field>
  );
}

function Number({
  methods,
  label,
  required,
  info,
  name,
  placeholder,
  InputProps,
  error: errorProp,
  fillLabelHeight = false,
}) {
  const { control, setValue, getValues } = methods;
  useEffect(() => {
    setValue(name, parseFloat(getValues(name)));
  }, [name, setValue, getValues]);
  return (
    <Field
      label={label ? label + (required ? " *" : "") : null}
      info={info}
      fillLabelHeight={fillLabelHeight}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <TextField
            inputRef={ref}
            type="number"
            value={value?.toString() ? value?.toString() : ""}
            onChange={(e) => onChange(parseFloat(e.target.value))}
            placeholder={placeholder}
            error={!!error || !!errorProp}
            helperText={error?.message || errorProp}
            size="small"
            InputProps={InputProps}
          />
        )}
      />
    </Field>
  );
}

function Date({
  methods,
  label,
  required,
  info,
  name,
  placeholder,
  error: errorProp,
}) {
  const { control } = methods;
  return (
    <Field label={label ? label + (required ? " *" : "") : null} info={info}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <DatePicker
            inputRef={ref}
            placeholder={placeholder}
            slots={{ openPickerIcon: Calendar }}
            format="DD/MM/YYYY"
            slotProps={{
              openPickerIcon: { style: { strokeWidth: "2px" } },
              actionBar: {
                actions: ["today"],
              },
              textField: {
                error: !!error || !!errorProp,
                helperText: error?.message || errorProp,
                size: "small",
              },
            }}
            value={value ? moment(value) : null}
            onChange={(e) => {
              onChange(e.format());
            }}
          />
        )}
      />
    </Field>
  );
}

function Select({
  methods,
  label,
  required,
  info,
  name,
  placeholder,
  multiple,
  options,
  getKey = "printable_name",
  setKey = "id",
  loading,
  onChangeFullData,
  disabled,
  renderOption,
  generateOptionLabel,
  error: errorProp,
  fillLabelHeight,
}) {
  const { control } = methods;
  return (
    <Field
      label={label ? label + (required ? " *" : "") : null}
      info={info}
      fillLabelHeight={fillLabelHeight}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={multiple ? undefined : null}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <Autocomplete
            loading={loading}
            multiple={multiple}
            disabled={disabled}
            value={
              multiple
                ? options?.filter((option) =>
                    value?.find((v) => v === option?.[setKey])
                  ) || []
                : options?.find((option) => option?.[setKey] === value) || null
            }
            onChange={(e, data) => {
              onChange(
                multiple ? data?.map((d) => d?.[setKey]) : data?.[setKey]
              );
              if (onChangeFullData) {
                onChangeFullData(data);
              }
            }}
            getOptionLabel={(option) => {
              return generateOptionLabel
                ? generateOptionLabel(option)
                : option?.[getKey];
            }}
            options={options ? options : []}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={ref}
                placeholder={placeholder}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "14px", height: "20px" },
                }}
                error={!!error || !!errorProp}
                helperText={error?.message || errorProp}
                size="small"
              />
            )}
          />
        )}
      />
    </Field>
  );
}

function Radio({
  methods,
  label,
  required,
  info,
  name,
  multiple,
  options,
  getKey = "printable_name",
  setKey = "id",
  loading,
  error: errorProp,
}) {
  const { control } = methods;
  return (
    <Field label={label ? label + (required ? " *" : "") : null} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <RadioGroup
              ref={ref}
              value={value}
              onChange={(e, data) => onChange(data)}
              name={name}
            >
              {options?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option[setKey]}
                  control={<RadioInput size="small" />}
                  label={
                    <InputLabel
                      sx={{
                        color: "gray.main",
                        justifySelf: "end",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {option[getKey]}
                    </InputLabel>
                  }
                  sx={{ fontSize: "14px" }}
                />
              ))}
            </RadioGroup>
            {(!!error || !!errorProp) && (
              <Box
                className="Mui-error"
                sx={{
                  color: "error.main",
                  marginLeft: "14px",
                  fontSize: "13px",
                }}
              >
                {error?.message || errorProp}
              </Box>
            )}
          </Box>
        )}
      />
    </Field>
  );
}

function File({ methods, label, required, info, name, error: errorProp }) {
  const { getRootProps, getInputProps } = useDropzone();
  const { control, watch, setValue } = methods;

  const watchedValue = watch(name);

  useEffect(() => {
    if (isValidUrl(watchedValue)) {
      const func = async () => {
        const file = await urlToFile(watchedValue);
        setValue(name, file);
      };
      func();
    }
  }, [watchedValue, setValue, name]);

  return (
    <Field label={label ? label + (required ? " *" : "") : null} info={info}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <Box
            ref={ref}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                border: !!error || !!errorProp ? "2px solid" : "1.5px dashed",
                borderColor:
                  !!error || !!errorProp ? "error.main" : "text.primary",
                padding: "20px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                cursor: "pointer",
                color: "gray.main",
              }}
              {...(value?.name
                ? {
                    onClick: () => {
                      onChange("");
                    },
                  }
                : getRootProps())}
            >
              <input
                {...getInputProps()}
                value={value instanceof File ? URL.createObjectURL(value) : ""}
                onChange={(e) => onChange(e.target.files[0])}
              />
              {value?.name ? <FileIcon /> : <UploadCloud />}
              {value?.name ? (
                <Box sx={{ fontSize: "14px" }}>
                  Selected file - {value?.name}{" "}
                  <Box
                    sx={{
                      fontWeight: "600",
                      color: "primary.main",
                      textDecoration: "underline",
                    }}
                  >
                    Click to remove selected file
                  </Box>
                </Box>
              ) : (
                <Box sx={{ fontSize: "14px" }}>
                  Drag & drop or{" "}
                  <Box
                    sx={{
                      display: "inline",
                      fontWeight: "600",
                      color: "primary.main",
                      textDecoration: "underline",
                    }}
                  >
                    Browse
                  </Box>
                </Box>
              )}
            </Box>
            {(!!error || !!errorProp) && (
              <Box
                className="Mui-error"
                sx={{
                  color: "error.main",
                  marginLeft: "14px",
                  fontSize: "13px",
                }}
              >
                {error?.message || errorProp}
              </Box>
            )}
          </Box>
        )}
      />
    </Field>
  );
}

function MultipleFile({
  methods,
  label,
  required,
  info,
  name,
  error: errorProp,
}) {
  const { getRootProps, getInputProps } = useDropzone();
  const { control, watch, setValue } = methods;

  const watchedValue = watch(name);

  const { fields, append, remove } = useFieldArray({
    name: name,
    control: methods.control,
  });

  useEffect(() => {
    if (isValidUrl(watchedValue)) {
      const func = async () => {
        const file = await urlToFile(watchedValue);
        setValue(name, file);
      };
      func();
    }
  }, [watchedValue, setValue, name]);

  return (
    <Field label={label ? label + (required ? " *" : "") : null} info={info}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <Box
            ref={ref}
            sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
          >
            <Box
              sx={{
                border: !!error || !!errorProp ? "2px solid" : "1.5px dashed",
                borderColor:
                  !!error || !!errorProp ? "error.main" : "text.primary",
                padding: "20px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                cursor: "pointer",
                color: "gray.main",
              }}
              {...(value?.name
                ? {
                    onClick: () => {
                      onChange("");
                    },
                  }
                : getRootProps())}
            >
              <input
                {...getInputProps()}
                // value={value instanceof File ? URL.createObjectURL(value) : ""}
                onChange={(e) => append({ file: e.target.files[0] })}
              />
              {value?.name ? <FileIcon /> : <UploadCloud />}
              {value?.name ? (
                <Box sx={{ fontSize: "14px" }}>
                  Selected file - {value?.name}{" "}
                  <Box
                    sx={{
                      fontWeight: "600",
                      color: "primary.main",
                      textDecoration: "underline",
                    }}
                  >
                    Click to remove selected file
                  </Box>
                </Box>
              ) : (
                <Box sx={{ fontSize: "14px" }}>
                  Drag & drop or{" "}
                  <Box
                    sx={{
                      display: "inline",
                      fontWeight: "600",
                      color: "primary.main",
                      textDecoration: "underline",
                    }}
                  >
                    Browse
                  </Box>
                </Box>
              )}
            </Box>
            <Box sx={{ p: "2px" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "fit-content",
              }}
            >
              {fields.map((field, index) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 10px",
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                  key={field.id}
                >
                  <Box>{field.file.name}</Box>
                  <IconButton onClick={() => remove(index)}>
                    <X />
                  </IconButton>
                </Box>
              ))}
            </Box>
            {(!!error || !!errorProp) && (
              <Box
                className="Mui-error"
                sx={{
                  color: "error.main",
                  marginLeft: "14px",
                  fontSize: "13px",
                }}
              >
                {error?.message || errorProp}
              </Box>
            )}
          </Box>
        )}
      />
    </Field>
  );
}

function Image({ methods, label, required, info, name, error: errorProp }) {
  const { getRootProps, getInputProps } = useDropzone();
  const { control, watch, setValue } = methods;

  const watchedValue = watch(name);

  useEffect(() => {
    if (isValidUrl(watchedValue)) {
      const func = async () => {
        const file = await urlToFile(watchedValue);
        setValue(name, file);
      };
      func();
    }
  }, [watchedValue, setValue, name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
        <Box
          ref={ref}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              border: !!error || !!errorProp ? "2px solid" : "1.5px dashed",
              borderColor:
                !!error || !!errorProp ? "error.main" : "text.primary",
              padding: "20px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              cursor: "pointer",
              color: "gray.main",
              height: "150px",
              width: "120px",
            }}
            {...(value?.name
              ? {
                  onClick: () => {
                    onChange("");
                  },
                }
              : getRootProps())}
          >
            <input
              {...getInputProps()}
              value={value instanceof File ? URL.createObjectURL(value) : ""}
              onChange={(e) => onChange(e.target.files[0])}
            />
            {value?.name ? null : <UploadCloud />}
            {value?.name ? (
              <Box sx={{ fontSize: "14px" }}>
                <DashboardImage
                  src={URL.createObjectURL(value)}
                  height="120px"
                  width="80px"
                  noLink
                />
              </Box>
            ) : (
              <Box sx={{ fontSize: "14px" }}>
                Drag & drop or{" "}
                <Box
                  sx={{
                    display: "inline",
                    fontWeight: "600",
                    color: "primary.main",
                    textDecoration: "underline",
                  }}
                >
                  Browse
                </Box>
              </Box>
            )}
          </Box>
          <Box>{label}</Box>
          {(!!error || !!errorProp) && (
            <Box
              className="Mui-error"
              sx={{
                color: "error.main",
                marginLeft: "14px",
                fontSize: "13px",
              }}
            >
              {error?.message || errorProp}
            </Box>
          )}
        </Box>
      )}
    />
  );
}

function OTP({ name, methods }) {
  const { control } = methods;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <OTPInput
            value={value}
            onChange={(v) => {
              onChange(v);
            }}
            numInputs={6}
            renderInput={(props, index) => {
              return (
                <TextField
                  inputProps={props}
                  size="small"
                  sx={{
                    width: "45px",
                    marginLeft: index === 3 ? "15px" : "0px",
                  }}
                />
              );
            }}
            renderSeparator={<Box sx={{ padding: "5px" }} />}
          />
          {!!error && (
            <Box
              className="Mui-error"
              sx={{
                color: "error.main",
                marginLeft: "14px",
                fontSize: "13px",
              }}
            >
              {error?.message}
            </Box>
          )}
        </>
      )}
    />
  );
}

function InputSwitch({
  type = "text",
  methods,
  label,
  name,
  placeholder,
  required,
  info,
  multiline,
  InputProps,
}) {
  const { control } = methods;
  return (
    <Field
      label={label ? label + (required ? " *" : "") : null}
      info={info}
      row
    >
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <Switch
            type={type}
            inputRef={ref}
            checked={value ? value : ""}
            onClick={() => onChange(!value)}
            error={!!error}
            helperText={error?.message}
            InputProps={InputProps}
          />
        )}
      />
    </Field>
  );
}

function InputCheckBox({
  type = "text",
  methods,
  label,
  name,
  placeholder,
  required,
  info,
  multiline,
  InputProps,
  disabled,
}) {
  const { control } = methods;
  return (
    <Field info={info} row>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                type={type}
                inputRef={ref}
                checked={value ? value : ""}
                onClick={() => onChange(!value)}
                error={!!error}
                helperText={error?.message}
                InputProps={InputProps}
                focusRipple={false}
              />
            }
            label={label}
          />
        )}
      />
    </Field>
  );
}

function Tags({
  type = "text",
  methods,
  label,
  name,
  placeholder,
  required,
  info,
  InputProps,
}) {
  const { control } = methods;
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Autocomplete
            multiple
            options={[]}
            defaultValue={[]}
            freeSolo
            value={
              value
                ? value
                    .split(",")
                    .filter((tag) => tag !== "" && tag !== undefined)
                : []
            }
            onChange={(event, newValue) => {
              onChange(
                newValue
                  .filter((tag) => tag !== "" && tag !== undefined)
                  .join(",")
              );
            }}
            renderInput={(params) => (
              <TextField
                type={type}
                placeholder={placeholder}
                error={!!error}
                helperText={error?.message}
                size="small"
                InputProps={InputProps}
                {...params}
              />
            )}
          />
        )}
      />
    </Field>
  );
}

export const Input = {
  Text,
  Date,
  File,
  MultipleFile,
  Image,
  Number,
  Select,
  Radio,
  OTP,
  Switch: InputSwitch,
  Tags,
  CheckBox: InputCheckBox,
};
