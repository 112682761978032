import { Box, Button, Divider, Paper } from "@mui/material";
import {
  address_url,
  country_url,
  facility_type_url,
  state_url,
} from "../../api/urls";
import { Loading } from "../../components/loading";
import { useFetch } from "../../hooks/useFetch";
import { useParamsState } from "../../hooks/useParamState";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../../utils/validators";
import { generateUID } from "../../utils/generateUID";
import { api } from "../../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../../utils/unknownError";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as NoAddress } from "../../images/noAddress.svg";
import { useState } from "react";
import { LabelValue } from "../../components/cardLabelValue";
import { sellerAddressFormatter } from "../../utils/addressFormatter";
import { AreYouSure } from "../../components/areYouSure";

export function AddressSection({ refetch }) {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const [editData, setEditData] = useState();
  const addressData = useFetch(address_url);

  if (addressData.loading || !addressData.data) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <AddressForm
        setSubPage={setSubPage}
        editData={editData}
        refetch={refetch}
      />
    );
  }

  if (addressData?.data?.length === 0) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoAddress />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => setSubPage("add")}>
            Add Address
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        {addressData?.data?.map((d) => (
          <Paper
            elevation={0}
            sx={{ border: "1px solid", borderColor: "divider" }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <LabelValue
                label="Address:"
                value={
                  <Box sx={{ maxWidth: "400px", textAlign: "justify" }}>
                    {sellerAddressFormatter(d)}
                  </Box>
                }
              />
              <Divider />
              <LabelValue label="GSTIN:" value={d.facility_gstin_number} />
              <Divider />
              <LabelValue label="Contact Person:" value={d.contact_name} />
              <Divider />
              <LabelValue label="Contact:" value={d.contact_number} />
              {/* <Divider />
              <LabelValue
                label="Status:"
                value={
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    {tickByStatus(d?.address_request_status)}
                  </Box>
                }
              /> */}
              <Box sx={{ padding: "5px" }} />
              <Box className="equal-columns">
                <AreYouSure
                  message="Are you sure you want delete address ?"
                  onYes={async () => {
                    try {
                      const response = await api.delete(
                        `${address_url}${d.id}/`
                      );
                      if (response?.status === 204) {
                        enqueueSnackbar("Address deleted successfully");
                        refetch();
                      } else {
                        unknownError();
                      }
                    } catch (e) {
                      if (
                        e?.response?.status === 400 &&
                        e?.response?.data?.data
                      ) {
                        enqueueSnackbar(e?.response?.data.data, {
                          variant: "error",
                        });
                      } else {
                        unknownError();
                      }
                    }
                  }}
                >
                  <Button variant="outlined">Delete</Button>
                </AreYouSure>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditData(d);
                    setSubPage("add");
                  }}
                >
                  Edit Address
                </Button>
              </Box>
            </Box>
          </Paper>
        ))}
        <Button
          variant="contained"
          onClick={() => {
            setEditData();
            setSubPage("add");
          }}
        >
          Add Address
        </Button>
      </Box>
    </Box>
  );
}

function AddressForm({ setSubPage, refetch, editData }) {
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        facility_name: validators.stringRequired,
        contact_name: validators.stringRequired,
        contact_number: validators.stringRequired,
        address_line_1: validators.stringRequired,
        address_line_2: validators.stringRequired,
        landmark: validators.stringRequired,
        pincode: validators.stringRequired,
        facility_gstin_number: validators.stringRequired,
        facility_type: validators.numberRequired,
        country: validators.numberRequired,
        state_link: validators.numberRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    try {
      const response = await (editData
        ? api.patch(`${address_url}${editData.id}/`, newData)
        : api.post(address_url, newData));
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  const facilityTypeData = useFetch(facility_type_url);
  const stateData = useFetch(state_url);
  const countryData = useFetch(country_url);
  return (
    <Box sx={{ padding: "20px" }}>
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Text
            methods={methods}
            name="facility_name"
            label="Facility Name"
            placeholder="Facility Name"
            required
          />
          <Input.Select
            methods={methods}
            name="facility_type"
            label="Facility Type"
            placeholder="Facility Type"
            options={facilityTypeData.data}
            loading={facilityTypeData.loading}
            required
          />
          <Input.Text
            methods={methods}
            name="contact_number"
            label="Phone Number"
            placeholder="Phone Number"
            required
          />
          <Input.Text
            methods={methods}
            name="contact_name"
            label="Contact Person"
            placeholder="Contact Person"
            required
          />
          <Input.Text
            methods={methods}
            name="address_line_1"
            label="Address Line 1"
            placeholder="Address Line 1"
            required
          />
          <Input.Text
            methods={methods}
            name="address_line_2"
            label="Address Line 2"
            placeholder="Address Line 2"
            required
          />
          <Input.Text
            methods={methods}
            name="landmark"
            label="Landmark"
            placeholder="Landmark"
            required
          />
          <Input.Select
            methods={methods}
            name="country"
            label="Country"
            placeholder="Country"
            options={countryData.data}
            loading={countryData.loading}
            required
          />
          <Input.Select
            methods={methods}
            name="state_link"
            label="State"
            placeholder="State"
            options={stateData.data}
            loading={stateData.loading}
            required
          />
          <Input.Text
            methods={methods}
            name="city_village"
            label="City"
            placeholder="City"
            required
          />
          <Input.Text
            methods={methods}
            name="pincode"
            label="Pincode"
            placeholder="Pincode"
            required
          />
          <Input.Text
            methods={methods}
            name="facility_gstin_number"
            label="GSTIN"
            placeholder="GSTIN"
            required
          />
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}
