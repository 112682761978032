import { Box } from "@mui/material";
import { StatusTick } from "../components/statusTick";
import { TextChip } from "../components/textChip";

export function tickByStatus(status) {
  if (status === "IN PROCESS") {
    return (
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
        In Process <StatusTick />
      </Box>
    );
  }
  if (status === "APPROVED") {
    return (
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
        Approved <StatusTick status={true} />
      </Box>
    );
  }
  return (
    <Box sx={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
      Rejected <StatusTick status={false} />
    </Box>
  );
}

export function chipByStatus(status) {
  function capitalize(text) {
    return text.toLowerCase().replace(/\b\w/g, function (m) {
      return m.toUpperCase();
    });
  }

  if (status === "REJECTED" || status === "QC FAILED") {
    return <TextChip text={capitalize(status)} color="error" />;
  }
  if (status === "APPROVED" || status === "QC PASSED") {
    return <TextChip text={capitalize(status)} color="success" />;
  }
  return <TextChip text={capitalize(status)} color="#FD7F01" />;
}
