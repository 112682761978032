import { ReactComponent as RightTick } from "../images/rightTick.svg";
import { ReactComponent as WrongTick } from "../images/wrongTick.svg";
import { ReactComponent as PendingTick } from "../images/pendingTick.svg";

export function StatusTick({ status }) {
  if (status === true) {
    return <RightTick />;
  }
  if (status === false) {
    return <WrongTick />;
  }
  return <PendingTick />;
}
