import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog, IconButton } from "@mui/material";
import { Box, Button } from "@mui/material";
import { Form } from "../components/form";
import { X } from "lucide-react";

export function useFilter({ schema, render, width }) {
  const [obj, setObj] = useState({});
  const [open, setOpen] = useState(false);
  const methods = useForm({ resolver: zodResolver(schema) });
  return {
    obj: obj,
    FilterButton: (
      <Box
        onClick={() => setOpen(true)}
        sx={{
          width: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "10px",
          cursor: "pointer",
          bgcolor: "background.default",
          border: "1px solid",
          borderColor: "divider",
          padding: "5px 15px",
          borderRadius: "4px",
          fontSize: "13px",
          color: "gray.main",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Filter
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "primary.main",
            color: "primary.contrastText",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
          }}
        >
          {getFilterCount(obj)}
        </Box>
      </Box>
    ),
    FilterModal: (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            padding: "40px",
            position: "relative",
            maxWidth: "100%",
            width: width,
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <FilterModal
            setObj={setObj}
            render={render}
            methods={methods}
            setOpen={setOpen}
          />
        </Box>
      </Dialog>
    ),
  };
}

function FilterModal({ setObj, render, methods, setOpen }) {
  const Render = render;
  return (
    <Form
      methods={methods}
      onSubmit={() => {
        setObj(filterObj(methods.getValues()));
        setOpen(false);
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Render methods={methods} />
        <Box sx={{ alignSelf: "end", display: "flex", gap: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              methods.reset();
              setObj(filterObj(methods.getValues()));
              setOpen(false);
            }}
          >
            Clear
          </Button>
          <Button variant="contained" type="submit">
            Apply
          </Button>
        </Box>
      </Box>
    </Form>
  );
}

function getFilterCount(obj) {
  let count = 0;
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      count += obj[key].length;
    } else {
      count++;
    }
  });
  return count;
}

function filterObj(paramObj) {
  const obj = { ...paramObj };
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete obj[key];
    }
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((x) => x);
      if (obj[key].length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
}
