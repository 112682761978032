import { Box } from "@mui/material";
import { ReactComponent as TrackCheck } from "../images/trackCheck.svg";

export function TrackUI({ data, completed }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflow: "auto",
        maxHeight: "300px",
      }}
    >
      {data.map((d, index) => {
        return (
          <Box
            key={index}
            sx={{ display: "flex", gap: "10px", position: "relative" }}
          >
            {index < data.length - 1 && (
              <Box
                sx={{
                  position: "absolute",
                  width: "5px",
                  bgcolor: index < completed ? "primary.main" : "gray.main",
                  height: "60px",
                  top: "5px",
                  left: "9px",
                  zIndex: "1",
                }}
              />
            )}
            <Box
              sx={{
                color: index < completed ? "primary.main" : "gray.main",
                zIndex: 2,
              }}
            >
              <TrackCheck />
            </Box>
            <Box sx={{ marginTop: "-3px" }}>
              <Box sx={{ fontWeight: "500" }}>{d.name}</Box>
              <Box sx={{ color: "gray.main" }}>{d.date}</Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
