import { useEffect, useState } from "react";
import { useParamsState } from "../hooks/useParamState";
import { country_url, roles_url, sub_user_list_url } from "../api/urls";
import { Loading } from "../components/loading";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Actions } from "../components/actions";
import { Eye, Pencil, Trash2, X } from "lucide-react";
import { TextChip } from "../components/textChip";
import { TableComponent } from "../components/table";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validators } from "../utils/validators";
import { generateUID } from "../utils/generateUID";
import { api } from "../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../utils/unknownError";
import { FieldSet, Form } from "../components/form";
import { Input } from "../components/input";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { ReactComponent as NoCoupon } from "../images/noCoupon.svg";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { View } from "../components/view";
import { MobileNumberDropdown, useFetchPublic } from "./root";
import { useFetch } from "../hooks/useFetch";

export function Users() {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const [editData, setEditData] = useState();
  const { data: usersData, pagination } =
    useFetchWithPagination(sub_user_list_url);

  if (usersData.loading || !usersData.data) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <UserForm
        setSubPage={setSubPage}
        editData={editData}
        refetch={usersData.refetch}
      />
    );
  }

  if (usersData?.data?.results?.length === 0) {
    return (
      <Box
        sx={{
          padding: "30px",
          bgcolor: "background.paper",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoCoupon />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => setSubPage("add")}>
            Add User
          </Button>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "User ID",
      field: "id",
    },
    {
      headerName: "Name",
      renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`,
    },
    {
      headerName: "Mobile Number",
      field: "mobile_number",
    },
    // {
    //   headerName: "Email ID",
    //   field: "email_id",
    // },
    {
      headerName: "Temporary Password",
      field: "tmp_hex",
    },
    {
      headerName: "Status",
      renderCell: () => <TextChip text="Online Todo" color="#00B087" />,
    },
    {
      headerName: "Actions",
      renderCell: (params) => (
        <Actions
          options={[
            {
              icon: Pencil,
              name: "Edit",
              onClick: () => {
                setEditData(params.row);
                setSubPage("add");
              },
            },
            {
              name: "View",
              render: () => <UserView data={params.row} />,
            },
            {
              icon: Trash2,
              name: "Delete",
              deleteUrl: sub_user_list_url,
              deleteId: params.row.id,
              refetch: usersData.refetch,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            setEditData();
            setSubPage("add");
          }}
          sx={{ px: "20px" }}
        >
          Add User
        </Button>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={usersData.loading}
        rows={usersData?.data?.results || []}
        columns={columns}
      />
      {pagination}
    </Box>
  );
}

function userDataToState(data) {
  if (data) {
    const newData = { ...data };
    newData.country = data.mobile_number.slice(0, 2);
    newData.mobile_number = data.mobile_number.slice(2);
    return newData;
  }
}

function UserForm({ setSubPage, refetch, editData }) {
  const methods = useForm({
    defaultValues: userDataToState(editData),
    resolver: zodResolver(
      z
        .object({
          firstname: validators.stringRequired,
          middlename: validators.stringRequired,
          lastname: validators.stringRequired,
          email_id: validators.emailRequired,
          mobile_number: validators.stringRequired,
          roles: validators.numberRequired,
        })
        .superRefine((values, ctx) => {
          if (countryData?.data) {
            const regex = countryData.data.find(
              (c) => c.country_mobile_code === values.country
            )?.country_regex;
            if (
              regex &&
              !RegExp(regex).test(`${values.country}${values.mobile_number}`)
            ) {
              ctx.addIssue({
                message: "Invalid mobile no",
                path: ["mobile_number"],
              });
            }
          }
        })
    ),
    shouldFocusError: true,
  });

  const rolesData = useFetch(roles_url);
  const countryData = useFetchPublic(country_url);

  useEffect(() => {
    if (countryData?.data && !methods.watch("country")) {
      const india = countryData.data.find((c) => c.country_mobile_code === 91);
      methods.setValue("country", india.country_mobile_code);
      methods.setValue("alpha_two_code", india.alpha_two_code);
    }
  }, [countryData.data, methods]);

  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.code = generateUID();
    newData.printable_name = generateUID();
    newData.mobile_number = newData.country + newData.mobile_number;
    try {
      const response = await (editData
        ? api.patch(`${sub_user_list_url}${editData.id}/`, newData)
        : api.post(sub_user_list_url, newData));
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Text
            methods={methods}
            name="firstname"
            label="First Name"
            placeholder="First Name"
            required
          />
          <Input.Text
            methods={methods}
            name="middlename"
            label="Middle Name"
            placeholder="Middle Name"
            required
          />
          <Input.Text
            methods={methods}
            name="lastname"
            label="Last Name"
            placeholder="Last Name"
            required
          />
          <Input.Text
            methods={methods}
            name="email_id"
            label="Email ID"
            placeholder="Email ID"
            required
          />
          <Input.Text
            methods={methods}
            label="Mobile number"
            placeholder="Mobile number"
            name="mobile_number"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Controller
                    control={methods.control}
                    name="country"
                    render={({ field }) => (
                      <MobileNumberDropdown
                        countryData={countryData}
                        value={field.value}
                        onChange={(v) => {
                          field.onChange(v.country_mobile_code);
                          methods.setValue("alpha_two_code", v.alpha_two_code);
                        }}
                      />
                    )}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Input.Select
            methods={methods}
            name="roles"
            label="Role"
            placeholder="Role"
            options={rolesData.data}
            loading={rolesData.loading}
            getKey="role_name"
            required
          />
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}

function UserView({ data: d }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <IconButton sx={{ color: "text.primary" }}>
          <Eye style={{ height: "15px", width: "15px" }} />
        </IconButton>
      </Box>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Typography fontSize={20} fontWeight="500">
            {d.firstname} {d.lastname}
          </Typography>
          <Box sx={{ p: "10px" }} />
          <View
            data={[
              {
                label: "Email ID",
                value: d.email_id,
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
}
