import { Box, Typography } from "@mui/material";
import { Loading } from "../components/loading";
import { policy_url } from "../api/urls";
import { useFetchPublic } from "./root";

export function PrivacyPolicy() {
  const { data: policy, loading } = useFetchPublic(`/in${policy_url}`);

  if (loading || !policy) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </Box>
    );
  }

  const data = policy?.[0]?.description;

  return (
    <Box
      sx={{
        padding: { xs: "15px", sm: "30px", maxWidth: "1000px", margin: "auto" },
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
        Privacy Policy
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Box>
  );
}
