import { icons } from "lucide-react";
import { Dashboard } from "../pages/dashboard";
import { Profile } from "../pages/profile";
import { Annexure, OrderList } from "../pages/orderList";
import { Coupon } from "../pages/coupon";
import { Reels } from "../pages/reels";
import { Inventory, NewProduct } from "../pages/inventory";
import { PaymentDetail, Payments } from "../pages/payments";
import { Users } from "../pages/users";
import { Roles } from "../pages/roles";
import { FAQ, Tickets } from "../pages/helpCenter";
import { modules, perms } from "../api/codes";

export const sidebarConfig = [
  {
    name: "Dashboard",
    icon: icons.LayoutDashboard,
    path: "/dashboard",
    component: <Dashboard />,
    onBoarding: true,
    noPerm: true,
  },
  {
    name: "Orders",
    icon: icons.Package,
    path: "/orders",
    component: <OrderList />,
    children: [
      {
        path: "/orders/:id",
        component: <Annexure />,
        display: false,
        module: modules.Orders,
        perm: perms.edit,
      },
    ],
    module: modules.Orders,
    perm: perms.view,
    perms: [{ name: "Deny Order", code: perms.denyOrder }],
  },
  {
    name: "Inventory",
    icon: icons.Boxes,
    path: "/inventory",
    component: <Inventory key="local" type="local" />,
    children: [
      {
        path: "/inventory/new",
        component: <NewProduct key="local" type="local" />,
        module: modules.Inventory,
        perm: perms.add,
        display: false,
      },
      {
        path: "/inventory/edit/:id",
        component: <NewProduct key="local" type="local" edit />,

        module: modules.Inventory,
        perm: perms.edit,
        display: false,
      },
    ],
    module: modules.Inventory,
    perm: perms.view,
    perms: [
      { name: "Add", code: perms.add },
      { name: "Edit", code: perms.edit },
      { name: "Delete", code: perms.delete },
    ],
  },
  {
    name: "Global Store",
    icon: icons.Earth,
    path: "/global_store",
    component: <Inventory key="global" type="global" />,
    children: [
      {
        path: "/global_store/new",
        component: <NewProduct key="global" type="global" />,
        display: false,
        module: modules.GlobalStore,
        perm: perms.add,
      },
      {
        path: "/global_store/edit/:id",
        component: <NewProduct key="global" type="global" edit />,
        module: modules.GlobalStore,
        perm: perms.edit,
        display: false,
      },
    ],
    module: modules.GlobalStore,
    perm: perms.view,
    perms: [
      { name: "Add", code: perms.add },
      { name: "Edit", code: perms.edit },
      { name: "Delete", code: perms.delete },
    ],
  },
  {
    name: "B2B Store",
    icon: icons.Earth,
    path: "/b2b_store",
    component: <Inventory key="b2b" type="b2b" />,
    children: [
      {
        path: "/b2b_store/new",
        component: <NewProduct key="b2b" type="b2b" />,
        display: false,
        module: modules.B2BStore,
        perm: perms.add,
      },
      {
        path: "/b2b_store/edit/:id",
        component: <NewProduct key="b2b" type="b2b" edit />,
        module: modules.B2BStore,
        perm: perms.edit,
        display: false,
      },
    ],
    module: modules.B2BStore,
    perm: perms.view,
    perms: [
      { name: "Add", code: perms.add },
      { name: "Edit", code: perms.edit },
      { name: "Delete", code: perms.delete },
    ],
  },
  {
    name: "Coupons",
    icon: icons.Ticket,
    path: "/coupons",
    component: <Coupon />,
    module: modules.Coupon,
    perm: perms.view,
    perms: [
      { name: "Add", code: perms.add },
      { name: "Edit", code: perms.edit },
      { name: "Delete", code: perms.delete },
    ],
  },
  {
    name: "Reels",
    icon: icons.MonitorPlay,
    path: "/reels",
    component: <Reels />,
    module: modules.Reels,
    perm: perms.view,
    perms: [
      { name: "Add", code: perms.add },
      { name: "Edit", code: perms.edit },
      { name: "Delete", code: perms.delete },
    ],
  },
  {
    name: "Payment",
    icon: icons.IndianRupee,
    path: "/payment",
    component: <Payments />,
    disabled: true,
    children: [
      {
        path: "/payment/:id",
        component: <PaymentDetail />,
        display: false,
        module: modules.Payments,
        perm: perms.view,
      },
    ],
    module: modules.Payments,
    perm: perms.view,
    perms: [{ name: "Download Reports", code: perms.downloadReports }],
  },
  {
    name: "Users",
    icon: icons.UserRoundCog,
    path: "/users",
    component: <Users />,
    module: modules.Users,
    perm: perms.view,
    noPerm: true,
  },
  {
    name: "Roles",
    icon: icons.Users,
    path: "/roles",
    component: <Roles />,
    module: modules.Roles,
    perm: perms.view,
    noPerm: true,
  },
  {
    name: "Profile",
    icon: icons.User,
    path: "/profile",
    onBoarding: true,
    component: <Profile />,
    noPerm: true,
  },
  {
    name: "Help Center",
    icon: icons.Headset,
    path: "/help_center",
    onBoarding: true,
    component: <FAQ />,
    module: modules.HelpCenter,
    perm: perms.view,
    perms: [{ name: "Raise", code: perms.add }],
    children: [
      {
        path: "/help_center/tickets",
        component: <Tickets />,
        display: false,
        module: modules.HelpCenter,
        perm: perms.view,
      },
    ],
  },
];
