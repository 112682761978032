import { Breadcrumbs, Typography } from "@mui/material";
import { ChevronRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

export function Crumbs() {
  const location = useLocation();
  const path = location.pathname;
  const breadcrumbs = path.slice(1).split("/");
  return (
    <Breadcrumbs
      separator={
        <ChevronRight
          style={{
            height: "15px",
            width: "15px",
            marginRight: "-5px",
            marginLeft: "-5px",
            strokeWidth: "1.5px",
          }}
        />
      }
      sx={{ color: "text.primary", marginY: "-15px", marginLeft: "10px" }}
    >
      {breadcrumbs.map((item, index) => (
        <Link key={index} to={"/" + breadcrumbs.slice(0, index + 1).join("/")}>
          <Typography
            fontWeight="500"
            fontSize={15}
            color={breadcrumbs.length === index + 1 ? "primary.main" : ""}
          >
            {item.slice(0, 1).toUpperCase() + item.slice(1).replace("_", " ")}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
}
