import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ReactComponent as LoadingIcon } from "../images/loading.svg";

export function TableComponent({ columns, rows, loading }) {
  if (loading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingIcon height="70px" width="70px" />
      </Box>
    );
  }
  return (
    <TableContainer
      sx={{
        borderRadius: "5px",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
      >
        <TableHead sx={{}}>
          <TableRow
            sx={{
              "& th, & td": {
                borderColor: "divider",
              },
            }}
          >
            {columns.map((c, index) => (
              <TableCell
                align={c.headerAlign ? c.headerAlign : "center"}
                key={index}
                sx={{
                  whiteSpace: c.headerWrap ? "normal" : "nowrap",
                  fontSize: "13px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  color: "gray.main",
                }}
              >
                {c.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "& th, & td": {
                  borderColor: "divider",
                },
              }}
            >
              {columns.map((c, cindex) => (
                <TableCell
                  key={cindex}
                  align={c.align ? c.align : "center"}
                  valign={c.valign ? c.valign : "center"}
                  sx={{
                    whiteSpace: c.wrap ? "normal" : "nowrap",
                    fontSize: "13px",
                  }}
                >
                  {c.renderCell
                    ? c.renderCell({
                        row: row,
                        value: row?.[c?.field],
                        index: index,
                      })
                    : row?.[c?.field]
                    ? row?.[c?.field]
                    : "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
