export const urlToFile = async (url) => {
  if (!url) return "";
  if (typeof url === "string" || url instanceof String) {
    const response = await fetch(url, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });
    const contentType = response?.headers.get("content-type");
    const blob = await response?.blob();
    const filename = url.split("/").pop();
    return new File([blob], filename, { contentType });
  }
  return "";
};
