import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import { user_profile_url } from "../../api/urls";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../../utils/validators";
import { api } from "../../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../../utils/unknownError";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { X } from "lucide-react";
import { DashboardImage } from "../../components/dashboardImage";
import { useDispatch } from "react-redux";
import { jsonToFormData } from "../../utils/jsonToFromData";
import { setProfileImage } from "../../redux/userStore";

export function SignatureSection({ data, refetch }) {
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {data?.digital_signature ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DashboardImage
            src={data.digital_signature}
            width="200px"
            height="200px"
          />
          <UpdateSignature data={data} refetch={refetch} />
        </Box>
      ) : (
        <Box>
          <UpdateSignature
            data={data}
            triggerText="Add Digital Signature"
            refetch={refetch}
          />
        </Box>
      )}
      <Divider />
      <List dense sx={{ listStyleType: "disc", marginLeft: "15px" }}>
        {[
          "Upload an image of size (200 X 200) pixels",
          "Image size should not exceed 50Kb",
          "Only upload jpeg, jpg or png format",
          "The Signature should be clear on a white background",
        ].map((text, index) => (
          <ListItem sx={{ display: "list-item" }}>
            <span key={index} style={{ position: "relative", left: "-10px" }}>
              {text}
            </span>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function UpdateSignature({ data, refetch, triggerText = "Update" }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: zodResolver(
      z.object({
        digital_signature: validators.imageRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(
        `${user_profile_url}${data.id}/`,
        jsonToFormData(newData)
      );
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        if (response?.data?.company_image) {
          dispatch(setProfileImage(response?.data.company_image));
        }
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>{triggerText}</Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Form methods={methods} onSubmit={onSubmit}>
            <FieldSet>
              <Input.File
                methods={methods}
                name="digital_signature"
                label="Profile picture"
                required
              />
              <Box sx={{ p: "5px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </FieldSet>
          </Form>
        </Box>
      </Dialog>
    </>
  );
}
