import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useFetch } from "./useFetch";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useParamsState } from "./useParamState";

export function useFetchWithPagination(url, options = { perPage: 10 }) {
  const { perPage } = options;
  const [page, setPage] = useParamsState("current_page", 1, "number");
  const [pageCount, setPageCount] = useState(1);
  const data = useFetch(
    url +
      (url.includes("?") ? "&" : "?") +
      `limit=${perPage}&offset=${perPage * (page - 1)}`
  );
  useEffect(() => {
    if (data?.data?.count) {
      setPageCount(Math.ceil(data?.data?.count / perPage) || 1);
    }
  }, [data, perPage]);
  const prevPage = () => {
    setPage(page === 1 ? page : page - 1);
  };
  const nextPage = () => {
    setPage(page === pageCount ? page : page + 1);
  };
  const PageButton = ({ index }) => {
    return (
      <Button
        variant="contained"
        size="small"
        sx={
          index !== page
            ? {
                bgcolor: "background.default",
                color: "text.primary",
                minWidth: "30px",
                "&:hover": {
                  bgcolor: "gray.main",
                },
              }
            : {
                minWidth: "30px",
              }
        }
        onClick={() => setPage(index)}
      >
        {index}
      </Button>
    );
  };

  if (page > pageCount || pageCount === 1) {
    return {
      data: data,
      pagination: null,
    };
  }
  return {
    data: data,
    pagination: (
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ fontSize: "14px", color: "gray.main" }}>
          Showing data {perPage * (page - 1) + 1} to{" "}
          {perPage * (page - 1) + perPage} of {data?.data?.count} entries
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {page - 1 > 1 && (
            <Button
              variant="contained"
              size="small"
              sx={{
                minHeight: "35px",
                bgcolor: "background.default",
                color: "text.primary",
                minWidth: "30px",
                "&:hover": {
                  bgcolor: "gray.main",
                },
              }}
              onClick={prevPage}
            >
              <ChevronLeft />
            </Button>
          )}
          <PageButton index={1} />
          {page - 2 > 1 && "..."}
          {page - 1 > 1 && <PageButton index={page - 1} />}
          {page !== 1 && page !== pageCount && <PageButton index={page} />}
          {page + 1 < pageCount && <PageButton index={page + 1} />}
          {page + 2 < pageCount && "..."}
          {pageCount > 1 && <PageButton index={pageCount} />}
          {page + 1 < pageCount && (
            <Button
              variant="contained"
              size="small"
              sx={{
                minHeight: "35px",
                bgcolor: "background.default",
                color: "text.primary",
                minWidth: "30px",
                "&:hover": {
                  bgcolor: "gray.main",
                },
              }}
              onClick={nextPage}
            >
              <ChevronRight />
            </Button>
          )}
        </Box>
      </Box>
    ),
  };
}
