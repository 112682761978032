export const FilledIcons = {
  PackageTime: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#514EFF"
        d="M18.75 1.302H4.167a2.865 2.865 0 0 0-2.865 2.865V18.75a2.865 2.865 0 0 0 2.865 2.865H18.75a2.865 2.865 0 0 0 2.865-2.865V4.167a2.865 2.865 0 0 0-2.865-2.865Z"
      />
      <path
        fill="#fff"
        d="M9.375 19.531H5.208a.781.781 0 1 1 0-1.562h4.167a.781.781 0 1 1 0 1.562ZM7.292 16.406H5.208a.781.781 0 1 1 0-1.562h2.084a.781.781 0 1 1 0 1.562Z"
      />
      <path
        fill="#1612D1"
        d="M15.365 1.302v7.031a.776.776 0 0 1-1.032.74l-2.875-.958-2.875.958a.782.782 0 0 1-.708-.104.799.799 0 0 1-.323-.636v-7.03h7.813Z"
      />
      <path
        fill="#FD7F01"
        d="M18.75 23.698a4.948 4.948 0 1 0 0-9.896 4.948 4.948 0 0 0 0 9.896Z"
      />
      <path
        fill="#fff"
        d="M19.792 19.531H18.75a.781.781 0 0 1-.781-.781v-2.083a.782.782 0 0 1 1.562 0v1.302h.26a.781.781 0 1 1 0 1.562Z"
      />
    </svg>
  ),
  PackageRight: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#514EFF"
        d="M18.75 1.302H4.167a2.865 2.865 0 0 0-2.865 2.865V18.75a2.865 2.865 0 0 0 2.865 2.865H18.75a2.865 2.865 0 0 0 2.865-2.865V4.167a2.865 2.865 0 0 0-2.865-2.865Z"
      />
      <path
        fill="#fff"
        d="M9.375 19.531H5.208a.781.781 0 1 1 0-1.562h4.167a.781.781 0 1 1 0 1.562ZM7.292 16.406H5.208a.781.781 0 1 1 0-1.562h2.084a.781.781 0 1 1 0 1.562Z"
      />
      <path
        fill="#1612D1"
        d="M15.365 1.302v7.031a.774.774 0 0 1-.782.782.7.7 0 0 1-.25-.042l-2.875-.958-2.875.958a.782.782 0 0 1-.708-.104.799.799 0 0 1-.323-.636v-7.03h7.813Z"
      />
      <path
        fill="#FD7F01"
        d="M18.75 23.698a4.948 4.948 0 1 0 0-9.896 4.948 4.948 0 0 0 0 9.896Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m17 19 1 1 2.5-2.5"
      />
    </svg>
  ),
  PackageWrong: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#514EFF"
        d="M18.75 1.563H4.167a2.604 2.604 0 0 0-2.604 2.604v15.625a2.604 2.604 0 0 0 2.604 2.604H18.75a2.604 2.604 0 0 0 2.604-2.604V4.167a2.604 2.604 0 0 0-2.604-2.604Z"
      />
      <path
        fill="#FD7F01"
        d="M17.708 23.438a5.73 5.73 0 1 0 0-11.459 5.73 5.73 0 0 0 0 11.459Z"
      />
      <path
        fill="#F1F4F7"
        d="M8.333 17.188H4.688a.52.52 0 0 1 0-1.042h3.645a.52.52 0 0 1 0 1.041ZM8.333 19.27H4.688a.52.52 0 1 1 0-1.04h3.645a.52.52 0 1 1 0 1.04ZM16.235 19.702a.52.52 0 0 1-.368-.889l2.947-2.946a.52.52 0 0 1 .736.737l-2.947 2.945a.52.52 0 0 1-.368.153Z"
      />
      <path
        fill="#F1F4F7"
        d="M19.182 19.702a.521.521 0 0 1-.368-.153l-2.947-2.945a.52.52 0 0 1 .736-.737l2.947 2.946a.521.521 0 0 1-.368.89Z"
      />
      <path
        fill="#1612D1"
        d="M15.625 1.563v7.052a1.041 1.041 0 0 1-1.87.63l-.057-.078c-.245-.323-.406-.521-.578-.521-.224 0-.401.213-.64.536a1.367 1.367 0 0 1-1.022.714 1.54 1.54 0 0 1-1.073-.672c-.26-.281-.53-.578-.802-.578-.078 0-.161.026-.364.354a1.042 1.042 0 0 1-1.927-.547v-6.89h8.333Z"
      />
    </svg>
  ),
  PackageAdd: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#514EFF"
        d="M18.75 1.302H4.167a2.865 2.865 0 0 0-2.865 2.865V18.75a2.865 2.865 0 0 0 2.865 2.865H18.75a2.865 2.865 0 0 0 2.865-2.865V4.167a2.865 2.865 0 0 0-2.865-2.865Z"
      />
      <path
        fill="#fff"
        d="M9.375 19.531H5.208a.781.781 0 1 1 0-1.562h4.167a.781.781 0 1 1 0 1.562ZM7.292 16.406H5.208a.781.781 0 1 1 0-1.562h2.084a.781.781 0 1 1 0 1.562Z"
      />
      <path
        fill="#1612D1"
        d="M15.365 1.302v7.031a.776.776 0 0 1-1.032.74l-2.875-.958-2.875.958a.782.782 0 0 1-.708-.104.799.799 0 0 1-.323-.636v-7.03h7.813Z"
      />
      <path
        fill="#FD7F01"
        d="M18.75 23.698a4.948 4.948 0 1 0 0-9.896 4.948 4.948 0 0 0 0 9.896Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.083 19h5.834M19 16.083v5.834"
      />
    </svg>
  ),
  PackageDown: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={25}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#514EFF"
          d="M19.25 1.302H4.667a2.865 2.865 0 0 0-2.865 2.865V18.75a2.865 2.865 0 0 0 2.865 2.865H19.25a2.865 2.865 0 0 0 2.865-2.865V4.167a2.865 2.865 0 0 0-2.865-2.865Z"
        />
        <path
          fill="#fff"
          d="M9.875 19.531H5.708a.781.781 0 1 1 0-1.562h4.167a.781.781 0 1 1 0 1.562ZM7.792 16.406H5.708a.781.781 0 1 1 0-1.562h2.084a.781.781 0 1 1 0 1.562Z"
        />
        <path
          fill="#1612D1"
          d="M15.865 1.302v7.031a.776.776 0 0 1-1.032.74l-2.875-.958-2.875.958a.782.782 0 0 1-.708-.104.799.799 0 0 1-.323-.636v-7.03h7.813Z"
        />
        <path
          fill="#FD7F01"
          d="M19.25 23.698a4.948 4.948 0 1 0 0-9.896 4.948 4.948 0 0 0 0 9.896Z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 17v4M21.5 19l-2 2-2-2"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h25v25H.5z" />
        </clipPath>
      </defs>
    </svg>
  ),
  PackageShield: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#514EFF"
        d="M19.25 1.302H4.667a2.865 2.865 0 0 0-2.865 2.865V18.75a2.865 2.865 0 0 0 2.865 2.865H19.25a2.865 2.865 0 0 0 2.865-2.865V4.167a2.865 2.865 0 0 0-2.865-2.865Z"
      />
      <path
        fill="#fff"
        d="M9.875 19.531H5.708a.781.781 0 1 1 0-1.562h4.167a.781.781 0 1 1 0 1.562ZM7.792 16.406H5.708a.781.781 0 1 1 0-1.562h2.084a.781.781 0 1 1 0 1.562Z"
      />
      <path
        fill="#1612D1"
        d="M15.865 1.302v7.031a.776.776 0 0 1-1.032.74l-2.875-.958-2.875.958a.782.782 0 0 1-.708-.104.799.799 0 0 1-.323-.636v-7.03h7.813Z"
      />
      <path
        fill="#FD7F01"
        d="M19.25 23.698a4.948 4.948 0 1 0 0-9.896 4.948 4.948 0 0 0 0 9.896Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M19.5 22.333S22.167 21 22.167 19v-2.333l-2.667-1-2.667 1V19c0 2 2.667 3.333 2.667 3.333Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="m18.5 19 .667.667 1.333-1.334"
      />
    </svg>
  ),
  Truck: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#1612D1"
        d="M23.333 11.753 20.73 8.281a1.834 1.834 0 0 0-1.458-.729h-4.688a.781.781 0 0 0-.78.781V18.75a.781.781 0 0 0 .78.781h7.292a1.825 1.825 0 0 0 1.823-1.823v-4.861c0-.394-.128-.778-.365-1.094Z"
      />
      <path
        fill="#514EFF"
        d="M13.542 3.385H3.125a1.824 1.824 0 0 0-1.823 1.823v12.5a1.824 1.824 0 0 0 1.823 1.823h12.24V5.208a1.824 1.824 0 0 0-1.823-1.823Z"
      />
      <path
        fill="#fff"
        d="M20.313 14.323h-3.125a.781.781 0 0 1-.782-.781v-3.125a.782.782 0 0 1 1.563 0v2.343h2.343a.781.781 0 1 1 0 1.563Z"
      />
      <path
        fill="#FD7F01"
        d="M18.75 21.615a2.865 2.865 0 1 0 0-5.73 2.865 2.865 0 0 0 0 5.73ZM6.25 21.615a2.865 2.865 0 1 0 0-5.73 2.865 2.865 0 0 0 0 5.73Z"
      />
    </svg>
  ),
  Flag: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46}
      height={36}
      fill="none"
      {...props}
    >
      <mask
        id="a"
        width={36}
        height={36}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <circle cx={18} cy={18} r={18} fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#514EFF"
          d="m21.222 5.165-1.876-.503L10.8 36.555l1.876.502 8.546-31.892Z"
        />
      </g>
      <path
        fill="#514EFF"
        d="m17.552 22.613 4.293-16.025s2.464-2.942 6.903-.747c4.439 2.195 6.209 4.344 7.376 4.992L31.73 27.227s-6.189-8.17-14.18-4.614Z"
      />
      <path
        fill="#514EFF"
        d="M36.246 12.877s6.003 2.614 9.57.553l-4.392 16.394s-5.827 2.986-14.286-2.967l.431-1.607 4.801 3.344 3.876-15.717Z"
      />
    </svg>
  ),
  OnBoardingCheck: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <circle
        cx={13}
        cy={13}
        r={12}
        fill="#514EFF"
        stroke="#514EFF"
        strokeLinecap="round"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m18 9-6.875 7L8 12.818"
      />
    </svg>
  ),
  OnBoardingUnCheck: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <circle
        cx={13}
        cy={13}
        r={12}
        stroke="#5F616E"
        strokeDasharray="4 4"
        strokeLinecap="round"
      />
    </svg>
  ),
  Stats: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#1612D1"
        d="M5.208 11.198a.781.781 0 0 1-.312-1.5l7.292-3.124 9.365-4.167a.782.782 0 1 1 .636 1.43l-9.376 4.166-7.3 3.125a.79.79 0 0 1-.305.07Z"
      />
      <path
        fill="#1612D1"
        d="M20.833 7.031a.78.78 0 0 1-.742-1.028l.796-2.384-2.384-.795a.78.78 0 0 1 .494-1.481l3.125 1.041a.78.78 0 0 1 .495.988l-1.042 3.125a.785.785 0 0 1-.742.534Z"
      />
      <path
        fill="#FD7F01"
        d="M7.292 15.885H3.125a.781.781 0 0 0-.781.782v6.25c0 .431.35.78.781.78h4.167c.431 0 .78-.349.78-.78v-6.25a.781.781 0 0 0-.78-.782Z"
      />
      <path
        fill="#514EFF"
        d="M14.583 11.719h-4.166a.781.781 0 0 0-.782.781v10.417c0 .431.35.78.782.78h4.166c.432 0 .782-.349.782-.78V12.5a.781.781 0 0 0-.782-.781ZM21.875 9.635h-4.167a.781.781 0 0 0-.78.782v12.5c0 .431.349.78.78.78h4.167c.431 0 .781-.349.781-.78v-12.5a.781.781 0 0 0-.781-.782Z"
      />
    </svg>
  ),
  Bell: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      {...props}
    >
      <path
        fill="#514EFF"
        d="M14 26.542a3.21 3.21 0 0 1-3.208-3.209v-1.166a.875.875 0 0 1 .875-.875h4.666a.875.875 0 0 1 .875.875v1.166A3.211 3.211 0 0 1 14 26.542Zm-1.458-3.5v.291a1.458 1.458 0 1 0 2.916 0v-.291h-2.916Z"
      />
      <path
        fill="#1612D1"
        d="M14 6.708a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25Z"
      />
      <path
        fill="#514EFF"
        d="M22.167 23.042H5.833a.875.875 0 0 1 0-1.75h16.334a.875.875 0 1 1 0 1.75Z"
      />
      <path
        fill="#514EFF"
        d="M14 7.292a7.884 7.884 0 0 0-7.875 7.875v7a.875.875 0 0 0 .875.875h14a.875.875 0 0 0 .875-.875v-7A7.884 7.884 0 0 0 14 7.292Z"
      />
      <path
        fill="#1612D1"
        d="M4.667 11.375a.874.874 0 0 1-.842-1.118 7.795 7.795 0 0 1 2.636-3.946A7.517 7.517 0 0 1 9.1 4.99a.875.875 0 0 1 .467 1.687c-.737.2-1.426.544-2.028 1.013a6.032 6.032 0 0 0-2.03 3.053.876.876 0 0 1-.842.632ZM23.333 11.375a.876.876 0 0 1-.84-.632A6.028 6.028 0 0 0 20.46 7.69a5.748 5.748 0 0 0-2.028-1.013.875.875 0 1 1 .467-1.687c.958.261 1.855.71 2.639 1.32a7.791 7.791 0 0 1 2.637 3.947.874.874 0 0 1-.843 1.118Z"
      />
      <path
        fill="#FD7F01"
        d="M21.625 23.917a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25Z"
      />
    </svg>
  ),
};
