import { useState } from "react";
import { useParamsState } from "../hooks/useParamState";
import {
  brand_url,
  coupons_url,
  first_category_url,
  product_url,
  second_category_url,
  third_category_url,
} from "../api/urls";
import { Loading } from "../components/loading";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { Actions } from "../components/actions";
import { Eye, Pencil, Trash2, X } from "lucide-react";
import { TableComponent } from "../components/table";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validators } from "../utils/validators";
import { generateUID } from "../utils/generateUID";
import { api } from "../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../utils/unknownError";
import { FieldGrid, FieldSet, Form } from "../components/form";
import { Input } from "../components/input";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { ReactComponent as NoCoupon } from "../images/noCoupon.svg";
import moment from "moment";
import { chipByStatus, tickByStatus } from "../utils/uiByStatus";
import { View } from "../components/view";
import { useSelector } from "react-redux";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { useFetch } from "../hooks/useFetch";
import { modules, perms } from "../api/codes";
import { Authorization } from "../components/authorization";

export function Coupon() {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const [editData, setEditData] = useState();
  const { data: couponsData, pagination } = useFetchWithPagination(coupons_url);

  if (couponsData.loading || !couponsData.data) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <Authorization module={modules.Coupon} perm={perms.add} page>
        <CouponForm
          setSubPage={setSubPage}
          editData={editData}
          refetch={couponsData.refetch}
        />
      </Authorization>
    );
  }

  if (couponsData?.data?.length === 0) {
    return (
      <Box
        sx={{
          padding: "30px",
          bgcolor: "background.paper",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoCoupon />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Authorization module={modules.Coupon} perm={perms.add}>
            <Button variant="contained" onClick={() => setSubPage("add")}>
              Add Coupon
            </Button>
          </Authorization>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Promo Code",
      field: "promocode",
    },
    {
      headerName: "Expiry Date",
      field: "expiry",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Discount Type",
      field: "discount_type",
    },
    {
      headerName: "Discount Value",
      field: "value",
    },
    {
      headerName: "Date Created",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Status",
      field: "coupon_request_status",
      renderCell: (params) => chipByStatus(params.value),
    },
    {
      headerName: "Actions",
      renderCell: (params) => (
        <Actions
          options={[
            {
              icon: Pencil,
              name: "Edit",
              onClick: () => {
                setEditData(params.row);
                setSubPage("add");
              },
              module: modules.Coupon,
              perm: perms.edit,
            },
            {
              name: "View",
              render: () => <CouponView data={params.row} />,
              module: modules.Coupon,
              perm: perms.view,
            },
            {
              icon: Trash2,
              name: "Delete",
              deleteUrl: coupons_url,
              deleteId: params.row.id,
              refetch: couponsData.refetch,
              module: modules.Coupon,
              perm: perms.delete,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Authorization module={modules.Coupon} perm={perms.add}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setEditData();
              setSubPage("add");
            }}
            sx={{ px: "20px" }}
          >
            Add Coupon
          </Button>
        </Authorization>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={couponsData.loading}
        rows={couponsData?.data?.results || []}
        columns={columns}
      />
      {pagination}
    </Box>
  );
}

function CouponForm({ setSubPage, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        promocode: validators.stringRequired,
        min_txn: validators.numberRequired,
        discount_type: validators.stringRequired,
        discount_upto: validators.numberRequired,
        expiry: validators.stringRequired,
        // products: validators.numberRequired,
        // brand: validators.numberRequired,
        // category_first: validators.numberRequired,
        // category_second: validators.numberRequired,
        // category_third: validators.numberRequired,
      })
    ),
    shouldFocusError: true,
  });

  const oneSelected =
    methods.getValues("products") ||
    methods.getValues("brand") ||
    methods.getValues("category_first") ||
    methods.getValues("category_second") ||
    methods.getValues("category_third");

  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();

    if (!oneSelected) {
      enqueueSnackbar("Please link coupon atlist one dropdown", {
        variant: "error",
      });
      return;
    }

    newData.seller = userId;
    newData.code = generateUID();
    newData.printable_name = generateUID();
    delete newData.coupon_image;
    try {
      const response = await (editData
        ? api.patch(`${coupons_url}${editData.id}/`, newData)
        : api.post(coupons_url, newData));
      if (response?.status === 200 || response?.status === 201) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  const productData = useFetch(product_url);
  const cat1Data = useFetch(first_category_url);
  const cat2Data = useFetch(second_category_url);
  const cat3Data = useFetch(third_category_url);
  const brandData = useFetch(brand_url);

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <FieldGrid>
            <Input.Text
              methods={methods}
              name="promocode"
              label="Coupon Code"
              placeholder="Coupon Code"
              required
            />
            <Input.Number
              methods={methods}
              name="min_txn"
              label="Minimum Spend"
              placeholder="Minimum Spend"
              required
            />
            <Input.Select
              methods={methods}
              name="discount_type"
              label="Discount Type"
              placeholder="Discount Type"
              options={[
                { id: "percent", printable_name: "Percent" },
                { id: "value", printable_name: "Value" },
              ]}
              required
            />
            <Input.Number
              methods={methods}
              name="discount_upto"
              label="Discount Value"
              placeholder="Discount Value"
              required
            />
            <Input.Date
              methods={methods}
              name="expiry"
              label="Coupon Expiry"
              placeholder="Expiry"
              required
            />
            <Input.Select
              methods={methods}
              name="products"
              label="Select Product"
              placeholder="Select Product"
              options={productData.data}
              loading={productData.loading}
              required
              disabled={!methods.watch("products") && oneSelected}
            />
            <Input.Select
              methods={methods}
              name="brand"
              label="Select Brand"
              placeholder="Select Brand"
              options={brandData.data}
              loading={brandData.loading}
              required
              disabled={!methods.watch("brand") && oneSelected}
            />
            <Input.Select
              methods={methods}
              name="category_first"
              label="Select First Category"
              placeholder="Select First Category"
              options={cat1Data.data}
              loading={cat1Data.loading}
              required
              disabled={!methods.watch("category_first") && oneSelected}
            />
            <Input.Select
              methods={methods}
              name="category_second"
              label="Select Second Category"
              placeholder="Select Second Category"
              options={cat2Data.data}
              loading={cat2Data.loading}
              required
              disabled={!methods.watch("category_second") && oneSelected}
            />
            <Input.Select
              methods={methods}
              name="category_third"
              label="Select Third Category"
              placeholder="Select Third Category"
              options={cat3Data.data}
              loading={cat3Data.loading}
              required
              disabled={!methods.watch("category_third") && oneSelected}
            />
          </FieldGrid>
          <Input.Switch methods={methods} name="todo" label="Active" />
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}

function CouponView({ data: d }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <IconButton sx={{ color: "text.primary" }}>
          <Eye style={{ height: "15px", width: "15px" }} />
        </IconButton>
      </Box>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Typography fontSize={20} fontWeight="500">
            {d.promocode}
          </Typography>
          <Box sx={{ p: "10px" }} />
          <View
            data={[
              {
                label: "Expiry Date",
                value: moment(d.expiry).format("DD-MM-YYYY"),
              },
              {
                label: "Discount Type",
                value: d.discount_type,
              },
              {
                label: "Discount Value",
                value: d.value,
              },
              {
                label: "Minimum Transaction",
                value: d.min_txn,
              },
              {
                label: "Discount Up To",
                value: d.discount_upto,
              },
              {
                label: "Status",
                value: tickByStatus(d.coupon_request_status),
              },
              {
                label: "Date Created",
                value: moment(d.created_at).format("DD-MM-YYYY"),
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
}
