import { useEffect, useState } from "react";
import "./root.css";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  TextField,
  Menu,
  MenuItem,
  Box,
  useTheme,
} from "@mui/material";
import { Eye, EyeOff, ChevronDown, Search, Sun, MoonStar } from "lucide-react";
import loginBg from "../images/loginBg.png";
import { ReactComponent as LogoWhite } from "../images/logoWhite.svg";
import { useParamsState } from "../hooks/useParamState";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiPublic } from "../api/api";
import {
  account_id_check_url,
  country_url,
  subuser_login_url,
  user_login_url,
  user_register_url,
  user_resend_otp_url,
  user_submit_otp_url,
} from "../api/urls";
import { unknownError } from "../utils/unknownError";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../components/input";
import { FieldSet } from "../components/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../utils/validators";
import { login } from "../redux/userStore";
import { enqueueSnackbar } from "notistack";
import { Tab } from "../components/tabs";
import _ from "lodash";
import { useLocalStorage } from "usehooks-ts";

export function Root() {
  const access = useSelector((state) => state.user.accessToken);
  const pwd_temp = useSelector((state) => state.user.pwd_temp);

  if (pwd_temp && access) {
    return <Navigate to="/update_temp_password" />;
  }

  if (access) {
    return <Navigate to="/dashboard" />;
  }

  return <Login />;
}

export function Login() {
  const [page, setPage] = useParamsState("page", "sign-in");
  const [themeVarient, setThemeVarient] = useLocalStorage("theme", "light");
  const theme = useTheme();

  return (
    <Box
      className={`login-container ${
        page === "sign-up" ? "right-panel-active" : ""
      }`}
      id="container"
      sx={{ bgcolor: "background.paper" }}
    >
      <IconButton
        onClick={() =>
          setThemeVarient(themeVarient === "light" ? "dark" : "light")
        }
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
          zIndex: 1000,
          bgcolor: "background.paper",
          boxShadow: theme.shadows[2],
          "&:hover": {
            bgcolor: "background.paper",
          },
        }}
      >
        {themeVarient === "light" ? <Sun size={20} /> : <MoonStar size={20} />}
      </IconButton>

      <div className="form sign-up-container">
        <RegistrationForm setPage={setPage} />
      </div>

      <div className="form sign-in-container">
        <LoginForm setPage={setPage} />
      </div>

      <div className="overlap-container">
        <img className="login-bg" src={loginBg} alt="login bg" />
        <Box className="overlap-panels">
          <div className="overlap-panel overlap-left">
            <div className="login-form">
              <h1>Welcome Back</h1>
              <div style={{ padding: "5px" }} />
              <p className="para">
                Login with us to continue your journey with us!
              </p>
              <div style={{ padding: "10px" }} />
              <LoadingButton
                variant="outlined"
                color="white"
                sx={{
                  width: "100%",
                  maxWidth: "250px",
                  borderWidth: "2px",
                  "&:hover": {
                    borderWidth: "2px",
                  },
                }}
                onClick={() => setPage("sign-in")}
              >
                Sign In
              </LoadingButton>
            </div>
            <LogoWhite className="login-logo-white" />
          </div>
          <div className="overlap-panel overlap-right">
            <div className="login-form">
              <h1>Hello, There !</h1>
              <div style={{ padding: "5px" }} />
              <p className="para">
                Enter your details to start your journey as pentagon seller.
              </p>
              <div style={{ padding: "10px" }} />
              <LoadingButton
                variant="outlined"
                color="white"
                sx={{
                  width: "100%",
                  maxWidth: "250px",
                  borderWidth: "2px",
                  "&:hover": {
                    borderWidth: "2px",
                  },
                }}
                onClick={() => setPage("sign-up")}
              >
                Sign Up
              </LoadingButton>
            </div>
            <LogoWhite className="login-logo-white" />
          </div>
        </Box>
      </div>
    </Box>
  );
}

export function MobileNumberDropdown({
  value: currentSelect,
  onChange: setCurrentSelect,
  countryData,
}) {
  const [search, setSearch] = useState("");
  const [anchor, setAnchor] = useState(null);
  const onClick = (e) =>
    anchor ? setAnchor(null) : setAnchor(e.currentTarget);
  const select = (option) => {
    setCurrentSelect(option);
    setAnchor(null);
  };
  return (
    <>
      <IconButton
        onClick={onClick}
        edge="start"
        sx={{
          fontSize: "15px",
          color: "text.primary",
          marginRight: "-10px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        disableRipple
      >
        +{currentSelect} <ChevronDown />
      </IconButton>
      <Menu onClose={() => setAnchor(null)} anchorEl={anchor} open={!!anchor}>
        <Box
          sx={{
            padding: "0px 10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="small"
            fullWidth
            type="text"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {countryData?.data
            ?.filter((country) =>
              country?.printable_name?.toLowerCase()?.includes(search)
            )
            ?.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => select(option)}
                sx={{ display: "flex", gap: "10px" }}
              >
                <img height="20px" width="30px" src={option.flag} alt="flag" />
                {option.printable_name}
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </>
  );
}

const checkAccountID = _.debounce(async (methods) => {
  try {
    await apiPublic.get(
      `/in${account_id_check_url}?account_id=${methods.watch("account_id")}`
    );
    methods.clearErrors("account_id");
  } catch (e) {
    const error = e?.response?.data?.data;
    if (error) {
      methods.setError("account_id", { message: error });
    }
  }
}, 300);

function RegistrationForm({ setPage }) {
  const theme = useTheme();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const countryData = useFetchPublic(country_url);
  const [step, setStep] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const remove = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      } else {
        clearTimeout(remove);
      }
    }, 1000);
    return () => {
      clearTimeout(remove);
    };
  }, [seconds]);

  const schemas = [
    z
      .object({
        mobile_number: validators.stringRequired,
        country: validators.numberRequired,
        account_id: validators.stringRequired,
        email_id: validators.emailRequired,
        password: validators.stringRequired,
        confirm_password: validators.stringRequired,
      })
      .superRefine((values, ctx) => {
        if (values.confirm_password !== values.password) {
          ctx.addIssue({
            message: "Password and confirm password should be same",
            path: ["confirm_password"],
          });
        }
        if (countryData?.data) {
          const regex = countryData.data.find(
            (c) => c.country_mobile_code === values.country
          )?.country_regex;
          if (
            regex &&
            !RegExp(regex).test(`${values.country}${values.mobile_number}`)
          ) {
            ctx.addIssue({
              message: "Invalid mobile no",
              path: ["mobile_number"],
            });
          }
        }
      }),
    z.object({ recepient_otp: validators.otpRequired }),
  ];
  const methods = useForm({
    resolver: zodResolver(schemas[step]),
  });

  const accountID = methods.watch("account_id");

  useEffect(() => {
    if (accountID) {
      checkAccountID(methods);
    }
  }, [accountID, methods]);

  const { setValue } = methods;
  const loading = methods.formState.isSubmitting;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    if (countryData?.data) {
      const india = countryData.data.find((c) => c.country_mobile_code === 91);
      setValue("country", india.country_mobile_code);
      setValue("alpha_two_code", india.alpha_two_code);
    }
  }, [countryData.data, setValue]);
  const submitByStep = [
    async () => {
      const newData = { ...methods.getValues() };
      newData.mobile_number = newData.country + newData.mobile_number;
      try {
        const response = await apiPublic.post(
          `/${newData.alpha_two_code}${user_register_url}`,
          newData
        );
        if (response?.status === 200) {
          setValue("token", response?.data.token);
          setStep(step + 1);
          setSeconds(30);
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    },
    async () => {
      const values = methods.getValues();
      const newData = { recipient_otp: values.recepient_otp };
      try {
        const response = await apiPublic.post(
          `/${values.alpha_two_code}${user_submit_otp_url}`,
          newData,
          { headers: { Token: values.token } }
        );
        if (response?.status === 200) {
          enqueueSnackbar(
            "Account created successfully, Please login to complete on-boarding process",
            { variant: "success" }
          );
          navigate("/dashboard");
        } else if (response?.status === 400) {
          if (response?.data?.data) {
            enqueueSnackbar(response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        } else {
          unknownError();
        }
      } catch (e) {
        if (e?.response?.status === 400 && e?.response?.data?.data) {
          enqueueSnackbar(e?.response?.data.data, { variant: "error" });
        } else {
          unknownError();
        }
      }
    },
  ];
  return (
    <form
      style={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
      className="login-form"
      onSubmit={methods.handleSubmit(submitByStep[step])}
    >
      {step === 0 && (
        <>
          <h1 className="color-accent">Create Account</h1>
          <p className="color-accent para">Fill Below informaton To Sign Up</p>
          <div style={{ padding: "5px" }} />
          <FieldSet>
            {/* <Box sx={{ display: "flex", gap: "10px" }}>
              <Input.Text
                methods={methods}
                name="firstname"
                label="First name"
                placeholder="Your first name"
              />
              <Input.Text
                methods={methods}
                name="lastname"
                label="Last name"
                placeholder="Your last name"
              />
            </Box> */}
            <Input.Text
              methods={methods}
              name="email_id"
              label="E-mail"
              placeholder="Your e-mail"
            />
            <Input.Text
              methods={methods}
              name="account_id"
              label="Account ID"
              placeholder="Account ID"
            />
            <Input.Text
              methods={methods}
              label="Mobile number"
              placeholder="Mobile number"
              name="mobile_number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Controller
                      control={methods.control}
                      name="country"
                      render={({ field }) => (
                        <MobileNumberDropdown
                          countryData={countryData}
                          value={field.value}
                          onChange={(v) => {
                            field.onChange(v.country_mobile_code);
                            setValue("alpha_two_code", v.alpha_two_code);
                          }}
                        />
                      )}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Input.Text
              methods={methods}
              name="password"
              label="Password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Input.Text
              methods={methods}
              name="confirm_password"
              label="Confirm password"
              placeholder="Confirm password"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword((show) => !show)}
                      edge="end"
                    >
                      {showConfirmPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FieldSet>
          <div style={{ padding: "5px" }} />
          <div style={{ padding: "5px" }} />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
          >
            Next
          </LoadingButton>
          <div style={{ padding: "5px" }} />
          <p className="para">
            Have an account ?{" "}
            <span
              className="color-accent"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setPage("sign-in")}
            >
              Sign In
            </span>
          </p>
        </>
      )}
      {step === 1 && (
        <>
          <h1 className="color-accent">Create Account</h1>
          <Box sx={{ p: "10px" }} />
          <p className="color-accent para">
            Enter OTP sent to +{methods.watch("country")}
            {methods.watch("mobile_number")} to verify mobile number
          </p>
          <Box sx={{ p: "5px" }} />
          <Input.OTP methods={methods} name="recepient_otp" />
          <div
            style={
              seconds <= 0
                ? {
                    marginLeft: "auto",
                  }
                : {
                    margin: "auto",
                  }
            }
          >
            {seconds <= 0 ? (
              <p
                className="forgot-password para"
                style={{ marginRight: "30px", cursor: "pointer" }}
                onClick={async () => {
                  setSeconds(30);
                  const values = methods.getValues();
                  const response = await apiPublic.post(
                    `/${values.alpha_two_code}${user_resend_otp_url}`,
                    {
                      country: values.country,
                      mobile_number:
                        values.country?.toString() + values.mobile_number,
                    },
                    { headers: { Token: values.token } }
                  );
                  if (response?.data.token) {
                    setValue("token", response?.data.token);
                  }
                }}
              >
                resend otp
              </p>
            ) : (
              <p className="para">
                00:{seconds < 10 ? "0" : ""}
                {seconds}s
              </p>
            )}
          </div>
          <div style={{ padding: "5px" }} />
          <div style={{ padding: "5px" }} />
          <LoadingButton
            type="submit"
            sx={{ width: "250px" }}
            variant="contained"
            loading={loading}
          >
            Verify
          </LoadingButton>
        </>
      )}
    </form>
  );
}

function LoginForm({ setPage }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const countryData = useFetchPublic(country_url);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const loginSchema = z
    .object({
      ...(activeTab === 1 ? { account_id: validators.stringRequired } : {}),
      mobile_number: validators.stringRequired,
      password: validators.stringRequired,
      country: validators.numberRequired,
    })
    .superRefine((values, ctx) => {
      if (countryData?.data) {
        const regex = countryData.data.find(
          (c) => c.country_mobile_code === values.country
        )?.country_regex;
        if (
          regex &&
          !RegExp(regex).test(`${values.country}${values.mobile_number}`)
        ) {
          ctx.addIssue({
            message: "Invalid mobile no",
            path: ["mobile_number"],
          });
        }
      }
    });
  const methods = useForm({ resolver: zodResolver(loginSchema) });

  const { setValue } = methods;
  const loading = methods.formState.isSubmitting;
  useEffect(() => {
    if (countryData?.data) {
      const india = countryData.data.find((c) => c.country_mobile_code === 91);
      setValue("country", india.country_mobile_code);
      setValue("alpha_two_code", india.alpha_two_code);
    }
  }, [countryData.data, setValue]);
  const theme = useTheme();
  return (
    <form
      style={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
      className="login-form"
      onSubmit={methods.handleSubmit(async () => {
        const newData = { ...methods.getValues() };
        newData.mobile_number = newData.country + newData.mobile_number;
        if (activeTab === 0) {
          try {
            const response = await apiPublic.post(
              `/${newData.alpha_two_code}${user_login_url}`,
              newData
            );
            if (response?.status === 200) {
              dispatch(
                login({
                  accessToken: response?.data?.auth?.access,
                  refreshToken: response?.data?.auth?.refresh,
                  id: response?.data?.user?.seller,
                  username: response?.data?.user?.firstname
                    ? response?.data?.user?.firstname +
                      // " " +
                      // response?.data?.user?.middlename +
                      " " +
                      response?.data?.user?.lastname
                    : "",
                  mobile_number: response?.data?.user?.mobile_number,
                  profile_image: response?.data?.user?.company_image,
                  permissions: response?.data?.user?.permissions,
                  is_owner: response?.data?.user?.is_owner,
                  account_id: response?.data?.user?.account_id,
                  verified: response?.data?.user?.is_verified,
                  // verified: true,
                })
              );
              navigate("/dashboard");
            } else if (response?.status === 400) {
              methods.setError("mobile_number");
              methods.setError("password", {
                message: "mobile number or password is wrong",
              });
            } else {
              unknownError();
            }
          } catch (e) {
            if (e?.response?.status === 400) {
              methods.setError("mobile_number");
              methods.setError("password", {
                message: "mobile number or password is wrong",
              });
            } else {
              unknownError();
            }
          }
        } else {
          try {
            const response = await apiPublic.post(
              `/${newData.alpha_two_code}${subuser_login_url}`,
              newData
            );
            if (response?.status === 200) {
              dispatch(
                login({
                  accessToken: response?.data?.auth?.access,
                  refreshToken: response?.data?.auth?.refresh,
                  id: response?.data?.user?.seller,
                  username: response?.data?.user?.firstname
                    ? response?.data?.user?.firstname +
                      // " " +
                      // response?.data?.user?.middlename +
                      " " +
                      response?.data?.user?.lastname
                    : "",
                  mobile_number: response?.data?.user?.mobile_number,
                  profile_image: response?.data?.user?.company_image,
                  permissions: response?.data?.user?.permissions,
                  is_owner: response?.data?.user?.is_owner,
                  account_id: response?.data?.user?.account_id,
                  verified: response?.data?.user?.is_verified,
                  // verified: true,
                  pwd_temp: response?.data?.user?.pwd_temp,
                })
              );
              if (response?.data?.user?.pwd_temp) {
                navigate("/update_temp_password");
              } else {
                navigate("/dashboard");
              }
            } else if (response?.status === 400) {
              methods.setError("mobile_number");
              methods.setError("password", {
                message: "mobile number or password is wrong",
              });
            } else {
              unknownError();
            }
          } catch (e) {
            if (e?.response?.status === 400) {
              methods.setError("mobile_number");
              methods.setError("password", {
                message: "mobile number or password is wrong",
              });
            } else {
              unknownError();
            }
          }
        }
      })}
    >
      <h1 className="color-accent">Sign In to Pentagon</h1>
      <p className="color-accent para">Enter your username and password</p>
      <div style={{ padding: "5px" }} />
      <Box sx={{ display: "flex", width: "100%" }}>
        <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
          Seller
        </Tab>
        <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
          Subordinate
        </Tab>
      </Box>
      <div style={{ padding: "5px" }} />
      <FieldSet>
        {activeTab === 1 && (
          <Input.Text
            methods={methods}
            label="Account ID"
            placeholder="Account ID"
            name="account_id"
          />
        )}
        <Input.Text
          methods={methods}
          label="Mobile number"
          placeholder="Mobile number"
          name="mobile_number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Controller
                  control={methods.control}
                  name="country"
                  render={({ field }) => (
                    <MobileNumberDropdown
                      countryData={countryData}
                      value={field.value}
                      onChange={(v) => {
                        field.onChange(v.country_mobile_code);
                        setValue("alpha_two_code", v.alpha_two_code);
                      }}
                    />
                  )}
                />
              </InputAdornment>
            ),
          }}
        />
        <Input.Text
          methods={methods}
          label="Password"
          placeholder="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((show) => !show)}
                  edge="end"
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FieldSet>
      <div
        style={{
          marginLeft: "auto",
        }}
      >
        <Link to="/forgot_password">
          <p className="forgot-password para">Forgot password?</p>
        </Link>
      </div>
      <div style={{ padding: "5px" }} />
      <div style={{ padding: "5px" }} />
      <LoadingButton
        loading={loading}
        type="submit"
        sx={{ width: "100%" }}
        variant="contained"
      >
        Sign In
      </LoadingButton>
      <div style={{ padding: "5px" }} />
      <p className="para">
        New to pentagon seller ?{" "}
        <span
          className="color-accent"
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => setPage("sign-up")}
        >
          Sign Up
        </span>
      </p>
    </form>
  );
}

export function useFetchPublic(url, params = "") {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [refetcher, setRefetcher] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (url) {
      setLoading(true);
      setData(undefined);
      try {
        apiPublic
          .get(url + params, { cancelToken: source.token })
          .then((res) => {
            setLoading(false);
            res.data && setData(res.data);
          })
          .catch((err) => {
            setLoading(false);
            unknownError(err);
          });
      } catch (err) {
        setLoading(false);
        unknownError(err);
      }
    }
    return () => {
      source.cancel();
    };
  }, [url, refetcher, params]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return { data, loading, refetch };
}
