import { useState } from "react";

export function useSteps(steps, initialStep = 0, initialComplete = -1) {
  const [step, setStep] = useState(initialStep);
  const [complete, setComplete] = useState(initialComplete);
  return {
    step,
    setStep: (paramStep) => {
      if (paramStep <= complete + 1 && paramStep < steps) {
        setStep(paramStep);
      }
    },
    canGoToStep: (paramStep) => {
      return paramStep <= complete + 1 && paramStep < steps;
    },
    next: () => {
      if (step <= complete + 1 && step + 1 < steps) {
        const currentStep = step;
        if (currentStep - 1 === complete) {
          setComplete(currentStep);
        }
        setStep(currentStep + 1);
      }
    },
    prev: () => {
      if (step - 1 >= 0) {
        setStep(step - 1);
      }
    },
    complete,
    setComplete,
  };
}
