import { icons } from "lucide-react";
import { AccountSection } from "../pages/profilePages/accountSection";
import { BankSection } from "../pages/profilePages/bankSection";
import { AddressSection } from "../pages/profilePages/addressSection";
import { BrandSection } from "../pages/profilePages/brandSection";
import { DocCertSection } from "../pages/profilePages/documentSection";
import { HolidaySection } from "../pages/profilePages/holidaySection";
import { SignatureSection } from "../pages/profilePages/signatureSection";

export const profileSidebarConfig = {
  account: {
    name: "Account",
    component: AccountSection,
    icon: icons.UserCog,
  },
  bank: {
    name: "Bank Details",
    component: BankSection,
    icon: icons.Landmark,
  },
  address: {
    name: "Office Address",
    component: AddressSection,
    icon: icons.MapPin,
  },
  brand: {
    name: "Brand & Category",
    component: BrandSection,
    icon: icons.Copyright,
  },
  document: {
    name: "Documents & Certificates",
    component: DocCertSection,
    icon: icons.FileText,
  },
  holiday: {
    name: "Working Times & Holidays",
    component: HolidaySection,
    icon: icons.CalendarCheck,
  },
  signature: {
    name: "Digital Signature",
    component: SignatureSection,
    icon: icons.ClipboardPen,
  },
};
