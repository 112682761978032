import { Box } from "@mui/material";

export function LabelValue({ label, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontWeight: "500" }}>{label}</Box>
      <Box>{value}</Box>
    </Box>
  );
}
