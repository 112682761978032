import { Box, IconButton, Tooltip } from "@mui/material";
import { api } from "../api/api";
import { AreYouSure } from "./areYouSure";
import { unknownError } from "../utils/unknownError";
import { Authorization } from "./authorization";

export function Actions({ options }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "0px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {options.map((option, index) => {
        const Option = () => {
          if (option.render) {
            return (
              <Tooltip key={index} title={option.name}>
                <option.render />
              </Tooltip>
            );
          }
          return option.deleteUrl ? (
            <AreYouSure
              onYes={async () => {
                try {
                  await api.delete(option.deleteUrl + option.deleteId + "/");
                  option.refetch();
                } catch (e) {
                  unknownError(e);
                }
                // try {
                //   await api.patch(option.deleteUrl + option.deleteId + "/", {
                //     is_delete: true,
                //   });
                // } catch (e) {
                //   unknownError(e);
                // }
              }}
            >
              <Tooltip key={index} title={option.name}>
                <IconButton sx={{ color: "text.primary" }}>
                  <option.icon style={{ height: "15px", width: "15px" }} />
                </IconButton>
              </Tooltip>
            </AreYouSure>
          ) : (
            <Tooltip key={index} title={option.name}>
              <IconButton
                sx={{ color: "text.primary" }}
                onClick={() => option.onClick()}
              >
                <option.icon style={{ height: "15px", width: "15px" }} />
              </IconButton>
            </Tooltip>
          );
        };
        if (option.module) {
          return (
            <Authorization
              key={index}
              module={option.module}
              perm={option.perm}
              page={option.page}
            >
              <Option />
            </Authorization>
          );
        }
        return <Option key={index} />;
      })}
    </Box>
  );
}
