import { useState } from "react";
import { useParamsState } from "../hooks/useParamState";
import { useFetch } from "../hooks/useFetch";
import { product_url, reels_url } from "../api/urls";
import { Loading } from "../components/loading";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { Actions } from "../components/actions";
import { Eye, Pencil, Trash2, X } from "lucide-react";
import { TableComponent } from "../components/table";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validators } from "../utils/validators";
import { generateUID } from "../utils/generateUID";
import { api } from "../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../utils/unknownError";
import { FieldSet, Form } from "../components/form";
import { Input } from "../components/input";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { ReactComponent as NoReels } from "../images/noReel.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { jsonToFormData } from "../utils/jsonToFromData";
import { View } from "../components/view";
import { chipByStatus, tickByStatus } from "../utils/uiByStatus";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { modules, perms } from "../api/codes";
import { Authorization } from "../components/authorization";

export function Reels() {
  const [subPage, setSubPage] = useParamsState("sub_page", "view");
  const [editData, setEditData] = useState();
  const { data: reelsData, pagination } = useFetchWithPagination(reels_url);

  if (reelsData.loading || !reelsData.data) {
    return <Loading />;
  }

  if (subPage === "add") {
    return (
      <Authorization module={modules.Reels} perm={perms.add} page>
        <ReelsForm
          setSubPage={setSubPage}
          editData={editData}
          refetch={reelsData.refetch}
        />
      </Authorization>
    );
  }

  if (reelsData?.data?.length === 0) {
    return (
      <Box
        sx={{
          padding: "30px",
          bgcolor: "background.paper",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoReels />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Authorization module={modules.Reels} perm={perms.add}>
            <Button variant="contained" onClick={() => setSubPage("add")}>
              Add New Reel
            </Button>
          </Authorization>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Media",
      field: "reels_video",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "150px",
              height: "150px",
              borderRadius: "10px",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <video
              width="150px"
              height="150px"
              style={{ objectFit: "contain", borderRadius: "10px" }}
              controls
            >
              <source src={params.value} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>
      ),
    },
    {
      headerName: "Title",
      field: "title",
    },
    {
      headerName: "Product",
      field: "check3",
      renderCell: () => "TODO",
    },
    {
      headerName: "Date",
      field: "updated_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Status",
      field: "reels_request_status",
      renderCell: (params) => chipByStatus(params.value),
    },
    {
      headerName: "Actions",
      renderCell: (params) => (
        <Actions
          options={[
            {
              icon: Pencil,
              name: "Edit",
              onClick: () => {
                setEditData(params.row);
                setSubPage("add");
              },
              module: modules.Reels,
              perm: perms.edit,
            },
            {
              name: "View",
              render: () => <ReelView data={params.row} />,
              module: modules.Reels,
              perm: perms.view,
            },
            {
              icon: Trash2,
              name: "Delete",
              deleteUrl: reels_url,
              deleteId: params.row.id,
              refetch: reelsData.refetch,
              module: modules.Reels,
              perm: perms.delete,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Authorization module={modules.Reels} perm={perms.add}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setEditData();
              setSubPage("add");
            }}
            sx={{ px: "20px" }}
          >
            Add New Reel
          </Button>
        </Authorization>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={reelsData.loading}
        rows={reelsData?.data?.results || []}
        columns={columns}
      />
      {pagination}
    </Box>
  );
}

function ReelsForm({ setSubPage, refetch, editData }) {
  const userId = useSelector((state) => state.user.id);
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        title: validators.stringRequired,
        product: validators.numberRequired,
        reels_video: validators.videoRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    newData.seller_video = userId;
    newData.code = generateUID();
    newData.printable_name = generateUID();
    try {
      const response = await (editData
        ? api.patch(`${reels_url}${editData.id}/`, jsonToFormData(newData))
        : api.post(reels_url, jsonToFormData(newData)));
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
        setSubPage("view");
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };
  const productData = useFetch(product_url);

  return (
    <Box
      sx={{
        padding: "30px",
        bgcolor: "background.paper",
        borderRadius: "10px",
      }}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <FieldSet>
          <Input.Text
            methods={methods}
            name="title"
            label="Reel Title"
            placeholder="Reel Title"
            required
          />
          <Input.Select
            methods={methods}
            name="product"
            label="Product"
            placeholder="Product"
            required
            options={productData.data}
            loading={productData.loading}
          />
          <Input.Text
            methods={methods}
            name="description"
            label="Caption"
            placeholder="Caption"
            required
          />
          <Input.File
            methods={methods}
            name="reels_video"
            label="Reel Video"
            placeholder="Reel Video"
            required
          />
          <Box sx={{ p: "5px" }} />
          <Box className="equal-columns">
            <LoadingButton
              variant="outlined"
              onClick={() => {
                setSubPage("view");
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Box>
        </FieldSet>
      </Form>
    </Box>
  );
}

function ReelView({ data: d }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <IconButton sx={{ color: "text.primary" }}>
          <Eye style={{ height: "15px", width: "15px" }} />
        </IconButton>
      </Box>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "500px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Typography fontSize={20} fontWeight="500">
            {d.title}
          </Typography>
          <Box sx={{ p: "10px" }} />
          <View
            data={[
              {
                label: "Caption",
                value: d.description,
              },
              {
                label: "Reel video",
                value: (
                  <Box
                    sx={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "10px",
                      border: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    <video
                      width="150px"
                      height="150px"
                      style={{ objectFit: "contain", borderRadius: "10px" }}
                      controls
                    >
                      <source src={d.reels_video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                ),
              },
              {
                label: "Request Status",
                value: tickByStatus(d.reels_request_status),
              },
              {
                label: "Request attempts",
                value: d.request_attempts,
              },
              {
                label: "Comment From Approver",
                value: d.comments,
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
}
