import {
  Box,
  ClickAwayListener,
  IconButton,
  InputLabel,
  Tooltip,
  styled,
} from "@mui/material";
import { Info } from "lucide-react";
import React from "react";

export function Form({ methods, children, onSubmit }) {
  const { handleSubmit } = methods;

  return <form onSubmit={handleSubmit(onSubmit)}>{children}</form>;
}

const FieldSetContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
});

const FieldSetContainerGrid = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "10px",
  width: "100%",
  alignItems: "flex-start",
});

export function FieldSet({ children }) {
  return <FieldSetContainer>{children}</FieldSetContainer>;
}

export function FieldGrid({ children, sx }) {
  return <FieldSetContainerGrid sx={sx}>{children}</FieldSetContainerGrid>;
}

export function FormFieldSection({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        borderRadius: "5px",
        padding: "40px",
      }}
    >
      {children}
    </Box>
  );
}

export function FormActionSection({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        borderRadius: "5px",
        padding: "10px",
        display: "flex",
        gap: "10px",
        marginTop: "5px",
        justifyContent: "end",
      }}
    >
      {children}
    </Box>
  );
}

export function Field({
  label,
  children,
  info,
  row = false,
  rowReverse = false,
  fillLabelHeight = false,
}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  return (
    <Box sx={{ display: "flex", gap: "5px", maxWidth: "100%" }}>
      <Box
        sx={{
          flex: "1",
          display: "flex",
          gap: "5px",
          flexDirection: rowReverse ? "row-reverse" : row ? "row" : "column",
          alignItems: row || rowReverse ? "center" : null,
          maxWidth: "100%",
        }}
      >
        <InputLabel
          sx={{
            color: "text.primary",
            justifySelf: "end",
            fontSize: "14px",
            fontWeight: "400",
            minHeight: fillLabelHeight ? "21px" : "auto",
          }}
        >
          {label ? label : ""}
        </InputLabel>

        {children}
      </Box>
      {info && (
        <Box sx={{ alignSelf: "end" }}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              tabIndex={-1}
              title={info}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton
                sx={{
                  color: "text.primary",
                  marginLeft: "0px",
                  marginBottom: "2px",
                }}
                onClick={handleTooltipOpen}
              >
                <Info />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </Box>
      )}
    </Box>
  );
}
