import { Box, useTheme } from "@mui/material";

export function Tab({ children, active, onClick }) {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "100%",
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: active ? "primary.main" : "gray.main",
        fontWeight: active ? "500" : "400",
        borderBottom: "2px solid",
        borderBottomColor: active ? "primary.main" : "background.paper",
        minHeight: "40px",
        cursor: "pointer",
        background: active
          ? `linear-gradient(180deg, ${theme.palette.primary.main}00 0%, ${theme.palette.primary.main}0A 100%)`
          : null,
      }}
    >
      {children}
    </Box>
  );
}
