export const idToname = (value, ids, params_key) => {
  const key = params_key ? params_key : "printable_name";
  if (!value) {
    return undefined;
  }
  if (!ids) {
    return "";
  }
  return ids?.filter?.((d) => d?.id === value)?.[0]?.[key];
};
