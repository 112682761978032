export const base_url = process.env.REACT_APP_API;
export const refresh_token_url = "/erp_refresh/";
export const user_login_url = "/sellerportal/seller_login/";
export const dashboard_graph_url = "/sellerportal/seller-data/";
export const subuser_login_url = "/sellerportal/seller_subuser_login/";
export const account_id_check_url = "/sellerportal/seller_account_valid/";
export const user_logout_url = "/sellerportal/seller_logout/";
export const user_register_url = "/sellerportal/signup/";
export const user_resend_otp_url = "/sellerportal/resendotpseller/";
export const user_submit_otp_url = "/sellerportal/seller_user_register/";
export const user_profile_url = "/sellerportal/seller/";
export const user_email_update_url = "/sellerportal/seller_email_update/";
export const user_temp_pass_update_url =
  "/sellerportal/seller_update_temp_pass/";
export const change_mobile_url = "/sellerportal/sellermobilenoreset/";
export const change_mobile_verify_url = "/sellerportal/sellerverifyotp/";
export const address_url = "/sellerportal/selleraddress/";
export const bank_details_url = "/sellerportal/sellerbank/";
export const bank_details_decrypted_url = "/sellerportal/seller_bank_decrypt/";
export const update_password_url = "/sellerportal/seller_changepass/";
export const request_brand_url = "/sellerportal/seller_brand/";
export const brand_document_url = "/sellerportal/seller_brand_document/";
export const full_brand_url = "/sellerportal/seller_erp_brand/";
export const category_url = "/sellerportal/seller_category/";
export const category_document_url = "/sellerportal/seller_category_document/";
export const seller_type_url = "/sellerportal/business/";
export const company_type_url = "/sellerportal/organization/";
export const facility_type_url = "/sellerportal/facilitytype/";
export const state_url = "/sellerportal/state/";
export const country_url = "/masters/country/";
export const coupons_url = "/sellerportal/coupon/";
export const reels_url = "/sellerportal/reels/";
export const help_center_url = "/sellerportal/seller_help_center/";
export const subject_category_url = "/sellerportal/seller_subject_category/";
export const subject_sub_category_url =
  "/sellerportal/seller_subject_sub_category/";
export const product_url = "/sellerportal/product_add/";
export const global_product_url = "/sellerportal/seller_global_product_add/";
export const b2b_product_url = "/sellerportal/seller_b2b_product_add/";
export const order_list_url = "/sellerportal/sellerorders/";
export const full_first_category_url = "/sellerportal/category01/";
export const full_second_category_url = "/sellerportal/category02/";
export const full_third_category_url = "/sellerportal/category03/";
export const forgot_password_url = "/sellerportal/forget_password/";
export const forgot_password_submit_otp_url = "/sellerportal/put_otp/";
export const forgot_password_password_change_url = "/sellerportal/change_pass/";
export const first_category_url = "/sellerportal/seller_approve_category1/";
export const second_category_url = "/sellerportal/seller_approve_category2/";
export const third_category_url = "/sellerportal/seller_approve_category3/";
export const brand_url = "/sellerportal/seller_approve_brand/";
export const sub_user_list_url = "/sellerportal/seller_sub_user_signup/";
export const roles_url = "/sellerportal/seller_roles/";
export const product_info_url = "/sellerportal/seller_product_info/";
export const property_type_url = "/sellerportal/seller_product_property_type/";
export const property_value_url = "/sellerportal/seller_property_value/";
export const size_url = "/sellerportal/seller_size_label/";
export const color_url = "/sellerportal/seller_hex_color/";
export const hsn_code_url = "/sellerportal/seller_hsn_code/";
export const document_sections_url = "/sellerportal/seller_section/";
export const documents_url = "/sellerportal/seller_document/";
export const certificate_sections_url = "/sellerportal/seller_section/";
export const certificate_url = "/sellerportal/seller_certificate/";
export const holiday_url = "/sellerportal/seller_holiday/";
export const faq_url = "/sellerportal/seller_faq/";
export const terms_url = "/sellerportal/seller_terms/";
export const policy_url = "/sellerportal/seller_help/";
export const contact_us_url = "/sellerportal/seller_helpnumber/";
