import {
  address_url,
  b2b_product_url,
  brand_url,
  color_url,
  country_url,
  first_category_url,
  global_product_url,
  hsn_code_url,
  product_info_url,
  product_url,
  property_type_url,
  property_value_url,
  second_category_url,
  size_url,
  third_category_url,
} from "../api/urls";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useFetchWithPagination } from "../hooks/useFetchWithPagination";
import { FilledIcons } from "../components/filledIcons";
import {
  Camera,
  Clipboard,
  Copy,
  Pencil,
  Search,
  Trash2,
  X,
} from "lucide-react";
import { TableComponent } from "../components/table";
import { Actions } from "../components/actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSteps } from "../hooks/useSteps";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FieldGrid,
  FieldSet,
  Form,
  FormActionSection,
  FormFieldSection,
} from "../components/form";
import { Input } from "../components/input";
import { LoadingButton } from "@mui/lab";
import { Crumbs } from "../components/crumbs";
import { FormSteps } from "../components/formSteps";
import { useFetch } from "../hooks/useFetch";
import { Loading } from "../components/loading";
import { useEffect, useState } from "react";
import { generateUID } from "../utils/generateUID";
import { Tab } from "../components/tabs";
import { DashboardImage } from "../components/dashboardImage";
import { objToParams } from "../utils/objToParams";
import { api } from "../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../utils/unknownError";
import { validators } from "../utils/validators";
import moment from "moment";
import { getBackUrl } from "../components/backButton";
import { useSelector } from "react-redux";
import { idToname } from "../utils/idToName";
import { jsonToFormData } from "../utils/jsonToFromData";
import { cloneDeep } from "lodash";
import { chipByStatus } from "../utils/uiByStatus";

export function Inventory({ type }) {
  const navigate = useNavigate();
  const apiUrl =
    type === "global"
      ? global_product_url
      : type === "b2b"
      ? b2b_product_url
      : product_url;
  const columns = [
    {
      headerName: " ",
      renderCell: (params) => (
        <DashboardImage
          src={params?.row?.product_inventory?.[0]?.image01}
          height="100px"
          width="100px"
        />
      ),
    },
    {
      headerName: "Product Name",
      field: "title",
      wrap: true,
    },
    {
      headerName: "Product ID",
      field: "id",
    },
    {
      headerName: "Variants",
      field: "product_inventory",
      renderCell: (params) => params.value?.filter((x) => x.is_active)?.length,
    },
    {
      headerName: "State",
      field: "product_request_status",
      renderCell: (params) => chipByStatus(params.value),
    },
    {
      headerName: "Actions",
      field: "check5",
      renderCell: (params) => (
        <Actions
          options={[
            {
              icon: Pencil,
              name: "Edit",
              onClick: () => {
                navigate(`edit/${params.row.id}`);
              },
            },
            {
              icon: Trash2,
              name: "Delete",
              deleteUrl: product_url,
              deleteId: params.row.id,
            },
          ]}
        />
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(apiUrl);

  const cardsData = [
    {
      label: "Low On Stock",
      value: "23",
      icon: FilledIcons.PackageDown,
    },
    {
      label: "Products Added",
      value: "23",
      icon: FilledIcons.PackageAdd,
    },
    {
      label: "In QC Process",
      value: "23",
      icon: FilledIcons.PackageShield,
    },
    {
      label: "QC Failed",
      value: "31",
      icon: FilledIcons.PackageWrong,
    },
  ];
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Grid container spacing="20px">
        {cardsData.map((d, index) => (
          <Grid key={index} item xs={6} lg={3}>
            <Card
              sx={{
                padding: "20px",
                display: "flex",
                gap: "10px",
                alignItems: "start",
                justifyContent: "center",
                height: "100%",
              }}
              elevation={0}
            >
              <Box sx={{ marginTop: "3px" }}>
                <d.icon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={16} align="center">
                  {d.label}
                </Typography>
                <Typography fontWeight="500" fontSize={20}>
                  {d.value}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Card sx={{ padding: "20px" }} elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                placeholder="Search"
                size="small"
                variant="outlined"
                sx={{
                  width: "200px",
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  sx: {
                    bgcolor: "background.default",
                  },
                }}
              />
              <Link to="new">
                <Button size="small" variant="contained" sx={{ px: "20px" }}>
                  Add Product
                </Button>
              </Link>
            </Box>
            <Box sx={{ padding: "10px" }} />
            <TableComponent
              columns={columns}
              rows={data.data?.results || []}
              loading={data.loading}
            />
            <Box sx={{ padding: "10px" }} />
            {pagination}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export function NewProduct({ edit, type }) {
  const apiUrl =
    type === "global"
      ? global_product_url
      : type === "b2b"
      ? b2b_product_url
      : product_url;
  const navigate = useNavigate();
  const { step, setStep, next, canGoToStep } = useSteps(6, 0, edit ? 999 : -1);
  const schemas = [
    z.object({
      title: validators.stringRequired,
      brand_details: validators.numberRequired,
      first_category: validators.numberRequired,
      second_category: validators.numberRequired,
      third_category: validators.numberRequired,
      product_origin_country: validators.stringRequired,
      skuid: validators.stringRequired,
      product_country: validators.numberArrayRequired,
    }),
    z.object({
      short_description: validators.stringRequired,
      description: validators.stringRequired,
      search_keys: validators.stringRequired,
    }),
    z.object({
      parameter: z.array(
        z.object({
          paramater_product_type: z.array(
            z
              .object({
                product_property_type: z.any(),
                product_property_value: z.any(),
              })
              .superRefine((values, ctx) => {
                if (
                  values.product_property_type &&
                  !values.product_property_value?.[0]
                ) {
                  ctx.addIssue({
                    message: "This field is required",
                    path: ["product_property_value", 0],
                  });
                }
              })
          ),
        })
      ),
    }),
    z.object({
      has_size: z.any(),
      size: z.any(),
      has_color: z.any(),
      colors: z.any(),
      // product_inventory: z.array(
      //   z.object({
      //     price_tax: z.array(
      //       z
      //         .object({
      //           price_range_start: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           price_range_end: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           price_value: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           disc_percent_range_start: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           disc_percent_range_end: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           disc_percent_value: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           goods_tax_code: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //         })
      //         .superRefine((values, ctx) => {
      //           if (values.price_range_start > values.price_range_end) {
      //             ctx.addIssue({
      //               message: "Start should be smaller than end",
      //               path: ["price_range_start"],
      //             });
      //           }
      //         })
      //     ),
      //     qty_addres: z.array(
      //       z.object({
      //         stock: validators.numberRequired,
      //       })
      //     ),
      //   })
      // ),
      // product_inventory: z.array(
      //   z.object({
      //     price_tax: z.array(
      //       z
      //         .object({
      //           price_range_start: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           price_range_end: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           price_value: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           disc_percent_range_start: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           disc_percent_range_end: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           disc_percent_value: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //           goods_tax_code: validators.numberRequired.or(
      //             validators.stringRequired
      //           ),
      //         })
      //         .superRefine((values, ctx) => {
      //           if (values.price_range_start > values.price_range_end) {
      //             ctx.addIssue({
      //               message: "Start should be smaller than end",
      //               path: ["price_range_start"],
      //             });
      //           }
      //         })
      //     ),
      //     qty_addres: z.array(
      //       z.object({
      //         stock: validators.numberRequired,
      //       })
      //     ),
      //   })
      // ),
    }),
    z
      .object({
        is_cancellable: z.any().optional(),
        is_refundable: z.any().optional(),
        is_returnable: z.any().optional(),
        is_replacable: z.any().optional(),
        min_inv: validators.numberRequired,
        max_inv: validators.numberRequired,
        bufferstock: validators.numberRequired,
      })
      .superRefine((values, ctx) => {
        // if (values.is_returnable && !values.return_span) {
        //   ctx.addIssue({
        //     code: z.ZodIssueCode.custom,
        //     message: "Return span is required when returnable is enabled",
        //     path: ["return_span"],
        //   });
        // }
        // if (values.is_replacable && !values.replace_span) {
        //   ctx.addIssue({
        //     code: z.ZodIssueCode.custom,
        //     message: "Replace span is required when replaceable is enabled",
        //     path: ["replace_span"],
        //   });
        // }
        // if (values.is_replacable && !values.no_replace) {
        //   ctx.addIssue({
        //     code: z.ZodIssueCode.custom,
        //     message:
        //       "Number of replaces is required when replaceable is enabled",
        //     path: ["no_replace"],
        //   });
        // }
        if (Number(values.min_inv) >= Number(values.max_inv)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Minimum inventory must be less than maximum inventory",
            path: ["min_inv"],
          });
        }
      }),
  ];
  const methods = useForm({
    resolver: zodResolver(schemas[step]),
    shouldFocusError: true,
  });
  const [fetching, setFetching] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const params = useParams();
  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      const response = await api.get(`${product_url}${params.id}`);
      if (response.status === 200) {
        setOriginalData(response.data);
        methods.reset(filterFetchedData(response.data));
      } else {
        unknownError();
      }
      setFetching(false);
    };
    if (edit) {
      fetchData();
    }
  }, [edit, methods, params.id]);
  const loading = methods.formState.isSubmitting;

  const brandData = useFetch(
    `${brand_url}?${objToParams({
      sellertype: type === "global" ? "global" : "local",
      // producttype: type === "b2b" ? "b2b" : "b2c",
    })}`
  );
  const categoryOneData = useFetch(
    `${first_category_url}?${objToParams({
      sellertype: type === "global" ? "global" : "local",
      producttype: type === "b2b" ? "b2b" : "b2c",
    })}`
  );
  const categoryTwoData = useFetch(
    `${second_category_url}?${objToParams({
      category_first: methods.watch("first_category"),
      sellertype: type === "global" ? "global" : "local",
      producttype: type === "b2b" ? "b2b" : "b2c",
    })}`
  );
  const categoryThreeData = useFetch(
    `${third_category_url}?${objToParams({
      category_second: methods.watch("second_category"),
      sellertype: type === "global" ? "global" : "local",
      producttype: type === "b2b" ? "b2b" : "b2c",
    })}`
  );
  const sizeData = useFetch(size_url);
  const colorData = useFetch(color_url);
  const productsData = useFetch(apiUrl);
  const countryData = useFetch(country_url);
  const hsnCodeData = useFetch(hsn_code_url);

  const userId = useSelector((state) => state.user.id);
  if (fetching) {
    return <Loading />;
  }

  const steps = [
    {
      title: "Product Information",
      onSubmit: async () => {
        const newData = filterDataBeforeSave(methods.getValues(), step);
        newData.code = generateUID();
        newData.printable_name = generateUID();
        if (!newData.short_description) {
          newData.short_description = "none";
        }
        if (!newData.description) {
          newData.description = "none";
        }
        if (!newData.style_code) {
          newData.style_code = "none";
        }
        newData.duration_end = moment(moment.now()).format("YYYY-MM-DD");
        newData.seller_details = userId;
        newData.is_global = type === "global" ? true : false;
        newData.is_b2b = type === "b2b" ? true : false;
        try {
          let response = await (newData.id
            ? api.patch(`${apiUrl}${newData.id}/`, jsonToFormData(newData))
            : api.post(apiUrl, jsonToFormData(newData)));
          response = await parameterDel(originalData, newData, response);
          if (response?.status === 200 || response?.status === 201) {
            enqueueSnackbar("Data submitted successfully", {
              variant: "success",
            });
            methods.reset(filterFetchedData(response.data));
            setOriginalData(response.data);
            next();
          } else {
            unknownError();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            enqueueSnackbar(e?.response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        }
      },
      body: (
        <>
          <FormFieldSection>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Product Name"
                name="title"
                placeholder="Product Name"
                required
              />
              <Input.Select
                methods={methods}
                label="Brand"
                name="brand_details"
                placeholder="Brand"
                options={brandData.data}
                loading={brandData.loading}
                required
              />
              <Input.Select
                methods={methods}
                label="Category 1"
                name="first_category"
                placeholder="Category 1"
                options={categoryOneData.data}
                loading={categoryOneData.loading}
                required
              />
              <Input.Select
                methods={methods}
                label="Category 2"
                name="second_category"
                placeholder="Category 2"
                options={categoryTwoData.data}
                loading={categoryTwoData.loading}
                required
              />
              <Input.Select
                methods={methods}
                label="Category 3"
                name="third_category"
                placeholder="Category 3"
                options={categoryThreeData.data}
                loading={categoryThreeData.loading}
                required
              />
              <Input.Select
                methods={methods}
                label="Country of Origin"
                name="product_origin_country"
                placeholder="Country of Origin"
                options={countryData.data}
                loading={countryData.loading}
                required
                setKey="printable_name"
              />
              <Input.Text
                methods={methods}
                label="SKU ID"
                name="skuid"
                placeholder="Product SKU ID"
                required
              />
              <Input.Select
                methods={methods}
                label="Country Availability"
                name="product_country"
                placeholder="Country Availability"
                options={countryData.data}
                loading={countryData.loading}
                required
                multiple
              />
            </FieldGrid>
          </FormFieldSection>
          <FormActionSection>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                navigate(getBackUrl());
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} type="submit" variant="contained">
              Continue
            </LoadingButton>
          </FormActionSection>
        </>
      ),
    },
    {
      title: "Product Description",
      onSubmit: async () => {
        const newData = filterDataBeforeSave(methods.getValues(), step);
        newData.is_global = type === "global" ? true : false;
        newData.is_b2b = type === "b2b" ? true : false;
        try {
          let response = await (newData.id
            ? api.patch(`${apiUrl}${newData.id}/`, jsonToFormData(newData))
            : api.post(apiUrl, jsonToFormData(newData)));
          response = await parameterDel(originalData, newData, response);
          if (response?.status === 200 || response?.status === 201) {
            enqueueSnackbar("Data submitted successfully", {
              variant: "success",
            });
            methods.reset(filterFetchedData(response.data));
            setOriginalData(response.data);
            next();
          } else {
            unknownError();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            enqueueSnackbar(e?.response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        }
      },
      body: (
        <>
          <FormFieldSection>
            <FieldSet>
              <Input.Text
                methods={methods}
                label="Short Description"
                name="short_description"
                placeholder="Short Description"
                required
              />
              <Input.Text
                methods={methods}
                label="Description"
                name="description"
                placeholder="Description"
                multiline
                required
              />
              <Input.Tags
                methods={methods}
                label="Key Words"
                name="search_keys"
                placeholder="Key Words"
                required
              />
            </FieldSet>
          </FormFieldSection>
          <FormActionSection>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                navigate(getBackUrl());
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} type="submit" variant="contained">
              Continue
            </LoadingButton>
          </FormActionSection>
        </>
      ),
    },
    {
      title: "Product Specification",
      onSubmit: async () => {
        const newData = filterDataBeforeSave(methods.getValues(), step);
        newData.is_global = type === "global" ? true : false;
        newData.is_b2b = type === "b2b" ? true : false;
        try {
          let response = await (newData.id
            ? api.patch(`${apiUrl}${newData.id}/`, jsonToFormData(newData))
            : api.post(apiUrl, jsonToFormData(newData)));
          response = await parameterDel(originalData, newData, response);
          if (response?.status === 200 || response?.status === 201) {
            enqueueSnackbar("Data submitted successfully", {
              variant: "success",
            });
            methods.reset(filterFetchedData(response.data));
            setOriginalData(response.data);
            next();
          } else {
            unknownError();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            enqueueSnackbar(e?.response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        }
      },
      body: (
        <>
          <ProductSpecification methods={methods} />
          <FormActionSection>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                navigate(getBackUrl());
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} type="submit" variant="contained">
              Continue
            </LoadingButton>
          </FormActionSection>
        </>
      ),
    },
    {
      title: "Product Variants",
      onSubmit: async () => {
        const newData = filterDataBeforeSave(methods.getValues(), step);
        newData.is_global = type === "global" ? true : false;
        newData.is_b2b = type === "b2b" ? true : false;
        if (!isValidVariation(newData, true)) return;
        try {
          let response = await (newData.id
            ? api.patch(`${apiUrl}${newData.id}/`, jsonToFormData(newData))
            : api.post(apiUrl, jsonToFormData(newData)));
          response = await parameterDel(originalData, newData, response);
          if (response?.status === 200 || response?.status === 201) {
            enqueueSnackbar("Data submitted successfully", {
              variant: "success",
            });
            methods.reset(filterFetchedData(response.data));
            setOriginalData(response.data);
            next();
          } else {
            unknownError();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            enqueueSnackbar(e?.response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        }
      },
      body: (
        <>
          <FormFieldSection>
            <Typography color="primary.main" fontSize={16} fontWeight="500">
              Select Type of varients
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Input.CheckBox methods={methods} label="Size" name="has_size" />
              <Input.CheckBox
                methods={methods}
                label="Color"
                name="has_color"
              />
            </Box>
            <Box sx={{ padding: "10px" }} />
            <FieldGrid>
              {methods.watch("has_size") && (
                <Input.Select
                  methods={methods}
                  label="Sizes"
                  name="size"
                  placeholder="Select sizes"
                  options={sizeData.data}
                  loading={sizeData.loading}
                  getKey="size_label"
                  required
                  multiple
                />
              )}
              {methods.watch("has_color") && (
                <Input.Select
                  methods={methods}
                  label="Colors"
                  name="colors"
                  placeholder="Select colors"
                  options={colorData.data}
                  loading={colorData.loading}
                  required
                  multiple
                />
              )}
            </FieldGrid>
            <Box sx={{ padding: "10px" }} />
            <Variants
              sizesData={sizeData}
              colorsData={colorData}
              methods={methods}
              hsnCodeData={hsnCodeData}
            />
          </FormFieldSection>
          <FormActionSection>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                navigate(getBackUrl());
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} type="submit" variant="contained">
              Continue
            </LoadingButton>
          </FormActionSection>
        </>
      ),
    },
    {
      title: "Additional",
      onSubmit: async () => {
        const newData = filterDataBeforeSave(methods.getValues(), step);
        newData.is_global = type === "global" ? true : false;
        newData.is_b2b = type === "b2b" ? true : false;

        try {
          let response = await (newData.id
            ? api.patch(`${apiUrl}${newData.id}/`, jsonToFormData(newData))
            : api.post(apiUrl, jsonToFormData(newData)));
          response = await parameterDel(originalData, newData, response);
          if (response?.status === 200 || response?.status === 201) {
            enqueueSnackbar("Data submitted successfully", {
              variant: "success",
            });
            methods.reset(filterFetchedData(response.data));
            setOriginalData(response.data);
            navigate(getBackUrl());
          } else {
            unknownError();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            enqueueSnackbar(e?.response?.data.data, { variant: "error" });
          } else {
            unknownError();
          }
        }
      },
      body: (
        <>
          <FormFieldSection>
            <Typography color="primary.main" fontSize={16} fontWeight="500">
              Policies
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Input.CheckBox
                methods={methods}
                label="Cancellable"
                name="is_cancellable"
              />
              <Input.CheckBox
                methods={methods}
                label="Refundable"
                name="is_refundable"
              />
              <Input.CheckBox
                methods={methods}
                label="Returnable"
                name="is_returnable"
              />
              <Input.CheckBox
                methods={methods}
                label="Replaceable"
                name="is_replacable"
              />
            </Box>
            <Box sx={{ padding: "10px" }} />

            {/* <FieldGrid sx={{ alignItems: "flex-start" }}>
              {methods.watch("is_returnable") && (
                <Field>
                  <Typography fontSize={14}>
                    Please specify the days for which the product is returnable
                    after the date of product being delivered
                  </Typography>
                  <Input.Number methods={methods} name="return_span" required />
                </Field>
              )}
              {methods.watch("is_replacable") && (
                <Field>
                  <Typography fontSize={14}>
                    Please specify the days for which the product is replaceable
                    after the date of product being delivered
                  </Typography>
                  <Input.Number
                    methods={methods}
                    name="replace_span"
                    required
                  />
                  <Typography fontSize={14}>
                    Please specify number of replaces applicable to the product
                  </Typography>
                  <Input.Number methods={methods} name="no_replace" required />
                </Field>
              )}
            </FieldGrid>
            <Box sx={{ padding: "10px" }} /> */}

            <Typography color="primary.main" fontSize={16} fontWeight="500">
              Order Related
            </Typography>
            <Box sx={{ padding: "5px" }} />
            <FieldGrid>
              <Input.Number
                methods={methods}
                name="qty_can_order"
                label="Maximum orderable quantity"
                placeholder="Maximum orderable quantity"
                required
              />
              {type === "b2b" && (
                <Input.Number
                  methods={methods}
                  label="Unit to Lot"
                  name="unit2lot"
                  placeholder="Unit to Lot"
                  required
                />
              )}
            </FieldGrid>

            <Box sx={{ padding: "10px" }} />
            <Typography color="primary.main" fontSize={16} fontWeight="500">
              Inventory Related
            </Typography>
            <Box sx={{ padding: "5px" }} />
            <FieldGrid>
              <Input.Number
                methods={methods}
                name="min_inv"
                label="Minimum Inventory"
                placeholder="Minimum Inventory"
                required
              />
              <Input.Number
                methods={methods}
                name="max_inv"
                label="Maximum Inventory"
                placeholder="Maximum Inventory"
                required
              />
              <Input.Number
                methods={methods}
                name="bufferstock"
                label="Buffer Stock"
                placeholder="Buffer Stock"
                required
              />
            </FieldGrid>

            <Box sx={{ padding: "10px" }} />
            <Typography color="primary.main" fontSize={16} fontWeight="500">
              You may Like
            </Typography>
            <Box sx={{ padding: "5px" }} />
            <FieldGrid>
              <Input.Select
                methods={methods}
                name="you_may_like"
                placeholder="You May Like"
                getKey="title"
                options={productsData.data}
                loading={productsData.loading}
                multiple
              />
              <Box />
            </FieldGrid>
            <Box sx={{ padding: "10px" }} />
            <Typography color="primary.main" fontSize={16} fontWeight="500">
              Limited Time Deal On Product
            </Typography>
            <Box sx={{ padding: "5px" }} />
            <FieldGrid>
              <Input.Date
                methods={methods}
                name="deal_start"
                placeholder="From"
              />
              <Input.Date methods={methods} name="deal_end" placeholder="To" />
            </FieldGrid>
          </FormFieldSection>
          <FormActionSection>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                navigate(getBackUrl());
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton loading={loading} type="submit" variant="contained">
              Save
            </LoadingButton>
          </FormActionSection>
        </>
      ),
    },
  ];

  return (
    <>
      <Crumbs />
      {/* <pre>{JSON.stringify(methods.watch(), null, 2)}</pre>
      <pre>{JSON.stringify(methods.formState.errors, null, 2)}</pre> */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Form methods={methods} onSubmit={steps[step].onSubmit}>
          <FormSteps
            currentStep={step}
            setCurrentStep={setStep}
            steps={steps}
            canGoToStep={canGoToStep}
            submit={async () => {
              return true;
            }}
          />
        </Form>
      </Box>
    </>
  );
}

function filterDataBeforeSave(paramData, step) {
  const data = { ...paramData };
  if (data) {
    if (data.parameter) {
      data.parameter.forEach((info, idx) => {
        data.parameter[idx] = { ...data.parameter[idx] };
        data.parameter[idx].paramater_product_type = [
          ...info.paramater_product_type.filter(
            (f) =>
              f?.product_property_type &&
              f?.product_property_value?.length &&
              f?.product_property_value?.length > 0
          ),
        ];
      });
      data.parameter = data.parameter.filter(
        (p) =>
          p.paramater_product_type?.length &&
          p.paramater_product_type?.length > 0
      );
    }
    if (
      step === 3 &&
      data.product_inventory &&
      data.product_inventory?.length > 0
    ) {
      data.product_inventory.forEach((pi, pidx) => {
        if (!data.product_inventory[pidx].is_active) {
          data.product_inventory[pidx].price_tax = [];
          data.product_inventory[pidx].qty_addres = [];
        }
        if (pi.price_tax && pi.price_tax?.length > 0) {
          pi.price_tax.forEach((pt, ptidx) => {
            if (pt.charges_of_product && pt.charges_of_product?.length > 0) {
              data.product_inventory[pidx].price_tax[ptidx].charges_of_product =
                pt.charges_of_product.filter((cp) => cp.charge_name);
            }
          });
        }
      });
    }
  }
  if (!data.is_replacable) {
    data.replace_span = 0;
    data.no_replace = 0;
  }
  if (!data.is_returnable) {
    data.return_span = 0;
    data.no_return = 0;
  }
  return data;
}

async function parameterDel(originalData, newData, oldResponse) {
  const ids = {};
  const addId = (key, id) => {
    if (!ids[key]) ids[`${key}_del`] = [];
    ids[`${key}_del`].push(id);
  };

  const collectIds = (original, updated, parentKey = "") => {
    if (!original) return;

    if (Array.isArray(original)) {
      original.forEach((originalItem) => {
        if (typeof originalItem === "number") {
          // Handle number case
          if (!updated?.includes(originalItem)) {
            addId(parentKey, originalItem);
          }
        } else if (originalItem?.id) {
          // Handle object with id case
          const matchingItem = updated?.find(
            (updatedItem) => updatedItem.id === originalItem.id
          );

          if (!matchingItem) {
            addId(parentKey, originalItem.id);
            // Recursively check deleted item's children

            collectIds(originalItem, updated?.[0], parentKey);
          } else {
            // Recursively check nested arrays/objects
            collectIds(originalItem, matchingItem, parentKey);
          }
        }
      });
    } else if (typeof original === "object" && original !== null) {
      Object.keys(original).forEach((key) => {
        collectIds(original[key], updated?.[key], key);
      });
    }
  };

  collectIds(originalData, newData);

  delete ids.product_property_value_del;

  if (Object.keys(ids).length > 0) {
    const response = await api.patch(
      `${product_url}${newData.id}/parameter_del/`,
      ids
    );
    return response;
  }
  return oldResponse;
}

const PSCodes = [
  { code: "g_info", name: "General Information" },
  { code: "t_info", name: "Technical Information" },
  { code: "a_info", name: "Additional Information" },
  { code: "o_info", name: "Other Information" },
];

function ProductSpecification({ methods }) {
  const ProductInfoData = useFetch(product_info_url);
  if (ProductInfoData.loading || !ProductInfoData.data) {
    return <Loading />;
  }
  return (
    <PSWithData
      ids={PSCodes.map((psc) => {
        const id = ProductInfoData?.data?.find(
          (pi) => pi.code === psc.code
        )?.id;
        return { id, code: psc.code };
      })}
      methods={methods}
    />
  );
}

function PSWithData({ methods, ids }) {
  const { fields, append } = useFieldArray({
    name: "parameter",
    control: methods.control,
    keyName: "_id",
  });
  useEffect(() => {
    if (ids && Array.isArray(ids) && ids.length > 0) {
      const old_data = methods.watch(`parameter`);
      ids.forEach(({ id, code }) => {
        const existing = old_data.find((d) => d.product_info === id);
        if (!existing) {
          append({
            description: generateUID(),
            printable_name: generateUID(),
            disable: false,
            is_active: true,
            code: generateUID(),
            product_info: id,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      {PSCodes.map((psc, index) =>
        fields.find(
          (d) => d.product_info === ids.find((id) => id.code === psc.code)?.id
        ) ? (
          <FormFieldSection key={psc.code}>
            <PSSection
              methods={methods}
              pre={`parameter.${fields.findIndex(
                (d) =>
                  d.product_info === ids.find((id) => id.code === psc.code)?.id
              )}`}
              title={PSCodes[index].name}
            />
          </FormFieldSection>
        ) : null
      )}
    </Box>
  );
}

function PSSection({ methods, pre, title }) {
  const { fields, append, remove } = useFieldArray({
    name: `${pre}.paramater_product_type`,
    control: methods.control,
    keyName: "_id",
  });

  useEffect(() => {
    const watchedFields = methods.watch(`${pre}.paramater_product_type`) || [];

    if (watchedFields.length > fields.length) {
      // If there are more watched fields than in the useFieldArray,
      // append the missing fields
      watchedFields.forEach((field) => {
        append({
          ...field,
          _id: field._id || generateUID(),
        });
      });
    } else if (watchedFields.length === 0 && fields.length === 0) {
      // If both are empty, add an initial field
      append({
        description: generateUID(),
        printable_name: generateUID(),
        disable: false,
        is_active: true,
        code: generateUID(),
        product_property_type: "",
        product_property_value: "",
      });
    }
  }, [fields.length, append, methods, pre]);

  const propertyType = useFetch(
    `${property_type_url}?${objToParams({
      cathird: methods.watch("third_category"),
    })}`
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}
      >
        <Typography color="primary.main" fontSize={16} fontWeight="500">
          {title}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            append({
              description: generateUID(),
              printable_name: generateUID(),
              disable: false,
              is_active: true,
              code: generateUID(),
            });
          }}
        >
          Add Field
        </Button>
      </Box>
      <Grid container spacing="20px">
        <Grid item xs={5}>
          <Typography fontSize={16} fontWeight="400" align="center">
            Label
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography fontSize={16} fontWeight="400" align="center">
            Value
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        {fields.map((field, index) => (
          <PSForm
            key={field._id}
            field={field}
            index={index}
            methods={methods}
            pre={pre}
            remove={remove}
            propertyType={propertyType}
          />
        ))}
      </Grid>
    </Box>
  );
}

function PSForm({ field, methods, pre, index, remove, propertyType }) {
  const propertyValue = useFetch(
    `${property_value_url}?${objToParams({
      proptype: methods.watch(
        `${pre}.paramater_product_type.${index}.product_property_type`
      ),
    })}`,
    {
      run: methods.watch(
        `${pre}.paramater_product_type.${index}.product_property_type`
      ),
    }
  );
  return (
    <>
      <Grid key={field._id.toString() + "1"} item xs={5}>
        <Input.Select
          methods={methods}
          name={`${pre}.paramater_product_type.${index}.product_property_type`}
          placeholder="Label"
          loading={propertyType.loading}
          options={propertyType.data}
        />
      </Grid>
      <Grid key={field._id.toString() + "2"} item xs={5}>
        <Input.Select
          methods={methods}
          name={`${pre}.paramater_product_type.${index}.product_property_value.0`}
          placeholder="Value"
          loading={propertyValue.loading}
          options={propertyValue.data}
        />
      </Grid>
      <Grid key={field._id.toString() + "3"} item xs={2}>
        <Button
          variant="outlined"
          color="error"
          sx={{ padding: "7px", minWidth: "0" }}
          onClick={() => remove(index)}
        >
          <Trash2 />
        </Button>
      </Grid>
    </>
  );
}

const isValidVariation = (data, sendToast = true) => {
  let ok = true;
  const toast = (msg) => {
    if (ok) {
      if (sendToast) {
        enqueueSnackbar(msg, { variant: "error" });
      }
      ok = false;
    }
  };
  data?.product_inventory?.forEach((d) => {
    if (d.is_active) {
      if (!isValidImages(d, false)) {
        toast("Please fill all the fields colored in red");
      }
      if (!isValidPrice(d, false)) {
        toast("Please fill all the fields colored in red");
      }
      if (!isValidInventory(d, false)) {
        toast("Please fill all the fields colored in red");
      }
    }
  });
  data?.size_chart_data?.forEach((x) =>
    x?.column_data?.forEach((d) => {
      if (!d.inch || !d.cm) {
        toast("Please fill all columns in size chart or remove columns");
      }
    })
  );
  return ok;
};

const isValidImages = (data, error = true) => {
  let ok = true;
  const toast = (msg) => {
    if (error) {
      enqueueSnackbar(msg, { variant: "error" });
    }
    ok = false;
  };
  if (!data.image01) {
    toast("First image is required");
  }
  return ok;
};

const isValidPrice = (data, error = true) => {
  let ok = true;
  const toast = (msg) => {
    if (error) {
      enqueueSnackbar(msg, { variant: "error" });
    }
    ok = false;
  };
  if (
    !data.price_tax ||
    !(data.price_tax?.length && data.price_tax?.length > 0)
  ) {
    toast("No country availability selected.");
  }
  data?.price_tax?.forEach((data) => {
    if (!data.price_range_start || !data.price_range_end) {
      toast("Price range is required");
    }
    if (Number(data.price_range_start) > Number(data.price_range_end)) {
      toast("Start should be smaller than end");
    }
    if (!data.price_value) {
      toast("Basic price is required");
    }
    if (Number(data.price_value) > Number(data.price_range_end)) {
      toast("Price should be smaller than price range end");
    }
    if (Number(data.price_value) < Number(data.price_range_start)) {
      toast("Price should be greater than price range start");
    }
    if (!data.goods_tax_code) {
      toast("Tax code is required");
    }
    if (!data.disc_percent_range_start || !data.disc_percent_range_end) {
      toast("Discount range is required");
    }
    if (
      Number(data.disc_percent_range_start) >
      Number(data.disc_percent_range_end)
    ) {
      toast("Start should be smaller than end");
    }
    if (!data.disc_percent_value) {
      toast("Discount percent is required");
    }
    if (Number(data.disc_percent_value) > Number(data.disc_percent_range_end)) {
      toast("Discount percent should be smaller than range end");
    }
    if (
      Number(data.disc_percent_value) < Number(data.disc_percent_range_start)
    ) {
      toast("Discount should be greater than range start");
    }
    data?.charges_of_product
      ?.filter((d) => d.charge_name)
      ?.forEach((d) => {
        if (!d.price_range_start || !d.price_range_end) {
          toast("Charges price range is required");
        }
        if (Number(d.price_range_start) > Number(d.price_range_end)) {
          toast("Start should be smaller than end");
        }
        if (!d.price_value) {
          toast("Charges basic price is required");
        }
        if (Number(d.price_value) > Number(d.price_range_end)) {
          toast("Charges price should be smaller than price range end");
        }
        if (Number(d.price_value) < Number(d.price_range_start)) {
          toast("Charges price should be greater than price range start");
        }
        if (!d.tax_code) {
          toast("Charges SAC should be greater than price range start");
        }
      });
  });
  return ok;
};

const isValidInventory = (data, error = true) => {
  let ok = true;
  const toast = (msg) => {
    if (error) {
      enqueueSnackbar(msg, { variant: "error" });
    }
    ok = false;
  };
  if (
    !data.qty_addres ||
    !(data.qty_addres?.length && data.qty_addres?.length > 0)
  ) {
    toast("Seller address is required");
  }
  data?.qty_addres?.forEach((data) => {
    if (!data.stock && data.stock !== 0) {
      toast("Quantity is required");
    }
  });
  return ok;
};

function Variants({ methods, sizesData, colorsData, hsnCodeData }) {
  const { fields, remove, append } = useFieldArray({
    name: "product_inventory",
    control: methods.control,
    keyName: "_id",
  });

  const [copiedIndex, setCopiedIndex] = useState(null);

  const addressData = useFetch(address_url);

  useEffect(() => {
    const old_data = methods.watch("product_inventory");
    methods.setValue("product_inventory", []);
    fields.forEach((field, index) => {
      remove(index);
    });
    const data_sizes = methods.watch("size");
    const data_colors = methods.watch("colors");
    const sizes =
      data_sizes && data_sizes?.length && data_sizes.length > 0
        ? data_sizes
        : [null];
    const colors =
      data_colors && data_colors?.length && data_colors.length > 0
        ? data_colors
        : [null];

    colors.forEach((color) => {
      sizes.forEach((size) => {
        const is_exist = old_data.find(
          (d) => d.product_colors === color && d.product_size === size
        );
        if (!is_exist) {
          append({
            code: generateUID(),
            printable_name: generateUID(),
            product_colors: color,
            product_size: size,
            is_active: true,
            skuid: "",
            image01: "",
            image02: "",
            image03: "",
            image04: "",
            image05: "",
            image06: "",
            image07: "",
            image08: "",
            price_tax: [],
            qty_addres: [],
            description: "",
            disable: false,
            video: "",
            item_length: "",
            item_width: "",
            item_height: "",
            item_unit: "",
            item_weight: "",
            item_weight_unit: "",
            package_length: "",
            package_width: "",
            package_height: "",
            package_unit: "",
            package_weight: "",
            package_weight_unit: "",
          });
        } else {
          append(is_exist);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch("size"), methods.watch("colors")]);

  const columns = [
    {
      headerName: "",
      field: "id",
      renderCell: (params) => (
        <Input.CheckBox
          methods={methods}
          name={`product_inventory.${params.index}.is_active`}
        />
      ),
    },
    {
      headerName: "Size",
      field: "product_size",
      renderCell: (params) => (
        <Box
          sx={{
            textDecoration: methods.watch(
              `product_inventory.${params.index}.is_active`
            )
              ? "none"
              : "line-through",
          }}
        >
          {idToname(params.value, sizesData.data, "size_label") || "N/A"}
        </Box>
      ),
    },
    {
      headerName: "Color",
      field: "product_colors",
      renderCell: (params) => (
        <Box
          sx={{
            textDecoration: methods.watch(
              `product_inventory.${params.index}.is_active`
            )
              ? "none"
              : "line-through",
          }}
        >
          {idToname(params.value, colorsData.data) || "N/A"}
        </Box>
      ),
    },
    {
      headerName: "SKU ID",
      renderCell: (params) => (
        <Input.Text
          key={methods.watch(`product_inventory.${params.index}.code`)}
          methods={methods}
          name={`product_inventory.${params.index}.skuid`}
          placeholder="SKU ID"
          disabled={
            !methods.watch(`product_inventory.${params.index}.is_active`)
          }
        />
      ),
    },
    {
      headerName: "Images",
      renderCell: (params) => (
        <ProductImages
          key={`product_inventory.${params.index}.images`}
          pre={`product_inventory.${params.index}`}
          methods={methods}
          size={idToname(params.row.product_size, sizesData.data, "size_label")}
          color={idToname(params.row.product_colors, colorsData.data)}
        />
      ),
    },
    {
      headerName: "Prices & Taxes",
      renderCell: (params) => (
        <ProductPrices
          key={`product_inventory.${params.index}.prices`}
          pre={`product_inventory.${params.index}`}
          methods={methods}
          size={idToname(params.row.product_size, sizesData.data, "size_label")}
          color={idToname(params.row.product_colors, colorsData.data)}
          hsnCodeData={hsnCodeData}
        />
      ),
    },
    {
      headerName: "Inventory",
      renderCell: (params) => (
        <Inventorys
          key={`product_inventory.${params.index}.inventories`}
          pre={`product_inventory.${params.index}`}
          methods={methods}
          size={idToname(params.row.product_size, sizesData.data, "size_label")}
          color={idToname(params.row.product_colors, colorsData.data)}
          addressData={addressData}
        />
      ),
    },
    {
      headerName: "",
      renderCell: (params) => {
        const handleCopy = () => {
          setCopiedIndex(params.index);
        };

        const handlePaste = () => {
          const idx = params.index;
          const inv = cloneDeep(
            methods.getValues().product_inventory[copiedIndex]
          );
          const newData = { ...methods.getValues() };
          inv.product_colors = newData.product_inventory[idx].product_colors;
          inv.product_size = newData.product_inventory[idx].product_size;
          changeCode(inv, "product_inventory");
          changeCode(newData.product_inventory[idx], "product_inventory");
          newData.product_inventory[idx] = inv;
          methods.reset(newData);
          setCopiedIndex(null);
        };

        const handleCancel = () => {
          setCopiedIndex(null);
        };

        if (copiedIndex === params.index) {
          return (
            <Button
              size="small"
              variant="contained"
              onClick={handleCancel}
              color="error"
            >
              Cancel
            </Button>
          );
        } else if (copiedIndex !== null) {
          return (
            <Button
              size="small"
              variant="contained"
              startIcon={<Clipboard />}
              onClick={handlePaste}
              color="warning"
            >
              Paste
            </Button>
          );
        } else {
          return (
            <Button
              size="small"
              variant="contained"
              startIcon={<Copy />}
              onClick={handleCopy}
              color="primary"
            >
              Copy
            </Button>
          );
        }
      },
    },
  ];
  return fields.length !== 0 ? (
    <TableComponent columns={columns} rows={fields} />
  ) : null;
}

function changeCode(obj, parentKey, del) {
  if (Array.isArray(obj)) {
    // If it's an array, iterate over each element
    obj.forEach((element) => changeCode(element, parentKey, del));
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, iterate over each key
    for (let key in obj) {
      if (key === "code") {
        if (parentKey && del) {
          del(parentKey + "_del", obj?.["id"]);
        }
        obj[key] = generateUID();
      } else {
        changeCode(obj[key], key, del); // Recursive call for nested objects/arrays
      }
    }
  }
}

function ProductImages({ pre, methods, color, size }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        size="small"
        variant="contained"
        startIcon={<Camera />}
        onClick={() => setOpen(true)}
        color={isValidImages(methods.watch(pre), false) ? "primary" : "error"}
        disabled={!methods.watch(pre).is_active}
      >
        Add Images
      </Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "800px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => {
              // if (isValidImages(methods.watch(pre))) {
              setOpen(false);
              // }
            }}
          >
            <X />
          </IconButton>
          {/* <Form methods={methods} onSubmit={onSubmit}> */}
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography fontSize={16} fontWeight="500">
              Images
            </Typography>
            {color && (
              <Typography fontSize={14} fontWeight="400">
                Color: {color}
              </Typography>
            )}
            {size && (
              <Typography fontSize={14} fontWeight="400">
                Size: {size}
              </Typography>
            )}
          </Box>
          <Box sx={{ padding: "10px" }} />
          <Grid container spacing="10px">
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image01`}
                label="Front Image"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image02`}
                label="Image 1"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image03`}
                label="Image 2"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image04`}
                label="Image 3"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image05`}
                label="Image 4"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image06`}
                label="Image 5"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image07`}
                label="Image 6"
              />
            </Grid>
            <Grid item xs={3}>
              <Input.Image
                methods={methods}
                name={`${pre}.image08`}
                label="Image 7"
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: "10px" }} />
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box> */}
          {/* </Form> */}
        </Box>
      </Dialog>
    </>
  );
}

function ProductPrices({ pre, methods, size, color, hsnCodeData }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const old_data = methods.watch(`${pre}.price_tax`);
    const countries = methods.watch("product_country");
    const newPriceTax = [];

    countries.forEach((c) => {
      const is_exist = old_data?.find((d) => d.country_tax === c);
      if (!is_exist) {
        newPriceTax.push({
          code: generateUID(),
          country_tax: c,
          is_active: true,
          price_range_start: "",
          price_range_end: "",
          price_value: "",
          goods_tax_code: "",
          disc_percent_range_start: "",
          disc_percent_range_end: "",
          disc_percent_value: "",
        });
      } else {
        newPriceTax.push({
          ...is_exist,
          country_tax: c,
          is_active: true,
        });
      }
    });

    methods.setValue(`${pre}.price_tax`, newPriceTax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch("product_country")]);

  useEffect(() => {
    if (methods.watch(`${pre}.price_tax`)?.length === 0) {
      const old_data = methods.watch(`${pre}.price_tax`);
      const countries = methods.watch("product_country");
      const newPriceTax = [];

      countries.forEach((c) => {
        const is_exist = old_data?.find((d) => d.country_tax === c);
        if (!is_exist) {
          newPriceTax.push({
            code: generateUID(),
            country_tax: c,
            is_active: true,
            price_range_start: "",
            price_range_end: "",
            price_value: "",
            goods_tax_code: "",
            disc_percent_range_start: "",
            disc_percent_range_end: "",
            disc_percent_value: "",
          });
        } else {
          newPriceTax.push({
            ...is_exist,
            country_tax: c,
            is_active: true,
          });
        }
      });

      methods.setValue(`${pre}.price_tax`, newPriceTax);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch(`${pre}.price_tax`)]);

  const [activeTab, setActiveTab] = useState(0);
  const countryData = useFetch(country_url);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={() => setOpen(true)}
        color={isValidPrice(methods.watch(pre), false) ? "primary" : "error"}
        disabled={!methods.watch(pre).is_active}
      >
        Add Prices
      </Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "800px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={async () => {
              setOpen(false);
            }}
          >
            <X />
          </IconButton>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography fontSize={16} fontWeight="500">
              Prices & Taxes
            </Typography>
            {color && (
              <Typography fontSize={14} fontWeight="400">
                Color: {color}
              </Typography>
            )}
            {size && (
              <Typography fontSize={14} fontWeight="400">
                Size:{size}
              </Typography>
            )}
          </Box>
          <Box sx={{ padding: "10px" }} />
          {methods.watch(`${pre}.price_tax`)?.length > 1 && (
            <Box sx={{ display: "flex", gap: "10px" }}>
              {methods.watch(`${pre}.price_tax`)?.map((price, index) => (
                <Tab
                  active={activeTab === index}
                  onClick={() => setActiveTab(index)}
                  key={index}
                >
                  {idToname(price.country_tax, countryData.data)}
                </Tab>
              ))}
            </Box>
          )}
          <Box sx={{ padding: "10px" }} />
          <PriceForm
            key={activeTab}
            methods={methods}
            pre={`${pre}.price_tax.${activeTab}`}
            hsnCodeData={hsnCodeData}
          />
          <Box sx={{ padding: "10px" }} />
        </Box>
      </Dialog>
    </>
  );
}

function PriceForm({ methods, pre, hsnCodeData }) {
  const priceRangeStart = Number(methods.watch(`${pre}.price_range_start`));
  const priceRangeEnd = Number(methods.watch(`${pre}.price_range_end`));
  const priceValue = Number(methods.watch(`${pre}.price_value`));
  const discRangeStart = Number(
    methods.watch(`${pre}.disc_percent_range_start`)
  );
  const discRangeEnd = Number(methods.watch(`${pre}.disc_percent_range_end`));
  const discValue = Number(methods.watch(`${pre}.disc_percent_value`));
  const hsnCode = methods.watch(`${pre}.goods_tax_code`);

  return (
    <FieldGrid>
      <FieldGrid>
        <Input.Number
          methods={methods}
          label="Basic price range"
          name={`${pre}.price_range_start`}
          placeholder="Min"
          required
          error={
            !priceRangeStart
              ? "Price range start is required"
              : priceRangeStart > priceRangeEnd && priceRangeEnd
              ? "Start should be smaller than end"
              : undefined
          }
        />
        <Input.Number
          methods={methods}
          name={`${pre}.price_range_end`}
          placeholder="Max"
          required
          error={
            !priceRangeEnd
              ? "Price range end is required"
              : priceRangeStart > priceRangeEnd
              ? "End should be larger than start"
              : undefined
          }
          fillLabelHeight
        />
      </FieldGrid>
      <Input.Number
        methods={methods}
        label="Basic price"
        name={`${pre}.price_value`}
        placeholder="Basic price"
        required
        error={
          !priceValue
            ? "Basic price is required"
            : priceValue > priceRangeEnd
            ? "Price should be smaller than price range end"
            : priceValue < priceRangeStart
            ? "Price should be greater than price range start"
            : undefined
        }
      />
      <Input.Select
        methods={methods}
        label="HSN Code"
        name={`${pre}.goods_tax_code`}
        placeholder="HSN Code"
        options={hsnCodeData.data}
        loading={hsnCodeData.loading}
        required
        error={!hsnCode ? "Tax code is required" : undefined}
      />
      <FieldGrid>
        <Input.Number
          methods={methods}
          label="Discount Percent range"
          name={`${pre}.disc_percent_range_start`}
          placeholder="Min"
          required
          error={
            !discRangeStart
              ? "Discount range start is required"
              : discRangeStart > discRangeEnd && discRangeEnd
              ? "Start should be smaller than end"
              : undefined
          }
        />
        <Input.Number
          methods={methods}
          name={`${pre}.disc_percent_range_end`}
          placeholder="Max"
          required
          error={
            !discRangeEnd
              ? "Discount range end is required"
              : discRangeStart > discRangeEnd
              ? "End should be larger than start"
              : undefined
          }
          fillLabelHeight
        />
      </FieldGrid>
      <Input.Number
        methods={methods}
        label="Discount Percent"
        name={`${pre}.disc_percent_value`}
        placeholder="Discount Percent"
        required
        error={
          !discValue
            ? "Discount percent is required"
            : discValue > discRangeEnd
            ? "Discount percent should be smaller than range end"
            : discValue < discRangeStart
            ? "Discount should be greater than range start"
            : undefined
        }
      />
    </FieldGrid>
  );
}

function Inventorys({ pre, methods, size, color, addressData }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (addressData.data) {
      const old_data = methods.watch(`${pre}.qty_addres`) || [];
      const addresses = addressData.data;
      const newQtyAddresses = addresses.map((c) => {
        const is_exist = old_data.find((d) => d.seller_address === c.id);
        if (!is_exist) {
          return {
            code: generateUID(),
            description: generateUID(),
            printable_name: generateUID(),
            seller_address: c.id,
            facility_name: c.facility_name,
            is_active: true,
            stock: "",
            item_length: "",
            item_width: "",
            item_height: "",
            item_weight: "",
            item_unit: "",
            package_length: "",
            package_width: "",
            package_height: "",
            package_weight: "",
            package_unit: "",
          };
        } else {
          return {
            ...is_exist,
            facility_name: c.facility_name,
          };
        }
      });

      methods.setValue(`${pre}.qty_addres`, newQtyAddresses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressData.data]);

  useEffect(() => {
    if (methods.watch(`${pre}.qty_addres`)?.length === 0) {
      if (addressData.data) {
        const old_data = methods.watch(`${pre}.qty_addres`) || [];
        const addresses = addressData.data;
        const newQtyAddresses = addresses.map((c) => {
          const is_exist = old_data.find((d) => d.seller_address === c.id);
          if (!is_exist) {
            return {
              code: generateUID(),
              description: generateUID(),
              printable_name: generateUID(),
              seller_address: c.id,
              facility_name: c.facility_name,
              is_active: true,
              stock: "",
              item_length: "",
              item_width: "",
              item_height: "",
              item_weight: "",
              item_unit: "",
              package_length: "",
              package_width: "",
              package_height: "",
              package_weight: "",
              package_unit: "",
            };
          } else {
            return {
              ...is_exist,
              facility_name: c.facility_name,
            };
          }
        });

        methods.setValue(`${pre}.qty_addres`, newQtyAddresses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch(`${pre}.qty_addres`), addressData.data]);

  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={() => setOpen(true)}
        color={
          isValidInventory(methods.watch(pre), false) ? "primary" : "error"
        }
        disabled={!methods.watch(pre).is_active}
      >
        Add Inventory
      </Button>
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "800px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography fontSize={16} fontWeight="500">
              Inventory
            </Typography>
            {color && (
              <Typography fontSize={14} fontWeight="400">
                Color: {color}
              </Typography>
            )}
            {size && (
              <Typography fontSize={14} fontWeight="400">
                Size: {size}
              </Typography>
            )}
          </Box>
          <Box sx={{ padding: "10px" }} />
          <Box sx={{ display: "flex", gap: "10px" }}>
            {methods.watch(`${pre}.qty_addres`)?.map((inv, index) => (
              <Tab
                active={activeTab === index}
                onClick={() => setActiveTab(index)}
                key={index}
              >
                {inv.facility_name || `Address ${index + 1}`}
              </Tab>
            ))}
          </Box>
          <Box sx={{ padding: "10px" }} />
          <InventoryForm
            key={activeTab}
            methods={methods}
            pre={`${pre}.qty_addres.${activeTab}`}
            uppperPre={pre}
          />
          <Box sx={{ padding: "10px" }} />
        </Box>
      </Dialog>
    </>
  );
}

function InventoryForm({ methods, pre, uppperPre }) {
  const stock = methods.watch(`${pre}.stock`);
  // const itemLength = methods.watch(`${uppperPre}.item_length`);
  // const itemWidth = methods.watch(`${uppperPre}.item_width`);
  // const itemHeight = methods.watch(`${uppperPre}.item_height`);
  // const itemWeight = methods.watch(`${uppperPre}.item_weight`);
  // const itemUnit = methods.watch(`${uppperPre}.item_unit`);
  // const packageLength = methods.watch(`${uppperPre}.package_length`);
  // const packageWidth = methods.watch(`${uppperPre}.package_width`);
  // const packageHeight = methods.watch(`${uppperPre}.package_height`);
  // const packageWeight = methods.watch(`${uppperPre}.package_weight`);
  // const packageUnit = methods.watch(`${uppperPre}.package_unit`);

  return (
    <>
      <FieldGrid>
        <Input.Number
          methods={methods}
          label="Quantity"
          name={`${pre}.stock`}
          placeholder="Quantity"
          required
          error={!stock ? "Quantity is required" : undefined}
        />
      </FieldGrid>
      <Box sx={{ padding: "10px" }} />
      <FieldGrid>
        <Box>
          <Typography fontSize={15} fontWeight={500}>
            Item dimensions
          </Typography>
          <Box sx={{ padding: "5px" }} />
          <FieldSet>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Length"
                name={`${uppperPre}.item_length`}
                placeholder="Length"
                // required
                // error={!itemLength ? "Length is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.item_unit`}
                placeholder="Unit"
                // required
                // error={!itemUnit ? "Unit is required" : undefined}
                options={[
                  { id: "cm", printable_name: "cm" },
                  { id: "inch", printable_name: "inch" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Width"
                name={`${uppperPre}.item_width`}
                placeholder="Width"
                // required
                // error={!itemWidth ? "Width is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.item_unit`}
                placeholder="Unit"
                // required
                // error={!itemUnit ? "Unit is required" : undefined}
                options={[
                  { id: "cm", printable_name: "cm" },
                  { id: "inch", printable_name: "inch" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Height"
                name={`${uppperPre}.item_height`}
                placeholder="Height"
                // required
                // error={!itemHeight ? "Height is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.item_unit`}
                placeholder="Unit"
                // required
                // error={!itemUnit ? "Unit is required" : undefined}
                options={[
                  { id: "cm", printable_name: "cm" },
                  { id: "inch", printable_name: "inch" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Item weight"
                name={`${uppperPre}.item_weight`}
                placeholder="Item weight"
                // required
                // error={!itemWeight ? "Weight is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.item_weight_unit`}
                placeholder="Unit"
                // required
                // error={!itemUnit ? "Unit is required" : undefined}
                options={[
                  { id: "kg", printable_name: "kg" },
                  { id: "g", printable_name: "g" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
          </FieldSet>
        </Box>
        <Box>
          <Typography fontSize={15} fontWeight={500}>
            Package dimensions
          </Typography>
          <Box sx={{ padding: "5px" }} />
          <FieldSet>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Length"
                name={`${uppperPre}.package_length`}
                placeholder="Length"
                // required
                // error={!packageLength ? "Length is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.package_unit`}
                placeholder="Unit"
                // required
                // error={!packageUnit ? "Unit is required" : undefined}
                options={[
                  { id: "cm", printable_name: "cm" },
                  { id: "inch", printable_name: "inch" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Width"
                name={`${uppperPre}.package_width`}
                placeholder="Width"
                // required
                // error={!packageWidth ? "Width is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.package_unit`}
                placeholder="Unit"
                // required
                // error={!packageUnit ? "Unit is required" : undefined}
                options={[
                  { id: "cm", printable_name: "cm" },
                  { id: "inch", printable_name: "inch" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Height"
                name={`${uppperPre}.package_height`}
                placeholder="Height"
                // required
                // error={!packageHeight ? "Height is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.package_unit`}
                placeholder="Unit"
                // required
                // error={!packageUnit ? "Unit is required" : undefined}
                options={[
                  { id: "cm", printable_name: "cm" },
                  { id: "inch", printable_name: "inch" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
            <FieldGrid>
              <Input.Text
                methods={methods}
                label="Item weight"
                name={`${uppperPre}.package_weight`}
                placeholder="Item weight"
                // required
                // error={!packageWeight ? "Weight is required" : undefined}
              />
              <Input.Select
                methods={methods}
                name={`${uppperPre}.package_weight_unit`}
                placeholder="Unit"
                // required
                // error={!packageUnit ? "Unit is required" : undefined}
                options={[
                  { id: "kg", printable_name: "kg" },
                  { id: "g", printable_name: "g" },
                ]}
                fillLabelHeight
              />
            </FieldGrid>
          </FieldSet>
        </Box>
      </FieldGrid>
    </>
  );
}

function filterFetchedData(data) {
  const newData = { ...data };
  Object.keys(newData).forEach((key) => {
    if (newData[key] === null) {
      delete newData[key];
    }
  });
  newData?.product_inventory?.forEach((item) => {
    item?.qty_addres?.forEach((address) => {
      delete address.variant_qr;
    });
  });
  if (newData.short_description === "none") {
    newData.short_description = "";
  }
  if (newData.description === "none") {
    newData.description = "";
  }

  return newData;
}

// function dataByStep(data, step) {
//   if (step === 0) {
//     return {
//       code: data.code,
//       printable_name: data.printable_name,
//       stock: data.stock,
//       style_code: data.style_code,
//       seller_details: data.seller_details,
//       brand_details: data.brand_details,
//       title: data.title,
//       short_description: data.short_description,
//       skuid: data.skuid,
//       product_origin_country: data.product_origin_country,
//       description: data.description,
//       first_category: data.first_category,
//       second_category: data.second_category,
//       third_category: data.third_category,
//       duration_end: data.duration_end,
//       deal_start: data.deal_start,
//       deal_end: data.deal_end,
//       search_keys: data.search_keys,
//       product_country: data.product_country,
//     };
//   }
//   if (step === 1) {
//     return {
//       parameter: data.parameter,
//       // data_upload: data.data_upload,
//       // file_upload: data.file_upload,
//     };
//   }
//   if (step === 2) {
//     return {
//       product_inventory: data.product_inventory,
//       has_size: data.has_size,
//       has_color: data.has_color,
//       size: data.size,
//       colors: data.colors,
//       // size_chart_data: data.size_chart_data,
//       // size_chart: data.size_chart,
//     };
//   }
//   if (step === 3) {
//     return {
//       is_cancellable: data.is_cancellable,
//       is_refundable: data.is_refundable,
//       is_returnable: data.is_returnable,
//       return_span: data.return_span,
//       is_replacable: data.is_replacable,
//       replace_span: data.replace_span,
//       no_replace: data.no_replace,
//       is_active: data.is_active,
//       is_global: data.is_global,
//       qty_can_order: data.qty_can_order,
//       min_inv: data.min_inv,
//       max_inv: data.max_inv,
//       bufferstock: data.bufferstock,
//       you_may_like: data.you_may_like,
//     };
//   }
// }

// function logFormData(data) {
//   for (const [key, value] of Object.entries(data)) {
//     console.log(key, value);
//   }
// }
