import { store } from "../redux/store";
import CryptoJS from "crypto-js";

export function encrypt(data) {
  // return data;
  const state = store.getState();
  const key = CryptoJS.enc.Utf8.parse(state.user.accessToken.substring(0, 16));
  const iv = CryptoJS.enc.Utf8.parse(state.user.mobile_number + "0000");
  return CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decrypt(data) {
  // return data;
  const state = store.getState();
  const key = CryptoJS.enc.Utf8.parse(state.user.accessToken.substring(0, 16));
  const iv = CryptoJS.enc.Utf8.parse(state.user.mobile_number + "0000");
  return CryptoJS.AES.decrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
}
