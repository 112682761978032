import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { address_url, holiday_url } from "../../api/urls";
import { Loading } from "../../components/loading";
import { useFetch } from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { validators } from "../../utils/validators";
import { api } from "../../api/api";
import { enqueueSnackbar } from "notistack";
import { unknownError } from "../../utils/unknownError";
import { FieldSet, Form } from "../../components/form";
import { Input } from "../../components/input";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { ReactComponent as NoDocs } from "../../images/noDocs.svg";
import { TableComponent } from "../../components/table";
import { Actions } from "../../components/actions";
import { Pencil, Trash2 } from "lucide-react";
import { useParamsState } from "../../hooks/useParamState";
import { MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

export function HolidaySection({ refetch }) {
  const [subPage] = useParamsState("sub_page", "view");
  return (
    <Box>{subPage === "view" ? <WorkingDayTab refetch={refetch} /> : null}</Box>
  );
}

function WorkingDayTab({ refetch }) {
  const addressData = useFetch(address_url);
  const methods = useForm({
    // defaultValues: editData,
    resolver: zodResolver(
      z.object({
        facility_name: validators.stringRequired,
        contact_name: validators.stringRequired,
        contact_number: validators.stringRequired,
        address_line_1: validators.stringRequired,
        address_line_2: validators.stringRequired,
        landmark: validators.stringRequired,
        pincode: validators.stringRequired,
        facility_gstin_number: validators.stringRequired,
        facility_type: validators.numberRequired,
        country: validators.numberRequired,
        state_link: validators.numberRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async () => {
    const newData = methods.getValues();
    try {
      const response = await api.patch(`${address_url}${newData.id}/`, newData);
      if (response?.status === 200) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
        });
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError();
      }
    }
  };

  const days = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    ...(methods.getValues("working_days") || {}),
  };

  const setActiveDay = (day, value) => {
    methods.setValue("working_days", {
      ...days,
      [day]: value,
    });
  };

  const activeDays = Object.keys(days).filter((day) => days[day]);

  return (
    <Box sx={{ padding: "20px" }}>
      <Form methods={methods} onSubmit={onSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
            Fulfilment Center:
          </Box>
          <Input.Select
            methods={methods}
            name="address"
            options={addressData.data}
            loading={addressData.loading}
            required
            placeholder="Fullfulment Center"
            getKey="facility_name"
            onChangeFullData={(data) => {
              methods.reset({
                ...methods.getValues(),
                ...data,
              });
            }}
          />
          {methods.watch("address") && (
            <>
              <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
                Working Hours:
              </Box>
              <Box className="equal-columns">
                <MobileTimePicker
                  value={
                    methods.getValues("start_time")
                      ? moment(methods.getValues("start_time"), "HH:mm")
                      : null
                  }
                  onChange={(value) => {
                    methods.setValue("start_time", value.format("HH:mm"));
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
                <MobileTimePicker
                  value={
                    methods.getValues("end_time")
                      ? moment(methods.getValues("end_time"), "HH:mm")
                      : null
                  }
                  onChange={(value) => {
                    methods.setValue("end_time", value.format("HH:mm"));
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </Box>
              <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
                Non-Working Days:
              </Box>
              <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                {Object.keys(days).map((day, index) => (
                  <Button
                    key={index}
                    variant={days[day] ? "contained" : "outlined"}
                    onClick={() => setActiveDay(day, !days[day])}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {day}
                  </Button>
                ))}
              </Box>
              {activeDays.length > 0 ? (
                <Box sx={{ fontSize: "13px", fontWeight: "500" }}>
                  Closed on{" "}
                  <Box
                    sx={{
                      display: "inline",
                      color: "primary.main",
                      textTransform: "capitalize",
                    }}
                  >
                    {activeDays.join(", ")}
                  </Box>
                </Box>
              ) : null}
              <Box sx={{ padding: "2px" }} />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Save
              </LoadingButton>
              <Box sx={{ padding: "10px" }} />
              <Box sx={{ fontSize: "18px", fontWeight: "500" }}>Holidays:</Box>
              <HolidayTab
                addressId={methods.watch("id")}
                refetch={refetch}
                oldHolidays={methods.watch("holidays")}
                addressRefetch={addressData.refetch}
                onAdd={(holidays) => {
                  methods.setValue("holidays", holidays);
                }}
              />
            </>
          )}
        </Box>
      </Form>
    </Box>
  );
}

function HolidayTab({ addressId, addressRefetch, oldHolidays, onAdd }) {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState();
  const holidayData = useFetch(holiday_url);

  holidayData.data = holidayData.data?.filter((d) =>
    oldHolidays?.includes(d.id)
  );

  const refetch = holidayData.refetch;

  const handleOpen = (data = null) => {
    setEditData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (data) => {
    handleOpen(data);
  };

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`${holiday_url}${id}/`);
      if (response?.status === 204) {
        enqueueSnackbar("Holiday deleted successfully", {
          variant: "success",
        });
        refetch();
      } else {
        unknownError();
      }
    } catch (e) {
      unknownError();
    }
  };

  if (holidayData.loading || !holidayData.data) {
    return <Loading />;
  }

  if (holidayData?.data?.length === 0) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoDocs />
          </Box>
          <Box sx={{ padding: "5px" }} />
          <Button variant="contained" onClick={() => handleOpen({})}>
            Add Holiday
          </Button>
        </Box>
        <HolidayForm
          open={open}
          onClose={handleClose}
          refetch={() => {
            refetch();
          }}
          editData={editData}
          oldHolidays={oldHolidays}
          addressId={addressId}
          onAdd={onAdd}
        />
      </Box>
    );
  }

  const columns = [
    {
      headerName: "Holiday Name",
      field: "holiday_name",
    },
    {
      headerName: "From",
      field: "start_date_time",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "To",
      field: "end_date_time",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => (
        <Actions
          options={[
            {
              icon: Pencil,
              name: "Edit",
              onClick: () => handleEdit(params.row),
            },
            {
              icon: Trash2,
              name: "Delete",
              onClick: () => handleDelete(params.row.id),
            },
          ]}
        />
      ),
      flex: 1,
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={() => handleOpen()}
          sx={{ px: "20px" }}
        >
          Add Holiday
        </Button>
      </Box>
      <Box sx={{ padding: "10px" }} />
      <TableComponent
        loading={holidayData.loading}
        rows={holidayData.data || []}
        columns={columns}
      />
      <HolidayForm
        open={open}
        onClose={handleClose}
        refetch={() => {
          refetch();
        }}
        editData={editData}
        addressId={addressId}
        oldHolidays={oldHolidays}
        onAdd={onAdd}
      />
    </Box>
  );
}

function HolidayForm({
  open,
  onClose,
  refetch,
  editData,
  addressId,
  oldHolidays,
  onAdd,
}) {
  const methods = useForm({
    defaultValues: editData,
    resolver: zodResolver(
      z.object({
        holiday_name: validators.stringRequired,
        start_date_time: validators.dateRequired,
        end_date_time: validators.dateRequired,
      })
    ),
    shouldFocusError: true,
  });
  const loading = methods.formState.isSubmitting;
  const onSubmit = async (data) => {
    try {
      let response = await (editData?.id
        ? api.patch(`${holiday_url}${editData.id}/`, data)
        : api.post(holiday_url, data));
      if (response?.status === 200 || response?.status === 201) {
        const newHolidays = [...(oldHolidays || []), response?.data?.id];
        response = await api.patch(`${address_url}${addressId}/`, {
          holidays: newHolidays,
        });

        if (response?.status === 200 || response?.status === 201) {
          onAdd(newHolidays);
          enqueueSnackbar("Holiday saved successfully", {
            variant: "success",
          });
          onClose();
          refetch();
        } else {
          unknownError();
        }
      } else {
        unknownError();
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        enqueueSnackbar(e?.response?.data.data, { variant: "error" });
      } else {
        unknownError(e);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editData ? "Edit Holiday" : "Add Holiday"}</DialogTitle>
      <DialogContent>
        <Form methods={methods} onSubmit={onSubmit}>
          <FieldSet>
            <Input.Text
              methods={methods}
              name="holiday_name"
              label="Holiday Name"
              placeholder="Holiday Name"
              required
            />
            <Input.Date
              methods={methods}
              name="start_date_time"
              label="From"
              placeholder="From"
              required
            />
            <Input.Date
              methods={methods}
              name="end_date_time"
              label="To"
              placeholder="To"
              required
            />
          </FieldSet>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={methods.handleSubmit(onSubmit)}
          loading={loading}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
