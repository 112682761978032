import { Box } from "@mui/material";
import { ReactComponent as LoadingIcon } from "../images/loading.svg";

export function Loading() {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <LoadingIcon height="70px" width="70px" />
    </Box>
  );
}
