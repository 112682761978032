export function objToParams(params) {
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        if (params[key].length > 0) {
          return key + "=" + params[key].join(",");
        } else {
          return "";
        }
      }
      if (params[key] || params[key] === false) {
        return key + "=" + params[key];
      }
      return "";
    })
    .filter((x) => x !== "")
    .join("&");
}
