import { LoadingButton } from "@mui/lab";
import { Box, Dialog, IconButton } from "@mui/material";
import { Info, X } from "lucide-react";
import { cloneElement, useState } from "react";

export function AreYouSure({ onYes, children, message = "Are you sure?" }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {cloneElement(children, {
        onClick: () => {
          setOpen(true);
        },
      })}
      <Dialog open={open}>
        <Box
          sx={{
            padding: "40px",
            width: "400px",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setOpen(false)}
          >
            <X />
          </IconButton>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Info style={{ height: "30px", width: "30px" }} />
            <Box sx={{ fontSize: "20px", fontWeight: "500" }}>{message}</Box>
          </Box>
          <Box sx={{ padding: "10px" }} />
          <Box className="equal-columns">
            <LoadingButton variant="outlined" onClick={() => setOpen(false)}>
              No
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await onYes();
                setLoading(false);
                setOpen(false);
              }}
            >
              Yes
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
